app = angular.module('NewWarehouse', [
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ui.bootstrap',
  'ngRoute',
  'zaikoModal'
  'GeeksCommon'
])

app.config ['$routeProvider', ($r)->
  $r
    .when('/', {
      templateUrl: "/html/newWarehouse/index.html"
      controller: "NewWarehouseController"
      controllerAs: "c"
    })
    .otherwise(redirectTo: "/")
]

app.controller 'NewWarehouseController', [
  'EstimateApiService'
  'ConditionService'
  'StockService'
  'EstimateCommentService'
  '$scope'
  '$timeout'
  class NewWarehouseController
    constructor: (@EstimateApiService, @ConditionService, @StockService, @EstimateCommentService, @$scope, @$timeout)->
      @reset()
      @conditions = @ConditionService.query()
      @commentData = @EstimateCommentService.query({category:"warehousing", type: "amazon"})

    reset: ()->
      @is_e = false
      @submit = false
      @item = {
        condition_id: 1003
      }
      @msg = "111"
      @e = null
      @code = ""
      @fc("#code")

    runEstimate: ()->
      if @is_e then return
      @is_e = true
      @EstimateApiService.find({code: @code}).$promise.then((d)=>
        k = Object.keys(d)[0]
        e = d[k]
        @e = e
        @item.code = @code
        @item.asin = e.request.code
        @item.product_catalog_id = e.request.productCatalogId
        @item.title = e.attribute.Title
        @item.staff_comment = "旧ストックから入庫"
        @item.cost_price = 0
        @item.shipping_cost = 0
        @item.quantity = 1
        @item.stock_status_id = 1
        @changeCondition()
        @fc("#label")
        @is_e = false
      )
    changeCondition: ->
      @item.export_comment = @commentData[3].condition[@commentData[3].condition_table[@item.condition_id]].export_comment
      @item.simulate_price = @e.result.export[@conditions[@item.condition_id].amazon_condition_english_name].price

    fc: (id)->
      @$timeout(->
        angular.element(id).focus()
      )
    create: ->
      if @submit then return
      @StockService.newWarehouse(@item).$promise.then((d)=>
        @reset()
        @msg = "登録完了"
      ).catch((d)=>
        console.log(d)
        @msg = "登録失敗=> "+d.data.msg
      )
]
