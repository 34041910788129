app = angular.module('container', [
  'angular-loading-bar'
  'ngAnimate'
  'ui.bootstrap',
  'GeeksCommon',
  'ngAlertify'
])

app.controller 'FinishContainerContainer', [
  'ContainerService'
  class FinishContainerContainer
    constructor: (@ContainerService)->
      @isSubmit = false
      @res = null

    finish: (form)->
      if @isSubmit then return;
      if form.$valid
        unless @containerNumberText.length > 0 then return
        cList = _.filter(@containerNumberText.replace(/\n/g, ",").split(","), (e)=>e.length >= 3 && e.length <= 7)
        cList = _.uniq(cList)

        @isSubmit = true
        @ContainerService.finish({
          container_numbers: cList
        }).$promise.then((d)=>
          @res = d
          @res.msg = _.map(@res.containers, (row)=>
            c = row[0]
            f = row[1]
            msg = row[2]
            if f then "#{c} を処理しました。" else "#{c} は処理できませんでした。(#{msg})").join("\n")
          @isSubmit = false
          @containerNumberText = ""
        ).catch((d)=>
          @res = {status: false}
          @isSubmit = false
          @containerNumberText = ""
        )
]

app.controller 'ClassificationContainerController', [
  'ContainerService'
  'alertify'
  '$timeout'
  class ClassificationContainerController
    constructor: (@ContainerService, @alertify, @$timeout)->
      window.addEventListener('beforeunload', (e)=>
        unless @container.items.length == 0 && @container.stocks.length == 0
          txt = "仕分けが完了している場合は仕分け完了を押してください。"
          e.returnValue = txt
          return txt
        return e
      )
      @init()
    init: ->
      @container = {}
      @containerNumber = ""
      @targetContainerNumber = ""
      @focus("containerNumber")
      @isTargetContainerOk = false
      @isSubmit = false
    classification: ()->
      @ContainerService.classification({
        container_number: @containerNumber
      }).$promise.then((d)=>
        @container = d
        if @container.items.length > 0 && @container.stocks.length > 0
          @focus("targetContainerNumber")
        #卸 or 入国商品が無いなら移動さきを入力する必要が無い
        if @container.items.length ==  0 || @container.stocks.length == 0
          @isTargetContainerOk = true
      ).catch((d)=>
        @alertify.alert("このコンテナは仕分け対象ではありません。").then(=>
          @init()
        )
      )
    getContainer: ()->
      if @containerNumber == @targetContainerNumber
        @alertify.alert("同一コンテナは使用できません。").then(=>@focus("targetContainerNumber"))
        @targetContainerNumber = ""
        return
      @ContainerService.searchContainer({container_number: @targetContainerNumber}).$promise.then((d)=>
        if d.estimate_container_id > 0
          @alertify.alert("このコンテナは既に使用されています。").then(=>@focus("targetContainerNumber"))
          @targetContainerNumber = ""
        else
          @isTargetContainerOk = true
      )
    doClassification: ()->
      if @isSubmit then return
      @isSubmit = true
      @ContainerService.doClassification({
        estimate_container_id: @container.container.estimate_container_id,
        target_container_number: @targetContainerNumber
      }).$promise.then((d)=>
        @isSubmit = false
        @alertify.alert("仕分け完了").then(=>@init())
      )
    focus: (id)->
      $ = angular.element
      @$timeout(=>
        $('#'+id).focus()
      )



]
#
#app.controller 'ContainerController', [
#  'UnWarehouseService'
#  class ContainerController
#    constructor: (@UnWarehouseService)->
#      @list = @UnWarehouseService.pickup()
#
#]
#
#app.controller 'PickupController', [
#  'UnWarehouseService'
#  class PickupController
#    constructor: (@UnWarehouseService)->
#      @isSubmit = false
#      @res = false
#    pickup: (form)->
#      if @isSubmit then return;
#      if form.$valid
#        @isSubmit = true
#        @UnWarehouseService.doPickup({container_number: @containerNumber}).$promise.then((d)=>
#          @isSubmit = false
#          @res = d
#          @focus()
#        ).catch((e)=>
#          @isSubmit = false
#          @res = {
#            status: false
#          }
#          @focus()
#        )
#    focus: ()->
#      $ = angular.element
#      $('#containerNumber').val("")
#      $('#containerNumber')[0].focus()
#]
