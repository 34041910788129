app = angular.module('amazonStock', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ngEnter'
  'GeeksCommon'
  'ngFileUpload'
])
app.config ['$locationProvider', ($locationProvider) ->
  $locationProvider.hashPrefix('!')
]

app.controller 'amazonStockController', [
  '$scope'
  '$http'
  '$location'
  'NgTableParams'
  '$filter'
  '$timeout'
  '$q'
  'AmazonStockService'
  'Upload'
  ($scope, $http, $location, NgTableParams, $filter, $timeout, $q, AmazonStockService, Upload) ->
    $scope.version = 'v1'
    $scope.amazonStocks = []
    $scope.url = '/api/' + $scope.version + '/amazon-stocks'

    $scope.init = ->
      conditions_url = '/api/' + $scope.version + '/conditions/amazon-conditions'
      $q.all([
        $http.get(conditions_url)
      ]).then ((results) ->
        $scope.conditions = results[0].data
        return
      ), (errorMessage) ->
#        alert 'error'
        console.log errorMessage
        return
      return

    ###*
    # Amazon在庫テーブル
    ###

    $scope.reload = ->
      $scope.amazonStockTable.reload()
      $scope.editAmazonStock = null

    $scope.amazonStockTable = new NgTableParams({
        page: 1
        count: 10
      },
      getData: (table) ->
#        table.filter = $scope.getSearchQueryParams()
        return AmazonStockService.query(table.url()).$promise.then((d)=>
          table.total(d.total)
          d.data
        )
    )

    ###*
    # 編集画面表示
    ###

    $scope.showEdit = (amazonStock) ->
      $location.hash amazonStock.amazon_stock_id
      AmazonStockService.get({amazon_stock_id: amazonStock.amazon_stock_id}).$promise.then((d)=>
        $scope.editAmazonStock = d
      )
      return

    $scope.hideEdit = ->
      $location.hash ""
      $scope.editAmazonStock = null
      return

    $scope.edit = ->
      $scope.editFlg = if $scope.editFlg then false else true
      return
    $scope.retryFeed = (amazonStock)->
      AmazonStockService.retryFeed({amazon_stock_id: amazonStock.amazon_stock_id}).$promise.then((d)=>
        alert("Feed依頼を投げました")
      )


    # 編集画面の表示、非表示によって、左右のテーブルのクラスを変える
    $scope.$watch 'editAmazonStock', (newValue) ->
      if newValue
        $scope.leftBoxClass = 'col-md-6'
        $scope.rightBoxClass = 'col-md-6'
      else
        $scope.leftBoxClass = 'col-md-12'
        $scope.rightBoxClass = ''
      return

    ###*
    # 更新
    # @param amazonStock
    # @param type
    ###

    $scope.update = ->
      if $scope.amazonStockForm.$valid == false
        return
      $scope.updating = true
      AmazonStockService.save($scope.editAmazonStock).$promise.then((d)=>
        $scope.editFlg = false
        $scope.updating = false
        $scope.showEdit($scope.editAmazonStock)
        $scope.amazonStockTable.reload()
      )

    ###*
    # 削除
    # @param amazonStock
    ###

    $scope.delete = (amazonStock) ->
      return
      if !confirm('本当に削除してよろしいですか？')
        return
      url = $scope.url + '/' + amazonStock['amazon_stock_id']
      $scope.updating = true
      $http.delete(url).then ((result) ->
        angular.forEach $scope.amazonStocks, (value, key) ->
          if value['amazon_stock_id'] == amazonStock['amazon_stock_id']
            delete $scope.amazonStocks[key]
          return
        $scope.editAmazonStock = null
        $scope.updating = false
        $scope.amazonStockTable.reload()
        return
      ), (errorMessage) ->
        alert 'error'
        console.log errorMessage
        return
      return

    ###*
    # FBA化
    # @param amazonStock
    ###

    $scope.fbaExe = ->
      if !confirm('本当にFBA化してよろしいですか?')
        return
      url = $scope.url + '/fba-exe/' + $scope.editAmazonStock['amazon_stock_id']
      $scope.updating = true
      $http.put(url).then (result) ->
        $scope.editAmazonStock = result.data
        $scope.updating = false
        $scope.editFlg = false
        return
      return

    $scope.jokyaku = (s, reason)->
      switch reason
        when 'notfound'
          return unless confirm("未発見在庫として除却してもよろしいでしょうか？")
        when 'unsellable'
          return unless confirm("状態違い販売不可在庫として除却してもよろしいでしょうか？")
        when 'other'
          return unless confirm("その他除却として除却してもよろしいでしょうか？")
        else
          alert("意図しない処理です、開発者に連絡してください")
          return
      $scope.updating = true
      AmazonStockService.jokyaku({amazon_stock_id: $scope.editAmazonStock.amazon_stock_id, stock_id: s.stock_id, reason: reason}).$promise.then((d)=>
        $scope.editFlg = false
        $scope.updating = false
        $scope.showEdit($scope.editAmazonStock)
        $scope.amazonStockTable.reload()
      )
    $scope.replace = (s)->
      if !confirm('代替発送した商品として、出品を停止します。')
        return
      $scope.updating = true
      AmazonStockService.replace({amazon_stock_id: $scope.editAmazonStock.amazon_stock_id, stock_id: s.stock_id}).$promise.then((d)=>
        $scope.editFlg = false
        $scope.updating = false
        $scope.showEdit($scope.editAmazonStock)
        $scope.amazonStockTable.reload()
      )

    ###
      入庫処理
    ###
    $scope.warehousing = ->
      return
      if !confirm('入庫します. ポジションは変更されません。')
        return
      amazonStock = $scope.editAmazonStock
      stock = amazonStock.stock
      param =
        'position_id': stock.position_id
        'warehouse_date': moment().format('YYYY-MM-DD HH:mm:ss')
      url = "/api/#{$scope.version}/stocks/" + stock['stock_id']
      $http.put(url, param).then ((result) ->
        amazonStock.stock = result.data
        return
      ), (errorMessage) ->
        console.error errorMessage
        return
      return

    $scope.updateStockCsvFileUpload = (file) ->
      if file
        Upload.upload(
          url: '/api/v1/amazon-stocks/update-price'
          file: file).progress((evt) ->
        ).then((resp) =>
          $scope.reload()
        , (resp)=>
          alert 'error\nstatusCode:' + resp.status
          console.log resp
        )
    return
]
