app = angular.module('position', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ui.bootstrap'
  'GeeksCommon'
])
app.controller 'positionController', [
  '$scope',
  'NgTableParams',
  'PositionService'
  'WarehouseService'
  'PositionGroupService'
  class PositionController
    constructor: (@$scope, @NgTableParams, @PositionService, @WarehouseService, @PositionGroupService)->
      @updated = false
      @WarehouseService.query().$promise.then((d)=>
        @warehouses = d
      )
      @positionTable = new @NgTableParams({
        count: 1000
      },{
        getData: (params)=>
          @PositionService.query(params.url()).$promise.then((d)=>
            params.total(d.total)
            d.data
          )
        counts: [1000,5000,100000]
      })
    showEdit: (position) ->
      @PositionService.get({position_id: position.position_id}).$promise.then((d)=>
        @editPosition = d
        @getPositionGroups(@editPosition.position_group.warehouse_id)
      )

    getPositionGroups: (warehouseId)->
      @PositionGroupService.query({warehouse_id: warehouseId}).$promise.then((d)=>
        @positionGroups = d
      )

    # ng-tableのselectの表示に使う
    getWarehouseNames: () ->
      @WarehouseService.query().$promise.then((d) =>
        warehouseNames = []
        _.each(d, (val, key) ->
          if val.warehouse_name? then warehouseNames.push({ id: val.warehouse_name, title: val.warehouse_name })
        )
        return warehouseNames
      )
    getPositionGroupNames: () ->
      @PositionGroupService.query().$promise.then((d) =>
        positionGroupNames = []
        _.each(d, (val, key) ->
          if val.label? then positionGroupNames.push({ id: val.label, title: val.label })
        )
        return positionGroupNames
      )

    hideEdit: ()->
      @editPosition = null

    edit: ()->
      @editFlg = !(@editFlg)

    update: (position)->
      if @updated then return
      @updated = true

      position.$save().then((d)=>
        @editFlg = false
        @updated = false
        @showEdit(position)
        @positionTable.reload()
      )
]
app.controller 'position1Controller', [
  '$scope'
  '$http'
  'NgTableParams'
  ($scope, $http, NgTableParams) ->
    $scope.positions = []
    $scope.warehouses = []
    $scope.positionGroups = []

    $scope.init = ->
      $scope.getWarehouses()
      return

    ###
    # 在庫テーブル
    ###

    $scope.positionTable = new NgTableParams({
      page: 1
      count: 50
    },
      getData: ($defer, table) ->
        $params = table.$params
        #filter
#        filteredData = if params.filter() then $filter('filter')($scope.positions, params.filter()) else $scope.positions
        #order
#        orderedData = if params.sorting() then $filter('orderBy')(filteredData, params.orderBy()) else $scope.positions
        p = $params
        $http.get("/api/v1/positions?#{$.param(p)}").then((result)->
          d = result.data
          table.total d.total
          $defer.resolve(d.data)
        )
        return
)

    $scope.getWarehouses = ->
      $http.get('/api/v1/warehouses').then (result) ->
        $scope.warehouses = result.data
        return
      return

    $scope.getPositionGroups = (warehouseId) ->
      $http.get('/api/v1/position-groups?warehouse_id=' + warehouseId).then (result) ->
        $scope.positionGroups = result.data
        return
      return

    ###*
    # 編集画面表示
    ###

    $scope.showEdit = (position) ->
      $scope.editPosition = position
      $scope.getPositionGroups position.position_group.warehouse_id
      return

    $scope.hideEdit = ->
      $scope.editPosition = null
      return

    $scope.edit = ->
      $scope.editFlg = if $scope.editFlg then false else true
      return

    $scope.update = ->
      $http.put('/api/v1/positions/' + $scope.editPosition.position_id, $scope.editPosition).then (result) ->
        angular.forEach $scope.positions, (position, key) ->
          if position.position_id == result.data.position_id
            $scope.positions[key] = result.data
          return
        $scope.hideEdit()
        $scope.positionTable.reload()
        return
      return

    $scope.delete = (position) ->
      if !confirm('本当に削除してよろしいでしょうか')
        return
      $http.delete('/api/v1/positions/' + position.position_id).then (result) ->
        $scope.hideEdit()
        $scope.init()
        return
      return

    return
]
