app = angular.module('zaikoModal', [
  'angular-loading-bar'
  'ngAnimate'
  'ui.bootstrap',
  'ngTable'
])

app.controller 'YahooAuctionShipmentOptionController', [
  '$scope'
  '$uibModalInstance'
  'yahooAuctionOrder'
  'YahooAuctionShipmentOptionService'
  ($scope, $uibModal, yahooAuctionOrder, YahooAuctionShipmentOptionService) ->
    $scope.yahooAuctionShipmentOption = null

    $scope.init = ->
      $scope.yahooAuctionOrder = yahooAuctionOrder
      YahooAuctionShipmentOptionService.create({
        yahoo_auction_order_id: $scope.yahooAuctionOrder.yahoo_auction_order_id
      }).$promise.then((d)=>
        $scope.yahooAuctionShipmentOption = d
        unless $scope.yahooAuctionShipmentOption.description?.length > 0
          $scope.yahooAuctionShipmentOption.description = '商品代金として'
        $scope.initAddressee()
        if $scope.yahooAuctionShipmentOption.deliv_date?
          $scope.yahooAuctionShipmentOption.deliv_date = moment(d.deliv_date).toDate()
      )
#      $scope.yahooAuctionShipmentOption.yahoo_auction_order_id = yahooAuctionOrder.yahoo_auction_order_id
      $scope.popup = {
        opened: false
      }

    $scope.open_datepicker = ->
      $scope.popup.opened = true

    $scope.delete = ->
      if $scope.is_saved then return
      unless $scope.yahooAuctionShipmentOption?.created_at? then $uibModal.close()
      $scope.is_saved = true
      $scope.yahooAuctionShipmentOption.$delete({yahoo_auction_order_id: $scope.yahooAuctionOrder.yahoo_auction_order_id}).then(->
        $uibModal.close()
      )

    $scope.save = ->
      if $scope.is_saved then return
      $scope.yahooAuctionShipmentOption.deliv_date = if $scope.yahooAuctionShipmentOption.deliv_date? then moment($scope.yahooAuctionShipmentOption.deliv_date).format("YYYY-MM-DD")
      $scope.is_saved = true
      $scope.yahooAuctionShipmentOption.$save({yahoo_auction_order_id: $scope.yahooAuctionOrder.yahoo_auction_order_id}).then(->
        $uibModal.close yahooAuctionShipmentOption: $scope.yahooAuctionShipmentOption
      )

    # 領収書宛名の初期化
    $scope.initAddressee = ->
      if $scope.yahooAuctionShipmentOption.addressee
        $scope.tmpAddressee = $scope.yahooAuctionShipmentOption.addressee
      if (($scope.yahooAuctionShipmentOption.is_blank is false) or ($scope.yahooAuctionShipmentOption.is_blank is undefined)) and not $scope.yahooAuctionShipmentOption.addressee
        $scope.yahooAuctionShipmentOption.addressee = $scope.yahooAuctionOrder.order.buyer_name

    # 宛名と空欄希望の制御
    $scope.toggleAddressee = ->
      $scope.yahooAuctionShipmentOption.is_blank = not $scope.yahooAuctionShipmentOption.is_blank
      if $scope.yahooAuctionShipmentOption.is_blank is true
        $scope.yahooAuctionShipmentOption.addressee = ''
      else if $scope.tmpAddressee
        $scope.yahooAuctionShipmentOption.addressee = $scope.tmpAddressee
      else
        $scope.yahooAuctionShipmentOption.addressee = $scope.yahooAuctionOrder.order.buyer_name
]

app.controller 'YahooAuctionCustomerMessageController', [
  'text'
  class YahooAuctionCustomerMessageController
    constructor: (@text)->
      @customer_message = @text
]

app.controller 'PositionModalController', [
  '$scope'
  '$http'
  '$uibModalInstance',
  'NgTableParams'
  ($scope, $http, $uibModal, NgTableParams) ->

    $scope.init = ->
      $scope.floors = []
      $scope.positionGroups = []
      $scope.positions = []
      $scope.group_id = 0

      $scope.fetchWarehouses()
      return

    $scope.fetchWarehouses = ->
      $http.get('/api/v1/warehouses').then (result) ->
        $scope.warehouses = result.data
        return
      return

    $scope.selectWarehouse = (selectedWarehouse) ->
      angular.forEach $scope.warehouses, (warehouse) ->
        warehouse.selected = false
        return
      selectedWarehouse.selected = true
      $scope.selectedWarehouse = selectedWarehouse
      $scope.floors = []
      $scope.positionGroups = []
      $scope.positions = []
      $http.get('/api/v1/position-groups/floor/' + selectedWarehouse.warehouse_id).then (result) ->
        $scope.floors = result.data
        return
      return

    $scope.selectFloor = (selectedFloor) ->
      angular.forEach $scope.floors, (floor) ->
        floor.selected = false
        return
      selectedFloor.selected = false
      $scope.positionGroups = []
      $scope.positions = []
      $http.get('/api/v1/position-groups?warehouse_id=' + $scope.selectedWarehouse.warehouse_id + '&floor=' + selectedFloor.floor).then (result) ->
        $scope.positionGroups = result.data
        return
      return

    $scope.selectPositionGroup = (selectedPositionGroup) ->
      angular.forEach $scope.positionGroups, (positionGroup) ->
        positionGroup.selected = false
        return
      selectedPositionGroup.selected = true
      $scope.selectedPositionGroup = selectedPositionGroup
      $scope.positions = []

      $scope.group_id = selectedPositionGroup.group_id
      $scope.positionTable.reload()

      return

    $scope.selectPosition = (selectedPosition) ->
      angular.forEach $scope.positions, (position) ->
        position.selected = false
        return
      selectedPosition.selected = true
      $uibModal.close 'position': selectedPosition

    $scope.positionTable = new NgTableParams({
        page: 1,
        count: 10
      },{
        getData: ($defer, table) ->
          positions = [];
          if $scope.group_id > 0
            $params = table.$params
            p = $params
            p.filter.group_id = $scope.group_id
            $http.get("/api/v1/positions?#{$.param(p)}").then((result)->
              d = result.data
              $scope.positions = d.data
              table.total(d.total)
              $defer.resolve(d.data)
            )
          else
            $defer.resolve(positions)
          return
      })

    return
]

#メール送信用Modal
app.controller 'YahooSendMailController', [
  '$scope'
  '$http'
  '$q'
  '$uibModalInstance'
  'OrderIds'
  'templateId'
  'statusId'
  'type'
  'mapFunc'
  'YahooOrderMailService'
  ($scope, $http, $q, $uibModalInstance, OrderIds, templateId, statusId, type, mapFunc, YahooOrderMailService) ->
    $scope.mail = []

    $scope.init = ->
      YahooOrderMailService.query({
        template_id: templateId
        'ids[]': OrderIds
        status_id: statusId
        type: type
        'with[]': ['yahoo_auction_order']
      }).$promise.then((d)->
        $scope.mails = if mapFunc then mapFunc(d) else d
        $scope.mail = $scope.mails[0]
      )

    $scope.viewData = (mail)->
      $scope.mail = mail

    $scope.sendMail = ->
      YahooOrderMailService.post($scope.mails).$promise.then(->
        $uibModalInstance.close()
      )

    # メールの送信対象にする(一括)
    $scope.changeSendMailAll = ->
      $scope.mails.map((v) ->
        v.is_send = 1
        return v
      )

    # メールの送信対象から外す(一括)
    $scope.changeNoSendMailAll = ->
      $scope.mails.map((v) ->
        v.is_send = 0
        return v
      )
]

app.controller 'ReestimateModalController', [
  '$scope',
  '$sce',
  '$window',
  'stock'
  ($scope, $sce, $window, stock) ->
    $scope.reestimateSrc = ''
    $scope.show = false

    $scope.init = () ->
      $scope.reestimateSrc =  $sce.trustAsResourceUrl($scope.getReestimateSrc(stock))
      $scope.show = true

    $scope.getReestimateSrc = (stock) ->
      category = ''
      if [23, 24, 33, 18, 25, 100].includes(stock.categoryId)
        category = 'new_gadget'
      else if stock.categoryId == 21
        category = 'new_all'
      else if stock.categoryId == 26
        category = 'figure'
      else if stock.code
        category = 'media'
      else
        category = 'auction'

      return $window.SATEI_HOST + "/estimate/reestimate/" + category + "?stock_id=" + stock.stockId
]
