app = angular.module('yahooAuctionOrderProcess', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ngFileUpload'
  'ui.bootstrap'
  'ngEnter',
  'GeeksCommon'
  'zaikoModal'
])

app.config ['$locationProvider', ($l)=>
  $l.hashPrefix('')
]

app.controller 'yahooAuctionOrderProcessController', [
  '$scope'
  '$rootScope'
  '$http'
  'NgTableParams'
  '$filter'
  '$timeout'
  '$q'
  '$location'
  'Upload'
  '$uibModal'
  'YahooAuctionOrderService'
  'YahooAuctionOrderItemService'
  ($scope, $rootScope, $http, NgTableParams, $filter, $timeout, $q, $location, Upload, $uibModal,
    YahooAuctionOrderService, YahooAuctionOrderItemService) ->
    version = 'v1'
    $scope.yahooAuctionOrders = []
    $scope.url = '/api/' + version + '/yahoo-auction-orders'
    # orderのstatus一覧を予め取得しておく
    $scope.yahooAuctionOrderStatuses = []
    $scope.isTableLoding = []
    url = '/api/' + version + '/yahoo-auction-order-statuses'
    $scope.islandDb = []

    $scope.init = ->
      $http.get(url).then (result) ->
        $scope.yahooAuctionOrderStatuses = result.data
        return
      YahooAuctionOrderService.islandDb().$promise.then((row) ->
        $scope.islandDb = _.map(row, (r) => r.full)
      )
      $rootScope.$on("$locationChangeSuccess", (event, next, current) ->
        $scope.tabStatus = switch $location.url()
          when "/successfulBid" then 2
          when "/contact" then 3
          when "/delayedContact" then 4
          when "/cancel" then 5
          when "/shippingPrice" then 6
          when "/demand" then 7
          when "/deposit" then 8
          when "/depositCancel" then 95
          when "/waitShipmentCancel" then 98
          else
            3
      );

    $scope.reload = ->
# 注文データを取得
      $scope.loading = true
      d = []
      #      d.push $scope.bidTable.reload()
      d.push $scope.contactTable.reload()
      #      d.push $scope.delayedContactTable.reload()
      d.push $scope.cancelTable.reload()
      d.push $scope.shippingPriceTable.reload()
      d.push $scope.demandTable.reload()
      d.push $scope.depositTable.reload()
      #      d.push $scope.depositCancelTable.reload()
      d.push $scope.waitShipmentCancelTable.reload()
      $q.all(d).then(->
        $scope.loading = false
      )
      return

    createAuctionTable = (status = 0, func = null)->
      new NgTableParams({
        page: 1
        count: 500
        sorting:
          'yahoo_auction_order_id': 'desc'
        filter:
          yahoo_auction_order_status_id: status
      },
        counts: [100, 500, 1000, 2000]
        total: 0
        getData: (params) ->
          $scope.isTableLoding[status + 5] = true
          checked = {}
          params.data.filter((e)->
            e.is_checked
          ).forEach((e) ->
            checked[e.yahoo_auction_order_id] = true
          )
          YahooAuctionOrderService.paginate(params.url()).$promise.then((data)->
            $scope.isTableLoding[status + 5] = false
            params.total(data.total)
            if func?
              func(data.data, checked)
            else
              data.data
          )
      )

    $scope.getTable = (status_id) =>
      switch status_id
        when 1 then $scope.bidTable
        when 2 then $scope.successfulBidTable #落札済み
        when 3 then $scope.contactTable #先行連絡ずみ
        when 4 then $scope.delayedContactTable
        when 5 then $scope.cancelTable
        when 6 then $scope.shippingPriceTable
        when 7 then $scope.demandTable
        when 8 then $scope.depositTable
        when 95 then $scope.depositCancelTable
        when 98 then $scope.waitShipmentCancelTable
        else
          undefined

    ###
    # 入札済み注文テーブル
    ###
    $scope.bidTable = []

    ###
    # 先行連絡済みテーブル
    ###
    $scope.contactTable = new NgTableParams({
      page: 1
      count: 500
      sorting:
        'yahoo_auction_order_item_id': 'desc'
      filter:
        yahoo_auction_order_item_status_id: 3
    },
      counts: [100, 500, 1000, 2000]
      total: 0
      getData: (params) ->
        $scope.isTableLoding[3] = true
        YahooAuctionOrderItemService.query(params.url()).$promise.then((data)->
          $scope.isTableLoding[3] = false
          params.total(data.total)
          data.data
        )
    )

    ###
    # 遅延連絡済みテーブル
    ###
    $scope.delayedContactTable = {
      reload: ()->
    }
    #    $scope.delayedContactTable = new NgTableParams({
    #      page: 1
    #      count: 500
    #      sorting: 'yahoo_auction_order_item_id': 'desc'
    #      filter: yahoo_auction_order_item_status_id: 4
    #    },
    #      counts: [100,500,1000,2000]
    #      total: 0
    #      getData: (params) ->
    #        $scope.isTableLoding[4] = true
    #        YahooAuctionOrderItemService.query(params.url()).$promise.then((data)->
    #          $scope.isTableLoding[4] = false
    #          params.total(data.total)
    #          data.data
    #        )
    #    )

    ###
    # キャンセル待ちテーブル
    ###
    $scope.cancelTable = new NgTableParams({
      page: 1
      count: 500
      sorting:
        'yahoo_auction_order_item_id': 'desc'
      filter:
        yahoo_auction_order_item_status_id: 5
    },
      counts: [100, 500, 1000, 2000]
      total: 0
      getData: (params) ->
        $scope.isTableLoding[5] = true
        YahooAuctionOrderItemService.query(params.url()).$promise.then((data)->
          $scope.isTableLoding[5] = false
          params.total(data.total)
          data.data
        )
    )

    ###
    # 送料未確定テーブル
    ###
    $scope.shippingPriceTable = createAuctionTable 1

    ###
    # 請求テーブル
    ###
    $scope.demandTable = createAuctionTable 2

    ###
    # 入金テーブル
    ###
    $scope.depositTable = createAuctionTable 3, ((d, checked)->
      return _.map(d, (row)=>
        if checked[row.yahoo_auction_order_id] then row.is_checked = true
        row
      )
    )

    #    $scope.depositCancelTable = createAuctionTable 95
    $scope.waitShipmentCancelTable = createAuctionTable(98)

    ###
    # 指定したステータスから前後１つずつのテーブルをリロードする => 数が減ったので無くす
    ###
    $scope.microReloadTable = (status_id)->
      $scope.reload()
      return

    $scope.saveYOrder = (yahooAuctionOrder, reload = true, columns = null)->
      if columns then yahooAuctionOrder = _.pick.apply(null, [yahooAuctionOrder, 'yahoo_auction_order_id'].concat(columns))
      YahooAuctionOrderService.save({id: yahooAuctionOrder.yahoo_auction_order_id}, yahooAuctionOrder).$promise.then((updatedYahooAuctionOrder) ->
        yahooAuctionOrder.updated_at = updatedYahooAuctionOrder.updated_at
        yahooAuctionOrder.is_saved = true
        if (reload == true)
          $scope.microReloadTable(yahooAuctionOrder.yahoo_auction_order_status_id + 5)
        $timeout((->
          yahooAuctionOrder.is_saved = false
        ), 2000)
      ).catch((error) ->
        if(error.data && error.data.error == 'ModelNotFoundException')
          alert("該当レコードは既に他のユーザによって更新されています。")
          $scope.microReloadTable(yahooAuctionOrder.yahoo_auction_order_status_id + 5)    # エラー時はデータリロード
        else
          defaultErrorHandler = $scope.catchError(['saveYOrder'])
          defaultErrorHandler(error)
        yahooAuctionOrder.is_failed = true
        $timeout((->
          yahooAuctionOrder.is_failed = false
        ), 2000)
      )

    $scope.syncYahooOrder = (yahooAuctionOrder) ->
      YahooAuctionOrderService.syncYahooAuctionOrder({id: yahooAuctionOrder.yahoo_auction_order_id}, yahooAuctionOrder).$promise.then(->
        $scope.microReloadTable(yahooAuctionOrder.yahoo_auction_order_status_id + 5)
      ).catch($scope.catchError(['syncYahooOrder']))

    ###
    # メールを送る時オーダIDが必要なのでそれを取り出す専用
    ###
    $scope.getCheckedOrderIds = (status_id, type = 'order')=>
      table = $scope.getTable(status_id)
      orderList = table.data
      if type == 'order'
        _(orderList).filter((row)->row.is_checked).map((yOrder)->yOrder.order.order_id).value()
      else if type == 'yahoo_order_item'
        _(orderList).filter((row)->row.is_checked).map((e)->e.yahoo_auction_order_item_id).value()
      else
        null

    $scope.moveOrderStatusId = (yahooAuctionOrder, status_id, showAlert = false, reload = true, showErr = true)->
      if showAlert then unless confirm "変更します。よろしいですか？" then return

      nowStatus = yahooAuctionOrder.yahoo_auction_order_status_id
      yahooAuctionOrder.yahoo_auction_order_status_id = status_id
      p = YahooAuctionOrderService.save({id: yahooAuctionOrder.yahoo_auction_order_id}, yahooAuctionOrder).$promise
      if reload
        p.then((updatedYahooAuctionOrder) ->
          yahooAuctionOrder.updated_at = updatedYahooAuctionOrder.updated_at
          $scope.microReloadTable(nowStatus + 5)
        ).catch((error) ->
          if (showErr)
            if(error.data && error.data.error == 'ModelNotFoundException')
              alert("該当レコードは既に他のユーザによって更新されています。")
              $scope.microReloadTable(yahooAuctionOrder.yahoo_auction_order_status_id + 5)
            else
              defaultErrorHandler = $scope.catchError(['moveOrderStatusId'])
              defaultErrorHandler(error)
        )
      p

    ###
    # 送料確定&注文の結合
    # OrderItemが集合体となった特殊な形式を渡す
    ###
    $scope.decideShippingPrice = (yahooAuctionOrder, showErr = true) ->
      if yahooAuctionOrder.is_loading then return else yahooAuctionOrder.is_loading = true
      $scope.moveOrderStatusId(yahooAuctionOrder, 2, false, true, showErr).then(()=> yahooAuctionOrder.is_loading = false)

    $scope.decideShippingPriceAll = (orders) ->
      targetOrders = orders.filter((o) -> o.is_checked)
      unless targetOrders.length && confirm(targetOrders.length + "件を移動します。") then return
      promises = targetOrders.map((o) -> $scope.decideShippingPrice(o, false))
      Promise.allSettled(promises).then((results) =>
        errors = results.filter((result) -> result.status == 'rejected')
        if errors.length > 0
          console.log("件数:" + errors.length)
          ModelNotFoundExceptionFlg = false
          OtherExceptionFlg = false
          errorMsg = ""
          traces = ['yahooAuctionOrderProcessController', 'decideShippingPriceAll']
          errors.forEach((error, index) ->
            console.log(JSON.stringify(error.reason.config.data.yahoo_auction_order_id))
            if(error.reason.data && error.reason.data.error == 'ModelNotFoundException')
              ModelNotFoundExceptionFlg = true
            else
              OtherExceptionFlg = true
              traces.push("/api/v1/yahoo-auction-orders/" + error.reason.config.data.yahoo_auction_order_id)
          )
          if (ModelNotFoundExceptionFlg == true)
            alert("該当レコードは既に他のユーザによって更新されています。")
          if (OtherExceptionFlg == true)
            alert("エラーが発生しました。以下の内容をコピーして管理者へ送ってください。\nMessage: \n" + traces.map(JSON.stringify).join("-> \n"))
        $scope.microReloadTable(7)
      )

    # 送料確定待ちへ戻す"
    $scope.returnShippingPrice = (yahooAuctionOrder)->
      unless confirm("送料確定待ちまで移動します") then return
      if yahooAuctionOrder.is_loading then return else yahooAuctionOrder.is_loading = true
      $scope.moveOrderStatusId(yahooAuctionOrder, 1, false).then(() =>
        yahooAuctionOrder.is_loading = false
      )
      return true

    # 最後に送った連絡日を指定のフォーマットで返す
    $scope.lastSendMailDate = (yahooAuctionOrder)->
      date = yahooAuctionOrder.last_contact_date
      if date isnt null
        moment(date).format("YYYY-MM-DD H:mm:ss")
      else
        null

    $scope.clickOrderItem = (yahooAuctionOrderItem)->
      if yahooAuctionOrderItem.is_customer then return false
      yahooAuctionOrderItem.is_checked = !yahooAuctionOrderItem.is_checked
      return false

    # 時間の比較
    $scope.diffHour = (baseDate, elapsed)->
      if baseDate is null then return
      baseDate = moment(baseDate)
      baseDate = baseDate.add(elapsed, 'days').format('YYYYMMDD')
      now = moment().format('YYYYMMDD')
      if baseDate <= now then true else false

    $scope.checkToday = (a1, a2)->
      moment(a1).day() == moment(a2).day()

    # 同梱可かどうかのチェック
    $scope.checkIsDoukon = (yahooAuctionStock) ->
      if yahooAuctionStock.is_doukon is 1 then return true else return false

    $scope.checkAll = (orders, flg = true)->
      orders.forEach((e, i) => orders[i].is_checked = flg)

    $scope.checkRowAddress = (order) ->
      $scope.isNotStandardShippingPrice(order) || $scope.existBlockNumber(order) || $scope.isPostOfficeStop(order) || $scope.isSalesOfficeStop(order)

    # 離島の判定
    $scope.isNotStandardShippingPrice = (order) ->
      $scope.islandDb.filter((row) => order.shipping_full_address.startsWith(row)).length > 0

    # 番地が存在するかどうかの判定
    $scope.existBlockNumber = (order) ->
      if !order.shipping_address1.match(/[0-9０-９]/) and
        !order.shipping_address2.match(/[0-9０-９]/) and
        !order.shipping_address3.match(/[0-9０-９]/) then return true

    # 郵便局止めの判定
    $scope.isPostOfficeStop = (order) ->
      !!order.shipping_full_address.match(/郵便局/)

    # 営業所止めの判定
    $scope.isSalesOfficeStop = (order) ->
      !!order.shipping_full_address.match(/営業所|止め|停め|とめ/)

    # 請求メールアドレスのビューを表示
    $scope.showDemandMail = (yahooAuctionOrder) ->
      $scope.demandMailModalInstance = $uibModal.open(
        templateUrl: 'demandMail.html'
        controller: 'demandMailModalController'
        scope: $scope)
      $scope.demandMailModalInstance.yahooAuctionOrder = yahooAuctionOrder
      #modalをcloseした際の処理
      $scope.demandMailModalInstance.result.then ->
        `var url`
        url = $scope.url + '/' + yahooAuctionOrder['yahoo_auction_order_id']
        data = 'yahoo_auction_order_status_id': 8
        order_url = '/api/' + version + '/orders/' + yahooAuctionOrder['order_id']
        order_data = 'order_status_id': 2
        $q.all([
          $http.put(url, data)
          $http.put(order_url, order_data)
        ]).then ((result) ->
          $scope.reload()
          return
        ), (errorMesasge) ->
          console.log errorMesasge
          return
        return
      return
    $scope.checkPayStatusMapFunc = (mails) ->
      mails.map((mail) -> Object.assign(mail, {category_id: 46, is_send: if mail.order.yahoo_auction_order.yahoo_pay_status < 1 then 1 else 0}))

    # 注文メール送信
    $scope.sendOrderMail = (yahooAuctionOrders, OrderIds, templateId, statusId, func = null, type = 'order_id', mapFunc = null) ->
      console.log('yahooAuctionOrders:' + yahooAuctionOrders)
      promises = yahooAuctionOrders.map((yahooAuctionOrder) -> YahooAuctionOrderService.verifyUpdatedAt({id: yahooAuctionOrder.yahoo_auction_order_id}, yahooAuctionOrder).$promise)
      Promise.all(promises).then(() ->
        modal = $uibModal.open(
          templateUrl: '/html/angular/mail_ng.html',
          controller: 'YahooSendMailController',
          scope: $scope
          size: 'lg'
          resolve: {
            OrderIds: -> OrderIds
            templateId: -> templateId
            statusId: -> statusId
            type: -> type
            mapFunc: -> mapFunc
          }
        )
        modal.result.then(->
          if func?
            func().then(->
              if statusId > 0 then $scope.microReloadTable(statusId + 5)
            )
          else
            if statusId > 0 then $scope.microReloadTable(statusId + 5)
        )
      ).catch((error) ->
        if(error.data && error.data.error == 'ModelNotFoundException')
          alert("該当レコードは既に他のユーザによって更新されています。")
        $scope.microReloadTable(statusId)
      )

    # 一括請求メール送信
    $scope.sendOrderMailAll = () ->
# 請求タブのチェックされているデータの取得
      demands = $scope.getTable(7).data
      checkedDemands = _(demands).filter((row) -> row.is_checked).value()
      checkedDemandIds = _(checkedDemands).filter((row) -> row.is_checked).map((e) -> e.order_id).value()

      # メール送信
      $scope.sendOrderMail(checkedDemands, checkedDemandIds, 3, 3, null, 'order_id', $scope.checkPayStatusMapFunc)

    $scope.sendMail = (OrderIds, templateId, statusId, func = null, type = 'order_id', mapFunc = null) ->
      modal = $uibModal.open(
        templateUrl: '/html/angular/mail_ng.html',
        controller: 'YahooSendMailController',
        scope: $scope
        size: 'lg'
        resolve: {
          OrderIds: -> OrderIds
          templateId: -> templateId
          statusId: -> statusId
          type: -> type
          mapFunc: -> mapFunc
        }
      )
      modal.result.then(->
        if func?
          func().then(->
            $scope.microReloadTable(statusId)
          )
        else
          $scope.microReloadTable(statusId)
      )

    $scope.moveTab = (tabNumber)->
      $timeout(->
        angular.element("[role='tabpanel'] ul li:nth-child(" + tabNumber + ") a").click()
      )

    $scope.shipmentOption = (yahooAuctionOrder)->
      modal = $uibModal.open(
        templateUrl: '/html/angular/shipment_option_ng.html',
        controller: 'YahooAuctionShipmentOptionController',
        scope: $scope
        resolve: {
          yahooAuctionOrder: -> yahooAuctionOrder
        }
      )
      modal.result.then((d)=>
        if d?
          yahooAuctionOrder.yahoo_auction_shipment_option = d.yahooAuctionShipmentOption
        else
          yahooAuctionOrder.yahoo_auction_shipment_option = null
      )

    $scope.openCustomerMessage = (YahooAuctionOrder)->
      text = """
領収書：\t#{if YahooAuctionOrder.yahoo_is_need_receipt then "領収書希望" else ""}
お客様要望：\t#{YahooAuctionOrder.yahoo_buyer_comments}

お届け希望日：\t#{YahooAuctionOrder.yahoo_ship_request_date}
お届け希望時間帯：\t#{YahooAuctionOrder.yahoo_ship_request_time}
"""
      modal = $uibModal.open(
        templateUrl: '/html/angular/yahoo_auction_customer_message_ng.html',
        controller: 'YahooAuctionCustomerMessageController',
        controllerAs: "c",
        resolve: {
          text: -> text
        }
      )

    ###*
    # キャンセル処理
    ###
    $scope.cancelOrder = (yahooAuctionOrder)->
      if !confirm('再出品依頼を行います\nヤフオク上で再出品が行えなかった商品のみ、この機能を使ってください。')
        return
      YahooAuctionOrderService.cancelAndReExportRequest({
        yahoo_auction_order_id: yahooAuctionOrder.yahoo_auction_order_id
      }, yahooAuctionOrder).$promise.then((updatedYahooAuctionOrder)->
        yahooAuctionOrder.updated_at = updatedYahooAuctionOrder.updated_at
        $scope.microReloadTable(yahooAuctionOrder.yahoo_auction_order_status_id + 5)
      ).catch((error) ->
        if(error.data && error.data.error == 'ModelNotFoundException')
          alert("該当レコードは既に他のユーザによって更新されています。")
          $scope.microReloadTable(yahooAuctionOrder.yahoo_auction_order_status_id + 5)    # エラー時はデータリロード
        else
          defaultErrorHandler = $scope.catchError(['cancelOrder'])
          defaultErrorHandler(error)
      )
      # キャンセル時にここでメールを送らないのでコメントアウト
      #      $scope.sendMail([yahooAuctionOrder.order.order_id], 6, null, =>
      #        YahooAuctionOrderService.cancel({
      #          yahoo_auction_order_id: yahooAuctionOrder.yahoo_auction_order_id
      #        }).$promise.then(->
      #          $scope.microReloadTable(yahooAuctionOrder.yahoo_auction_order_status_id +5)
      #        )
      #      )
      return
    $scope.cancelAndReExportOrder = (yahooAuctionOrder)->
      if !confirm('未入金キャンセル処理を行います\nヤフオクで同一カタログから再出品を行っている場合のみ使えます。')
        return
      YahooAuctionOrderService.cancel({
        yahoo_auction_order_id: yahooAuctionOrder.yahoo_auction_order_id
      }).$promise.then(->
        $scope.microReloadTable(yahooAuctionOrder.yahoo_auction_order_status_id + 5)
      )
    # 再出品
    $scope.cancelItem = (orderItem)->
      if !confirm('本当にキャンセルしてよろしいですか？')
        return
      $scope.sendMail([orderItem.yahoo_auction_order_item_id], 6, null, =>
        YahooAuctionOrderItemService.cancel(orderItem).$promise.then(->
          $scope.cancelTable.reload()
        )
      , 'yahoo_order_item')
      return

    # 一括再出品
    $scope.cancelItemAll = () ->
# 再出品タブのチェックされているデータの取得
      cancels = $scope.getTable(5).data
      checkedCancels = _(cancels).filter((row) -> row.is_checked).value()
      checkedCancelIds = _(cancels).filter((row) -> row.is_checked).map((e) -> e.yahoo_auction_order_item_id).value()

      # メール送信・再出品
      modal = $uibModal.open(
        templateUrl: '/html/angular/mail_ng.html',
        controller: 'YahooSendMailController',
        scope: $scope
        size: 'lg'
        resolve: {
          OrderIds: -> checkedCancelIds
          templateId: -> 6
          statusId: -> null
          type: -> 'yahoo_order_item'
          mapFunc: -> (d)-> _.map(d, (r) -> Object.assign({category_id: 56}, r))
        }
      )
      modal.result.then(->
        defers = checkedCancels.map((cancel) -> YahooAuctionOrderItemService.cancel(cancel).$promise)
        Promise.all(defers).then(->
          $scope.cancelTable.reload()
        )
      )
      return

    $scope.moveItemStatus = (orderItem)->
      if !confirm('本当に変更してよろしいですか？')
        return
      nowStatusId = orderItem.yahoo_auction_order_item_status_id
      YahooAuctionOrderItemService.save(orderItem).$promise.then((d)=>
        $scope.contactTable.reload()
        $scope.delayedContactTable.reload()
        $scope.cancelTable.reload()
      ).catch($scope.catchError(['moveItemStatus']))

    $scope.cancelBeforeDeposit = (yahooAuctionOrder)->
      if !confirm('本当にキャンセルしてよろしいですか？')
        return
      YahooAuctionOrderService.cancelBeforeDeposit({
        yahoo_auction_order_id: yahooAuctionOrder.yahoo_auction_order_id
      }).$promise.then(->
        $scope.microReloadTable(yahooAuctionOrder.yahoo_auction_order_status_id + 5)
      )
      return

    $scope.focus = (css)->
      console.log(css)
      $timeout(->
        angular.element(css).focus()
      )
    $scope.saveYOrderItem = (orderItem)->
      YahooAuctionOrderItemService.save(orderItem).$promise.then((d)=>
        _.each(Object.keys(orderItem), (key)=>
          if d[key]? then orderItem[key] = d[key]
        )
      )


    # オブジェクトのlengthを返す
    # filterで$promiseと$resolvedを除外しないと正確が長さが測れないため記述
    $scope.objectSize = (object)->
      Object.keys(object).filter((v) ->
        return v isnt '$promise' and v isnt '$resolved'
      ).length

    $scope.elementJump = (depositId, e)->
      document.querySelector('#deposit_' + depositId).scrollIntoView()
    $scope.shippingPriceJump = (yahooId)->
      document.querySelector('#shippingPrice_' + yahooId).scrollIntoView()

    $scope.catchError = (traces = [])->
      traces.unshift('yahooAuctionOrderProcessController')
      (error) ->
        traces.push(error.config.url)
        if error.data then traces.push(error.data)
        alert("エラーが発生しました。以下の内容をコピーして管理者へ送ってください。\nMessage: \n" + traces.map(JSON.stringify).join("-> \n"))
        $scope.reload()

    return

]

# 請求メール送信
app.controller 'demandMailModalController', [
  '$scope'
  '$http'
  '$q'
  '$uibModalInstance'
  ($scope, $http, $q, $uibModalInstance) ->
    url = '/api/v1/mail/' + $uibModalInstance.yahooAuctionOrder['order_id'] + '?path=/order/yahooAuction/demand'
    $http.get(url).then ((result) ->
      $scope.mailAddress = result.data.mail_address
      $scope.subject = result.data.subject
      $scope.content = result.data.content
      return
    ), (errorMessage) ->
      alert 'error'
      console.log errorMessage
      return

    ###
    # 請求処理メール送信
    ###

    $scope.send = ->
      `var url`
      data =
        'mail_address': $scope.mailAddress
        'subject': $scope.subject
        'content': $scope.content
      if !data['subject'] or !data['content'] or !data['mail_address']
        alert '宛先・件名・メールアドレスを全て入力してください'
        return
      if !confirm('送信してよろしいですか？')
        return
      url = '/api/v1/mail/send'
      $scope.sending = true
      $http.post(url, data).then ((result) ->
        $scope.sending = false
        $uibModalInstance.close()
        return
      ), (errorMessage) ->
        alert '送信error'
        console.log errorMessage
        return
      return

    return
]
