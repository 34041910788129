app = angular.module('YahooAuctionWarehousingSmart', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ngFileUpload'
  'ui.bootstrap'
  'nl2br'
  'ngSanitize'
  'ngResource'
  'GeeksCommon'
  'yahooAuctionExport'
])

app.controller 'yahooAuctionWarehousingSmartIndexController', [
  'YahooAuctionStockService'
  'StockService'
  'ConditionService'
  'PositionService'
  'AuctionService'
  '$uibModal'
  '$scope'
  '$q'
  'Upload'
  'YahooAuctionShippingCostTable'
  '$timeout'
  class yahooAuctionExportSmartIndexController
    yahoo_auction_stock_id: null
    estimate_item_id: null
    yahoo_auction_stock: null
    search_type: 'estimate_item_id'
    msg: ""

    constructor: (@YahooAuctionStockService, @StockService, @ConditionService, @PositionService, @AuctionService, @$uibModal, @$scope, @$q, @Upload, @YahooAuctionShippingCostTable, @$timeout)->
      @ConditionService.query().$promise.then((d)=>
        @conditions = d
      )
      @yahooAuctionShippingCostTable = @YahooAuctionShippingCostTable
      @focus("#" + @search_type)

    reset: ()->
      @yahoo_auction_stock_id = null
      @estimate_item_id = null
      @yahoo_auction_stock = null
      @updating = false

    searchAuctionStock: ()=>
      unless @yahoo_auction_stock_id > 0 or @estimate_item_id > 0 then return
      if @yahoo_auction_stock_id > 0
        @YahooAuctionStockService.get({yahoo_auction_stock_id: @yahoo_auction_stock_id}).$promise.then((d)=>
          if d.yahoo_auction_stock_id > 0
            @yahoo_auction_stock = d
            @search_type = 'yahoo_auction_stock_id'
            @focus('#position_id')
        )
      else
        @YahooAuctionStockService.query({
          "filter[estimate_item_id]": @estimate_item_id
        }).$promise.then((d)=>
          if d.total == 1
            @yahoo_auction_stock_id = d.data[0].yahoo_auction_stock_id
            @YahooAuctionStockService.get({yahoo_auction_stock_id: @yahoo_auction_stock_id}).$promise.then((d)=>
              if d.yahoo_auction_stock_id > 0
                @yahoo_auction_stock = d
                @search_type = 'estimate_item_id'
                @focus('#position_id')
            )
        )
      @msg = ""

    # ヤフオクカテゴリ選択
    selectYahooAuctionCategory: ()->
      modalInstance = @$uibModal.open(
        templateUrl: '/html/angular/auction_ng.html'
        controller: 'ModalInstanceController'
        size: 'lg'
        resolve: {
          SuggestKeyword:  => @yahoo_auction_stock.title
        }
      )
      modalInstance.result.then (yahoo) =>
        @yahoo_auction_stock.yahoo_auction_category ?= {}
        @yahoo_auction_stock.yahoo_category_id = yahoo.yahoo_category_id
        @yahoo_auction_stock.yahoo_auction_category.category = yahoo.category_name
        @yahoo_auction_stock.all_yahoo_category_name = yahoo.all_category_name
        return

    # 商品説明HTMLの表示
    showTemplate: (yahooAuctionStockId) =>
      @YahooAuctionStockService.showTemplate({yahoo_auction_stock_id: yahooAuctionStockId}).$promise.then((d)=>
        templateView = d.template
        templateView = '<div class="modal-content" style="width: 790px;margin-left: -95px;">' + templateView
        templateView = templateView + '</div>'
        @$uibModal.open template: templateView
      )

    # 送料の設定
    setShippingPrice: (shippingCost) ->
      @yahoo_auction_stock.shipping_price = shippingCost.price;

    # 同梱の自動設定
    setIsDoukon: (shippingCost) ->
      if shippingCost.name is '同梱可'
        @yahoo_auction_stock.is_doukon = 1
      else
        @yahoo_auction_stock.is_doukon = 0

    # 送料と同梱が一致しているか
    isValidIsDoukon: () ->
      if (!@yahoo_auction_stock)
        return false

      if (@yahoo_auction_stock.shipping_price is 864 and @yahoo_auction_stock.is_doukon is 1) or
         (@yahoo_auction_stock.shipping_price isnt 864 and @yahoo_auction_stock.is_doukon is 0)
        return true
      return false

    focus: (elm)->
      @$timeout(->
        document.querySelector(elm).focus({preventScroll: true});
      )

    # 商品データ保存 + 画像の保存
    updateExportData: ->
      if @$scope.yahooAuctionStockForm.$valid == false
        return
      @updating = true

      # 保存する前にPosition検証
      stock = @yahoo_auction_stock['stock']
      label = stock.position_label
      if label?.length > 2 then stock.editPos = true else stock.editPos = false
      p = @PositionService.get({"filter[label]":label}).$promise
      p.then((d)=>
        position = d.toJSON()
        if stock.editPos
          if d.total == 1
            position = d.data[0]
            stock.position_id = position.position_id
          else
            position = null
          if position == null
            alert("ポジションが見つかりません。")
            @updating = false
            return

        # YStockとStockの更新
        @StockService.update(stock).$promise.then(=>
          @YahooAuctionStockService.update(@yahoo_auction_stock).$promise.then((d)=>
            id = (@yahoo_auction_stock.stock.estimate_item_id ? @yahoo_auction_stock.yahoo_auction_stock_id).toString(10).padStart(8, 0)
            @msg = id.substr(0,4) + "-" + id.substr(4, 4) + " を [" + position.position_text_format + "] に入庫しました。"
            @reset()
            @focus("#" + @search_type)
          );
        )

      )
      return
]
