jQuery(($)=>
  $.AdminLTESidebarTweak = {};

  $.AdminLTESidebarTweak.options = {
    EnableRemember: true,
    NoTransitionAfterReload: false
    #Removes the transition after page reload.
  };

  "use strict"
  $("body").on('collapsed.pushMenu', ()=>
    if $.AdminLTESidebarTweak.options.EnableRemember
      localStorage.setItem("toggleState", "closed")
  )

  $("body").on("expanded.pushMenu", ()=>
    if $.AdminLTESidebarTweak.options.EnableRemember
      localStorage.setItem("toggleState", "opened")
  )

  if $.AdminLTESidebarTweak.options.EnableRemember
    toggleState = localStorage.getItem("toggleState")
    if toggleState == "closed"
      if $.AdminLTESidebarTweak.options.NoTransitionAfterReload
        $("body").addClass('sidebar-collapse hold-transition').delay(100).queue(->
          $(this).removeClass('hold-transition')
        )
      else
        $("body").addClass('sidebar-collapse')
)