app = angular.module('yahooAuctionTanaoroshi', [
  'angular-loading-bar'
  'ngTable'
  'ngEnter'
  'GeeksCommon',
  'tableSort'
])

app.controller 'YahooAuctionTanaoroshiController', [
  '$scope'
  '$q'
  'NgTableParams'
  '$uibModal'
  'YahooAuctionTanaoroshiService'
  '$timeout'
  class YahooAuctionTanaoroshiController
    constructor: (@$scope, @$q, @NgTableParams, @$uibModal, @YahooAuctionTanaoroshiService, @$timeout) ->
      @isSearch = false
      @position_id = ""
      @search_position = ""
      @stockTotalCount = 0
      @stockCount = 0
      @stockTable = new @NgTableParams({}, {
        counts: []
        getData: (params)=>
          @isSearch = true
          paramsUrl = params.url()
          paramsUrl['position'] = @search_position
          @YahooAuctionTanaoroshiService.query(paramsUrl).$promise.then((d)=>
            @isSearch = false
            params.data = d.data
            @calcStockCount()
            d.data
          )
      })

    calcStockCount: ()->
#      console.log(@stockTable.data)
      @stockTotalCount = @totalAndSellCount()
      @stockCount = @totalCount()

    totalCount: ()->
      @stockTable.data?.filter((r)=> r.date == null && !r.stock.is_empty_stock).length

    totalAndSellCount: ()->
      @stockTable.data?.filter((r)=>!r.stock.is_empty_stock).length


    doEmptyStock: (d, $event)->
      if !!d.date then return false
      stock = d.stock
      stock.is_empty_stock = !stock.is_empty_stock
      @calcStockCount()
      $event.stopPropagation()
      return false

    viewEstimateItemId: (id)->
      id = "" + id
      id.slice(0, id.length - 4) + "-" + id.slice(-4, id.length)

    searchPosition: () ->
      @position_id = @search_position
      @stockTable.reload()

    finish: () ->
      list = []
      if !confirm('チェックが入っている商品を除却します。') then return
      for stock in @stockTable.data
        if stock.stock.is_empty_stock
          list.push(stock.stock.stock_id)

      if list.length > 0 then @YahooAuctionTanaoroshiService.doTanaoroshi({
        "stocks": list,
        "position_id": @position_id
      }).$promise.then((d) =>
        alert("処理しました")
        @position_id = ""
        @search_position = ""
        @stockTotalCount = 0
        @stockCount = 0
        @stockTable.reload()
      )
]
