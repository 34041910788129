app = angular.module('amazonWaitCatalogStock', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ngEnter'
  'GeeksCommon'
  'ngFileUpload'
])
app.config ['$locationProvider', ($locationProvider) ->
  $locationProvider.hashPrefix('!')
]

app.controller 'amazonWaitCatalogStockController', [
  '$scope'
  '$http'
  '$location'
  'NgTableParams'
  '$filter'
  '$timeout'
  '$q'
  'AmazonStockService'
  'Upload'
  ($scope, $http, $location, NgTableParams, $filter, $timeout, $q, AmazonStockService, Upload) ->
    $scope.version = 'v1'
    $scope.amazonStocks = []
    $scope.url = '/api/' + $scope.version + '/amazon-stocks'

    $scope.init = ->
      conditions_url = '/api/' + $scope.version + '/conditions/amazon-conditions'
      $q.all([
        $http.get(conditions_url)
      ]).then ((results) ->
        $scope.conditions = results[0].data
        return
      ), (errorMessage) ->
#        alert 'error'
        console.log errorMessage
        return
      return

    ###*
    # Amazon在庫テーブル
    ###

    $scope.reload = ->
      $scope.amazonStockTable.reload()
      $scope.editAmazonStock = null

    $scope.amazonStockTable = new NgTableParams({
        page: 1
        count: 10
      },
      getData: (table) ->
#        table.filter = $scope.getSearchQueryParams()
        return AmazonStockService.waitCatalogQuery(table.url()).$promise.then((d)=>
          table.total(d.total)
          d.data
        )
    )
    $scope.retryFeed = (amazonStock)->
      if !confirm('本当に更新完了してよろしいですか？')
        return

      AmazonStockService.retryFeed({amazon_stock_id: amazonStock.amazon_stock_id}).$promise.then((d)=>
        alert("更新完了しました")
        $scope.reload()
      )

    # 編集画面の表示、非表示によって、左右のテーブルのクラスを変える
    $scope.$watch 'editAmazonStock', (newValue) ->
      if newValue
        $scope.leftBoxClass = 'col-md-6'
        $scope.rightBoxClass = 'col-md-6'
      else
        $scope.leftBoxClass = 'col-md-12'
        $scope.rightBoxClass = ''
      return
]
