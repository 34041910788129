app = angular.module('YahooAuctionShippingSlip', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ngFileUpload'
  'ngEnter',
  'ngPapaParse'
  'GeeksCommon'
])
app.controller 'YahooAuctionShippingSlipController', [
  '$scope'
  '$http'
  'NgTableParams'
  '$filter'
  '$timeout'
  '$q'
  'Upload'
  'DeliveryCompanyService'
  'YahooAuctionShippingSlipService'
  'ShipmentService'
  ($scope, $http, NgTableParams, $filter, $timeout, $q, Upload,
   DeliveryCompanyService, YahooAuctionShippingSlipService, ShipmentService) ->
    $scope.viewShippingSlipGroupId = null;
    $scope.deleteShipmentForm = false

    #出荷通知用
    $scope.notifyDatas = [];

    $scope.getNotifyTemplate = ()->
      {
      shipment_id: null,
      delivery_company_id: 1
      tracking_number: null
      }
    $scope.addNotifyTemplate = ->
      $scope.notifyDatas.push $scope.getNotifyTemplate()

    $scope.init = ->
      # 配送会社を取得
      DeliveryCompanyService.query().$promise.then((d)->
        $scope.deliveryCompanies = d
        $scope.addNotifyTemplate()
        $scope.addNotifyTemplate()
      )
      return


    $scope.tableReload = ->
      $scope.shippingSlipTable.reload()
      return

    ###
    # 注文テーブル
    ###

    $scope.shippingSlipTable = new NgTableParams({
      page: 1
      count: 20
      sorting: 'shipping_slip_group_id': 'desc'
      filter:
        type: 'YAHOO'
    },
      getData: (params) ->
        YahooAuctionShippingSlipService.query(params.url()).$promise.then((d)->
          params.total(d.total)
          d.data
        )
)

    $scope.viewPrintDate = (date)->
      moment(date).format('MM月DD日 H時m分')

    ###
    # 納品書詳細データ
    ###
    $scope.shippingSlipDetailTable = new NgTableParams({
      page: 1,
      count: 1000
    },{
      counts: [1000,2000, 5000, 10000],
      getData: (params) ->
        if($scope.viewShippingSlipGroupId > 0)
          params.parameters({id: $scope.viewShippingSlipGroupId})
          YahooAuctionShippingSlipService.query(params.url()).$promise.then((d)->
            params.total(d.total)
            d.data
          )
    })

    $scope.viewShippingSlipDetail = (id)->
      $scope.viewShippingSlipGroupId = id
      $scope.shippingSlipDetailTable.reload()

    $scope.showDeleteShipmentForm = ->
      $scope.deleteShipmentForm = !$scope.deleteShipmentForm

    $scope.deleteShipment = (id = null)->
      ids = if id? then "#{id}" else $scope.deleteShipmentIds
      ids = ids?.split('\n').filter((e)-> isFinite(e) and e isnt null) ? []

      if ids.length > 0 and confirm("#{ids.length} 件未発見処理します。")
        defers = []
        ids.forEach((e)->
          defer = $http.put("#{$scope.url}/shipments/#{e}", {is_not_found: 1})
          defers.push defer
        )
        $q.all(defers).then((r)->
          alert('処理完了')
          $scope.deleteShipmentIds = [];
          $scope.tableReload()
          $scope.shippingSlipDetailTable.reload()
        )

    $scope.getDeliveryCompany = (id)->
      $scope.deliveryCompanies[id]
    $scope.changeAllDeliveryCompany = (id)->
      d = $scope.getDeliveryCompany(id)
      _.each($scope.notifyDatas, (e)->
        e.delivery_company_id = id
        e.delivery_method = d.delivery_method
      )

    $scope.parseFile = (file)->
      if file is null then return
      $scope.loadCsvFlg = true
      $scope.notifyDatas = _.filter($scope.notifyDatas, (r)-> isFinite(r.shipment_id) && r.shipment_id != null && r.shipment_id != "")
      Papa.parse(file, {
        encoding: 'Shift-JIS',
        step: (r)->
          d = $scope.getDeliveryCompany(1)
          row = r.data
          if row.length == 1
            row = row[0]
          if isFinite row[29]
            notifyData = {
              shipment_id: row[29],
              tracking_number: row[0]
              delivery_company_id: d.delivery_company_id
              delivery_method : d.delivery_method
            }
            $scope.notifyDatas.push notifyData
        complete: (result)->
          $timeout(->
            $scope.notifyDatas = _.filter($scope.notifyDatas, (r)-> isFinite(r.shipment_id) && r.shipment_id != null && r.shipment_id != "")
          )
          $scope.loadCsvFlg = false
      })

    $scope.sendShipmentReport = ->
      ShipmentService.saveYahoo({
        shipments: $scope.notifyDatas
      }).$promise.then((r)->
        $scope.notifyDatas = []
        $scope.addNotifyTemplate()
        $scope.addNotifyTemplate()
        $scope.tableReload()
        $scope.shippingSlipDetailTable.reload()
      )

    $scope.positionView = (position)->
      warehouse = position.position_group.warehouse
      position_group = position.position_group

      return "#{warehouse.prefix} #{position_group.floor}階 #{position_group.label} #{position.label}"

    return
]

app.directive('ngMoveNext', ->
  (scope, element, attrs)->
    element.bind "keydown keypress", (e)->
      if(e.which == 13)
        scope.$apply(->
          $e = $(element[0])
          $tr = $e.parents('tr')
          $table = $tr.parent()
          $trs = $table.children()
          index = $trs.index($tr)
          if attrs.ngModel is "row.shipment_id"
            if /^[0-9]*$/.test(element.val())
              $tr.find('[ng-model="row.tracking_number"]').focus()
          else if attrs.ngModel is "row.tracking_number"
            if element.val() isnt ""
              console.log $trs.length ,index
              if $trs.length - index < 4
                scope.$eval(attrs.ngMoveNext)
              $tr = $($trs[index+1])
              $input = $tr.find('[ng-model="row.shipment_id"]')
              $input.focus()
        )
        e.preventDefault()
      return
)
