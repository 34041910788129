app = angular.module('amazonReWarehouseCancelStock', [
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'GeeksCommon'
])

app.controller 'AmazonReWarehouseCancelStockController', [
  'AmazonOrderItemStockService',
  'AmazonOrderService'
  class AmazonReWarehouseCancelStockController
    constructor: (@AmazonOrderItemStockService, @AmazonOrderService)->
      @init()

    init: ()->
      @amazonOrderId = ""
      @amazonOrder = null
      @amazonOrderItemStock = null
      @isSearch = false
      @isSubmit = false
      @msg = ""
      @info_msg = ""
      @focus("#amazon_order_id")
      @resetStock()

    resetStock: (focus = false)->
      @code = ""
      @position_label = ""
      @targetAmazonOrderItemStock = null
      if focus
        @searchAmazonOrder()
        @focus("#code")

    searchAmazonOrder: ()->
      if @amazonOrderId?.length == 0
        return
      if @isSearch then return
      @isSearch = true
      @AmazonOrderService.get({amazon_order_id: @amazonOrderId}).$promise.then((d)=>
        res = d.toJSON();
        if res.amazon_order_id?
          @amazonOrder = res;
          @focus("#code")
        else
          @amazonOrder = null
          @amazonOrderId = ""
          @msg = "注文が見つかりません。"
        @isSearch = false
      , ()=>
        @isSearch = false
        @msg = "注文が見つかりません。"
      )

    searchStockOfCode: (code)->
      amazon_order_item_stocks = _.filter(_.flatten(_.map(@amazonOrder.amazon_order_items, (item)=> item.amazon_order_item_stocks)), (row)=> row?.amazon_order_item_stock_status_id == 97)
      console.log(amazon_order_item_stocks)
      amazon_order_item_stock = _.find(amazon_order_item_stocks, (row)=> row.stock?.code == code)
      if amazon_order_item_stock?.amazon_order_item_stock_id && amazon_order_item_stock?.stock?
        @targetAmazonOrderItemStock = amazon_order_item_stock
        @msg = ""
        @focus("#position_label")
      else
        @msg = "商品が見つかりません。"
        @resetStock(true)
    searchStockOfId: (stock_id)->
      amazon_order_item_stocks = _.filter(_.flatten(_.map(@amazonOrder.amazon_order_items, (item)=> item.amazon_order_item_stocks)), (row)=> row?.amazon_order_item_stock_status_id == 97)
      amazon_order_item_stock = _.find(amazon_order_item_stocks, (row)=> row.stock?.stock_id == stock_id)
      if amazon_order_item_stock?.amazon_order_item_stock_id && amazon_order_item_stock?.stock?
        @targetAmazonOrderItemStock = amazon_order_item_stock
        @msg = ""
        @code = amazon_order_item_stock.stock.code
        @focus("#position_label")
      else
        @msg = "商品が見つかりません。"
        @resetStock(true)
    reWarehouseCancelStock: ()=>
      if @isSubmit then return
      @isSubmit = true
      @AmazonOrderItemStockService.reWarehouse({
        amazon_order_item_stock_id: @targetAmazonOrderItemStock.amazon_order_item_stock_id,
        position_label: @position_label
      }).$promise.then((d)=>
        @info_msg = "入庫しました。"
        @resetStock(true)
        @isSubmit = false
      ,(d)=>
        @isSubmit = false
        if d.data?.error?.position?
          @msg = "このポジションは存在しません。"
          @position_label = ""
          @focus("#position_label")
          return
        @msg = "エラーにより、処理できませんでした。"
        @resetStock(true)
      )

    focus: (target)->
      $(target).focus()

]
