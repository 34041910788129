app = angular.module('order', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ngFileUpload'
  'ngEnter'
])
app.controller 'orderController', [
  '$scope'
  '$http'
  'NgTableParams'
  '$filter'
  '$timeout'
  '$q'
  'Upload'
  ($scope, $http, NgTableParams, $filter, $timeout, $q, Upload) ->
    version = 'v1'
    $scope.orders = []
    $scope.url = '/api/' + version + '/orders'
    # orderのstatus一覧を予め取得しておく
    $scope.orderStatuses = []
    $http.get("/api/#{version}/order-statuses").then (result) ->
      $scope.orderStatuses = result.data
      $scope.orderStatusNames = _.map($scope.orderStatuses, (val, key)-> {id: val.status_id, title: val.status_name})
      return
    # 配送会社を取得
    $scope.deliveryCompanies = []
    $http.get("/api/#{version}/delivery-companies").then (result) ->
      $scope.deliveryCompanies = result.data
      return


    $scope.init = ->

      return

    $scope.tableReload = ->
      $scope.orderTable.reload()
      return

    ###
    # 注文テーブル
    ###

    $scope.orderTable = new NgTableParams({
      page: 1
      count: 10
      sorting: 'order_id': 'desc'
    },
      getData: ($defer, table) ->
        $params = table.$params
        p = $params
        $http.get("#{$scope.url}/with-stocks?#{$.param(p)}").then((result)->
          d = result.data
          table.total d.total
          $defer.resolve(d.data)
        )
        return
)

    ###*
    # 編集画面表示
    ###

    $scope.showEdit = (editOrder) ->
      $scope.editOrder = editOrder
      return

    $scope.hideEdit = ->
      $scope.editOrder = null
      return

    $scope.edit = ->
      $scope.editFlg = if $scope.editFlg then false else true
      return

    ###*
    # 注文更新
    # @param order
    # @param type
    ###

    $scope.update = ->
      `var url`
      if $scope.orderForm.$valid == false
        return
      url = $scope.url + '/' + $scope.editOrder['order_id']
      data = 
        'price': $scope.editOrder['price']
        'shipping_price': $scope.editOrder['shipping_price']
        'status': $scope.editOrder['status']
        'buyer_name': $scope.editOrder['buyer_name']
        'buyer_email': $scope.editOrder['buyer_email']
        'shipping_name': $scope.editOrder['shipping_name']
        'shipping_tel': $scope.editOrder['shipping_tel']
        'shipping_zip': $scope.editOrder['shipping_zip']
        'shipping_pref': $scope.editOrder['shipping_pref']
        'shipping_address1': $scope.editOrder['shipping_address1']
        'shipping_address2': $scope.editOrder['shipping_address2']
        'delivery_company': $scope.editOrder['delivery_company']
        'slip_number': $scope.editOrder['slip_number']
      $scope.updating = true
      $http.put(url, data).then ((result) ->
        remake $scope.editOrder
        $scope.updating = false
        $scope.editFlg = false
        return
      ), (errorMessage) ->
        alert 'error'
        console.log errorMessage
        return
      return

    remake = (order) ->
      angular.forEach $scope.statuses, (orderStatus) ->
        if order['status'] == orderStatus['status_id']
          order['status_name'] = orderStatus['status_name']
        return
      angular.forEach $scope.deliveryCompanies, (deliveryCompany) ->
        if order['delivery_company'] == deliveryCompany['company_id']
          order['delivery_company_name'] = deliveryCompany['company_name']
        return
      return

    ###*
    # 注文削除
    # @param order
    ###

    $scope.delete = (order) ->
      `var url`
      if !confirm('本当に削除してよろしいですか？')
        return
      url = $scope.url + '/' + order['order_id']
      $http.delete(url).then ((result) ->
        $scope.editFlg = false
        $scope.hideEdit()
        $scope.reload()
        return
      ), (errorMessage) ->
        alert 'error'
        console.log errorMessage
        return
      return

    return
]
