app = angular.module('unwarehouse', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ui.bootstrap',
  'ngRoute',
  'zaikoModal'
  'GeeksCommon'
])

app.controller 'ContainerController', [
  'UnWarehouseService'
  class ContainerController
    constructor: (@UnWarehouseService)->
      @list = @UnWarehouseService.pickup()
      console.log(@list)
]

app.controller 'LargeContainerController', [
  'UnWarehouseService'
  class LargeContainerController
    constructor: (@UnWarehouseService)->
      @list = @UnWarehouseService.largePickup()
]

app.controller 'PickupController', [
  'UnWarehouseService'
  class PickupController
    constructor: (@UnWarehouseService)->
      @isSubmit = false
      @res = false
      @is_large = true
      @focus()
    pickup: (form)->
      if @isSubmit then return;
      if form.$valid
        @isSubmit = true
        @res = false
        @UnWarehouseService.doPickup({container_number: @containerNumber, is_large: @is_large}).$promise.then((d)=>
          @isSubmit = false
          @res = d
          @focus()
        ).catch((e)=>
          @isSubmit = false
          @res = {
            status: false
          }
          @focus()
        )
    focus: ()->
      $ = angular.element
      $('#containerNumber').val("")
      $('#containerNumber')[0].focus()
]

app.controller 'PickupColController', [
  'UnWarehouseService',
  '$timeout'
  class PickupColController extends PickupController
    constructor: (@UnWarehouseService, @$timeout)->
      path = window.location.href.split('/')
      @step = 0
      @list = @UnWarehouseService.pickupCol({col: path[path.length - 1]})
      @count = 0
      self = this
      @errorSound = new Audio("/sound/pickup_error.mp3")
      @list.$promise.then((value) ->
        for i, containers of value
          if Array.isArray(containers)
            for j, container of containers
              self.count++
      )
    nextStep: (step)->
      @step++
      @$timeout(=>
        @focus()
      )

    pickup: (form)->
      if @isSubmit then return;
      if form.$valid
        @isSubmit = true
        @res = false
        @UnWarehouseService.doPickup({container_number: @containerNumber, is_large: @is_large}).$promise.then((d)=>
          @isSubmit = false
          @res = d
          if (@res.status) then @count-- else @errorSound.play() #間違ったコンテナスキャンはエラー
          @focus()
        ).catch((e)=>
          @isSubmit = false
          @res = {
            status: false
          }
          @errorSound.play()
          @focus()
        )
    focus: ()->
      $ = angular.element
      $('#containerNumber').val("")
      $('#containerNumber')[0].focus()
]
