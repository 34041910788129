app = angular.module('reWarehouse', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ui.bootstrap',
  'ngRoute',
  'zaikoModal'
  'GeeksCommon'
])

app.controller 'ReWarehouseController', [
  'StockService'
  'NgTableParams'
  '$timeout'
  class ReWarehouseController
    constructor: (@StockService, @NgTableParams, @$timeout)->
      @waitWarehouseTable = @NgTableParams({
        count: 10
        filter:{
          stock_status_id: 7
        }
      },{
        counts: [500, 1000,2000,5000]
        getData: (params)=>
          @StockService.query(params.url()).$promise.then((d)=>
            params.total(d.total)
            d.data
          )
      })
      @targetStock = null
      @info_msg = null
    init:()->
      @targetStock = null
      @positionLabel = null
      @isbn = null
      @waitWarehouseTable.reload()
      @error_msg = null
    searchStock: (isbn)->
      @StockService.searchRewarehouseStock({code: isbn}).$promise.then((d)=>
        stock = d
        if Object.keys(d.toJSON()).length == 0
          @error_msg = "商品が見つかりませんでした。";
        else
          @targetStock = stock
          @focus("#position_id")
      );
    notFoundItem: ()->
      unless confirm("未発見処理します。良いですか？") then return
      if @isWarehoused then return
      @isWarehoused = true
      @StockService.notFound({stock_id: @targetStock.stock_id}).$promise.then((d)=>
        @isWarehoused = false
        @init()
      ).catch(()=>
        @isWarehoused = false
      )
      @focus("#isbn")
    reWarehouse: ()->
      if @isWarehoused then return
      @isWarehoused = true
      @targetStock.stock_status_id = 6
      @StockService.reWarehouse({stock_id: @targetStock.stock_id, stock_status_id: @targetStock.stock_status_id}, {label: @positionLabel}).$promise.then((d)=>
        @isWarehoused = false
        @info_msg = @targetStock.title+"を入庫しました。"
        @init()
      ).catch(()=>
        @error_msg = "ポジションが間違っています。"
        @isWarehoused = false
        @positionLabel = ""
        @focus('#position_id')
      )
      @focus("#isbn")
    focus: (id)->
      @$timeout(->angular.element(id).focus())
];
