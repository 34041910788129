app = angular.module('amazonFbaStock', [
  'angular-loading-bar'
  'ngTable'
  'ngEnter'
  'GeeksCommon',
  'tableSort'
])

app.controller 'AmazonFbaStockController', [
  '$scope'
  '$q'
  'NgTableParams'
  'WarehouseService'
  'CategoryService'
  'AmazonFbaStockService'
  'AmazonFbaStockGroupService'
  '$uibModal'
  'AmazonFbaStockShipmentService'
  'ConditionService'
  class AmazonFbaStockController
    constructor: (@$scope, @$q, @NgTableParams,
      @WarehouseService, @CategoryService,
      @AmazonFbaStockService, @AmazonFbaStockGroupService, @$uibModal, @AmazonFbaStockShipmentService, @ConditionService) ->
      @$q.all([
        @WarehouseService.query().$promise,
      ]).then (results) =>
        @warehouses = _.assign(results[0], {0: {warehouse_id: null, warehouse_name: "全て"}})

      @fetchAmazonConditions()

      @categories = {
        0: {category_id: [3, 5, 16], category_name: "メディア"}
        1: {category_id: [20, -1], category_name: "セット本"}
        2: {category_id: [18, 22, 23, 24, 25, 33], category_name: "ガジェット"}
        3: {category_id: [26, -1], category_name: "フィギュア"},
        4: {category_id: [21, -1], category_name: "ゲーム機本体"},
        5: {category_id: [32, -1], category_name: "その他"},
        6: {category_id: [34, -1], category_name: "DVDSet"},
      }
      @selectedCategory = [];

      @isNew = false
      @boxNum = 1
      @warehouseId = null
      @itemCategoryId = null
      @amazonFbaStockGroupId = -1
      @freefbaStockNote = "";
      @freeAmazonFbaStocks = new @NgTableParams({
        filter:
          amazon_fba_stock_status_id: 1
        sorting:
          amazon_fba_stock_id: 'desc'
        count: 200
        page: 1
      },
        getData: (params)=>
          @AmazonFbaStockService.query(params.url()).$promise.then((d)=>
            params.total(d.total)
            _.toArray(d.data)
          )
        counts: [50, 100, 200, 500]
      )
      @amazonFbaStockGroups = new @NgTableParams({
        sorting:
          amazon_fba_stock_group_id: 'desc'
      },
        getData: (params)=>
          @AmazonFbaStockGroupService.query(params.url()).$promise.then((d)=>
            params.total(d.total)
            d.data
          )
      )
      tmp = @
      @amazonFbaStocks = new class AmazonFbaStockClass
        reload: ()->
          @query(tmp.amazonFbaStockGroupId)
        query: (id)->
          tmp.AmazonFbaStockGroupService.get({amazon_fba_stock_group_id: id}).$promise.then((d)=>
            tmp.amazonFbaStockGroupId = id
            @data = d
          )
        data: {}
      @amazonFbaShipment = new class AmazonFbaShipmentClass extends AmazonFbaStockClass
        query: (id)->
          tmp.AmazonFbaStockShipmentService.query({id: id}).$promise.then((d)=>
            tmp.amazonFbaStockGroupId = id
            @data = d
          ).catch((d) =>
            tmp.amazonFbaStockGroupId = -1
          )

    changeFreeAmazonFbaStockParam: ()->
      categoryIds = (_(@selectedCategory).map((e, i)=> if !!e then @categories[i]?.category_id else false).filter((e)=>e).flatten().uniq().value())
      @freeAmazonFbaStocks.parameters({'category_id': if categoryIds.length == 0 then undefined else categoryIds})
      @freeAmazonFbaStocks.parameters({'warehouse_id': if @warehouseId == null then undefined else @warehouseId})
    checkAllFreeStocks: (flg)->
      @freeAmazonFbaStocks.data = _.map(@freeAmazonFbaStocks.data, (row)=>row.to_fba = flg; row)
    checkSelectCountFreeStocks: (cnt = 24)-> #件数を指定してFBAの新規納品対象にチェックを入れる
      angular.element('table#freeAmazonFbaStockTable tr > td:nth-child(2) input[type=checkbox]:not(:checked)').slice(0, cnt).click()
    createFbaGroup: (is_new = false)->
      amazonFbaStockIds = _.map(_.filter(@freeAmazonFbaStocks.data, (row)=> row.to_fba == true), (row)=> row.amazon_fba_stock_id)
      @AmazonFbaStockGroupService.save({
        amazon_fba_stock_id: amazonFbaStockIds,
        note: @freefbaStockNote,
        is_new: !!is_new
      }).$promise.then((d)=>
        if d.error
          alert(d.msg)
        @freeAmazonFbaStocks.reload()
        @amazonFbaStockGroups.reload()
        @freefbaStockNote = ""
      ).catch((d)=>
        if d.data.error
          alert(d.data.msg)
      )
    viewAmazonFbaStockGroup: (id, group)->
      @isNew = group.is_new
      @amazonFbaStockGroupId = 0
      if @isNew
        @amazonFbaShipment.query(id)
      else
        @amazonFbaStocks.query(id)

    pickup: (id)->
      @AmazonFbaStockGroupService.pickup({
        amazon_fba_stock_group_id: id
      }).$promise.then((d)=>
        @amazonFbaStocks.reload()
        @amazonFbaStockGroups.reload()
      )
    donePick: (id)->
      unless confirm("ピック完了にします？") then return
      @amazonFbaStockGroupId = id
      @AmazonFbaStockGroupService.done_pick({
        amazon_fba_stock_group_id: id
      }).$promise.then((d)=>
        @amazonFbaStocks.reload()
        @amazonFbaStockGroups.reload()
      )

# Amazonコンディションの取得
    fetchAmazonConditions: () ->
      @ConditionService.amazonConditions().$promise.then((d) =>
        @amazonConditions = d
      )

# 状態違い
    wrongState: (amazonFbaStock, index) ->
      unless confirm("データがリストから消えます。更新後は状態違いポジションへ移動します") then return
      @AmazonFbaStockService.wrongState({
        amazon_fba_stock_id: amazonFbaStock.amazon_fba_stock_id,
        condition_id: amazonFbaStock.amazon_stock.condition_id,
      }).$promise.then((d) =>
        $('#wrongStateModal' + index).modal('hide')
        @amazonFbaShipment.reload()
        @amazonFbaStocks.reload()
        @amazonFbaStockGroups.reload()
      )

# 未発見
    notFound: (amazonFbaStock, message = "未発見処理します。", isReSatei = false)->
      if amazonFbaStock.amazon_stock.is_set
        modal = @$uibModal.open(
          templateUrl: '/html/fbaStock/notFound.html'
          controller: 'AmazonFbaStockNotFoundController'
          controllerAs: 'c'
          backdrop: true
          bindToController: true
          resolve:
            amazon_fba_stock_id: ()->
              amazonFbaStock.amazon_fba_stock_id
        )
        modal.result.then(=>
          @amazonFbaShipment.reload()
          @amazonFbaStocks.reload()
          @amazonFbaStockGroups.reload()
        )
      else
#セットでない場合。
        unless confirm(message) then return
        @AmazonFbaStockService.notFound({
          amazon_fba_stock_id: amazonFbaStock.amazon_fba_stock_id,
          is_re_satei: isReSatei
        }).$promise.then((d)=>
          @amazonFbaShipment.reload()
          @amazonFbaStocks.reload()
          @amazonFbaStockGroups.reload()
        )
    _donePick: (shipment)->
      if !confirm("ピックを完了します、よろしいですか？") then return;
      if shipment.is_update then return else shipment.is_update = true
      shipment.amazon_fba_stock_shipment_status_id = 2;
      @AmazonFbaStockShipmentService.update(shipment).$promise.then((d)=>
        if d.error then alert(d.msg)
        @amazonFbaShipment.reload()
      ).catch((d)=>
        alert(d.data.message)
        @amazonFbaShipment.reload()
      )
    _doneShipment: (shipment)->
      if !confirm("出荷完了します、よろしいですか？") then return;
      if shipment.is_update then return else shipment.is_update = true
      shipment.amazon_fba_stock_shipment_status_id = 3;
      @AmazonFbaStockShipmentService.update(shipment).$promise.then((d)=>
        if d.error then alert(d.msg)
        @amazonFbaShipment.reload()
      ).catch((d)=>
        alert(d.data.message)
        @amazonFbaShipment.reload()
      )
    _deleteTrackingId: (shipment, index)->
      delete shipment.amazon_fba_stock_shipment_tracking_ids[index]
      shipment.amazon_fba_stock_shipment_tracking_ids = _.filter(_.toArray(shipment.amazon_fba_stock_shipment_tracking_ids), (e)=> e)
      console.log(shipment)
    _addTrackingId: (shipment)->
      shipment.amazon_fba_stock_shipment_tracking_ids.push({
        id: "",
        tracking_id: ""
      })
    _update: (shipment)->
      if shipment.is_update then return else shipment.is_update = true
      @AmazonFbaStockShipmentService.update(shipment).$promise.then((d)=>
        if d.error then alert(d.msg)
        @amazonFbaShipment.reload()
      ).catch((d)=>
        alert(d.data.message)
        @amazonFbaShipment.reload()
      )
    _saveTrackingIds: (shipment)->
      if shipment.is_update then return else shipment.is_update = true
      idList = _.map(shipment.amazon_fba_stock_shipment_tracking_ids, (e)=> e.tracking_id)
      @AmazonFbaStockShipmentService.updateTrackingIds({id: shipment.id, tracking_id: idList}).$promise.then((d)=>
        @amazonFbaShipment.reload()
      )
]

app.controller 'AmazonFbaStockNotFoundController', [
  '$uibModalInstance'
  'amazon_fba_stock_id'
  'AmazonFbaStockService'
  class AmazonFbaStockNotFoundController
    constructor: (@$uibModalInstance, @amazon_fba_stock_id, @AmazonFbaStockService)->
      @AmazonFbaStockService.get({amazon_fba_stock_id: @amazon_fba_stock_id}).$promise.then((d)=>
        @fbaStock = d
        @stocks = d.set_stocks[0].stocks
      )
    runNotFound: ()->
      stock_ids = _.map(_.filter(@stocks, (row)->row.is_not_found), (row)->row.stock_id)

      unless stock_ids.length > 0
        alert("対象が選択されていません")
        return
      unless confirm(stock_ids.length + "件 未発見処理します。") then return
      if @send then return
      @send = true

      amazon_fba_stock_id = @fbaStock.amazon_fba_stock_id
      @AmazonFbaStockService.notFound({amazon_fba_stock_id: amazon_fba_stock_id}, {stock_id: stock_ids}).$promise.then((d)=>
        @$uibModalInstance.close()
        if d.is_found
          alert "代替商品が見つかりました。Pickリストの再取得をおこなってください。\n\n" + d.found_position?.map((e) -> '・' + e.title + '(' + e.position + ")").join("\n")
        else
          alert "代替商品が見つからなかったので、このセットは再入庫してください。"
      )
]
