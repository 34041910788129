app = angular.module('mergeWarehouseContainer', [
  'angular-loading-bar'
  'ngAnimate'
  'ui.bootstrap',
  'GeeksCommon',
  'ngAlertify',
  'ngEnter'
])


app.controller 'MergeWarehousingController', [
  'StockService',
  'UnWarehouseService'
  '$timeout'
  class MergeWarehousingController
    constructor: (@StockService, @UnWarehouseService, @$timeout)->
      @reset()
    reset: ()->
      @container_number = ""
      @item_count = 0
      @container = null
      @to_container_number = ""
      @isSubmit = false

    searchContainer: (container_number)->
      p = @UnWarehouseService.query({container_number: container_number}).$promise
      p.then((d)=>
        @item_count = _.size(d.estimate_items)
        @container = d
        #入庫する商品があるならfocusさせる
        if @item_count > 0
          @focus("#w_2")
      )
    merge: ()->
      if @isSubmit then return
      @isSubmit = true
      @StockService.mergeContainer({
        base_container: @container_number,
        to_container: @to_container_number
      }).$promise.then((d)=>
        @msg = ""
        @error_msg = ""
        if d.error
          if d.msg?
            @error_msg = d.msg
          else
            @error_msg = "何らかのエラーが発生しました。"
        else
          @reset()
          @msg = "処理完了しました。"
      )
    focus:(id)->
      $ = angular.element
      @$timeout(->
        $(id).focus()
      )

]