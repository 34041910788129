app = angular.module('stockJokyaku', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ui.bootstrap',
  'GeeksCommon'
])

app.controller 'stockJokyakuController', [
  'StockService'
  class stockJokyakuController
    constructor: (@StockService)->
      @stockIdText = ""
      @msg = ""
      @runMsg = ""
    search: ()->
      @idList = @stockIdText.replace(/\n/g, ",").split(",")
      if @idList.length > 0
        @StockService.bulkJokyaku({"estimate_item_id[]": @idList}).$promise.then((d)=>
          @msg = d.msg;
          @runMsg = ""
        )
    doJokyaku: () ->
      if @idList.length > 0
        @StockService.bulkJokyaku({"estimate_item_id[]": @idList, run: 1}).$promise.then((d)=>
          @runMsg = d.msg;
        )
    reset: () ->
      @idList = []
      @stockIdText = ""
      @msg = ""
      @runMsg = ""
]
