app = angular.module('YahooAuctionShipList', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'GeeksCommon'
])

app.controller 'YahooAuctionShipListController', [
  '$rootScope'
  '$scope'
  '$http'
  '$location'
  '$uibModal'
  'NgTableParams'
  '$timeout'
  'YahooAuctionOrderService'
  'YahooAuctionShippingSlipService'
  'YahooAuctionShippingSlipGroupService'
  ($rootScope, $scope, $http, $location, $uibModal, NgTableParams, $timeout,
    YahooAuctionOrderService,
    YahooAuctionShippingSlipService,
    YahooAuctionShippingSlipGroupService) =>
    $scope.isTableLoding = []
    $scope.viewShippingSlipGroupId = null
    $scope.noGroupShipTableItemCount = 0
    $scope.init = ->
      $rootScope.$on("$locationChangeSuccess", (event, next, current) ->
        $scope.tabStatus = switch $location.url()
          when "#pick" then "pick"
          when "#ship_contact" then "ship_contact"
          else
            "pick"
      );

    $scope.checkDeliveryPrintAll = (f, table)->
      switch f
        when "check"
          _.each(table, (row)->
# noGroupShipTableの場合
            unless row.shipments?
              row.is_checked = true
# shippingSlipDetailTableの場合
            else if row.shipments[0].slip_number isnt null and row.shipments[0].yahoo_auction_order_item_stocks[0]?.yahoo_auction_order_item_stock_status_id <= 3
              row.is_checked = true
            return
          )
        when "uncheck"
          _.each(table, (row)->
            row.is_checked = false
            return
          )

    $scope.tableReload = ->
      $scope.loading = true
      defers = []
      defers.push($scope.shippingSlipGroupTable.reload())
      defers.push($scope.shippingSlipDetailTable.reload())
      Promise.all(defers).then(->
        $scope.loading = false
      )

    createAuctionTable = (status = 0, func = null) ->
      new NgTableParams({
        page: 1
        count: 1000
        sorting:
          'yahoo_auction_order_id': 'desc'
        filter:
          yahoo_auction_order_status_id: status
      },
        counts: [1000, 2000, 5000, 10000],
        getData: (params) ->
          $scope.isTableLoding[status] = true
          YahooAuctionOrderService.paginate(params.url()).$promise.then((data)->
            $scope.isTableLoding[status] = false
            params.total(data.total)
            if func?
              func(data.data)
            else
              (data.data)
          )
      )

    $scope.noGroupShipTable = createAuctionTable(4, (orders) ->
      $scope.noGroupShipTableItemCount = _.sum(orders.map((order)-> _.sum(order.yahoo_auction_order_items.map((item)->item.quantity)))) # 商品の個数を数える
      orders
    )

    $scope.shippingSlipGroupTable = new NgTableParams(
      {
        page: 1
        count: 10
        filter:
          type: 'YAHOO'
        sorting: {shipping_slip_group_id: 'desc'}
      },
      getData: (params) ->
        YahooAuctionShippingSlipService.query(params.url()).$promise.then((d)->
          params.total(d.total)
          d.data
        )
    )

    $scope.shippingSlipDetailTable = new NgTableParams(
      {
        page: 1
        count: 1000
      },
      counts: [1000, 2000, 5000, 10000],
      getData: (params)->
        if($scope.viewShippingSlipGroupId > 0)
          YahooAuctionShippingSlipService.yahooAuctionOrders({
            id: $scope.viewShippingSlipGroupId
          }).$promise.then((d)->
            d
          ).catch((d)=>
            $scope.viewShippingSlipGroupId = null
          )
    )

    $scope.viewShippingSlipDetail = (group_id)->
      $scope.viewShippingSlipGroupId = group_id
      $scope.shippingSlipDetailTable.reload()

    $scope.doneShipment = (yahoo_auction_order_id, isReload = true)->
      p = YahooAuctionOrderService.done({id: yahoo_auction_order_id}).$promise
      p.then((d)->
        if isReload then $scope.tableReload()
      ).catch($scope.catchError(['doneShipment', 'YahooAuctionOrderService.done']))
      return p

    # 納品書グループ作成
    $scope.createShippingSlipGroup = ->
      datas = _.filter($scope.noGroupShipTable.data, (order)->
        order.is_checked
      )
      unless datas?.length > 0 then return
      ids = _.map(datas, (order)->
        order.yahoo_auction_order_id
      )
      YahooAuctionShippingSlipGroupService.createYahooAuctionSlip({ids: ids}).$promise.then((data)->
        $scope.tableReload()
        $scope.noGroupShipTable.reload()
      ).catch($scope.catchError(['createShippingSlipGroup',
        'YahooAuctionShippingSlipGroupService.createYahooAuctionSlip', ids]))
      return

    # ヤフオクかんたん決済入金金額が一致しているかどうか
    $scope.matchYahooAuctionPaymentPrice = (yahooAuctionOrder) ->
      price = yahooAuctionOrder.price + yahooAuctionOrder.tax + yahooAuctionOrder.shipping_price
      if price == yahooAuctionOrder.total_yahoo_auction_payment_price then return true else return false

    $scope.saveYOrder = (yahooAuctionOrder)->
      YahooAuctionOrderService.save({id: yahooAuctionOrder.yahoo_auction_order_id}, yahooAuctionOrder).$promise.then((d)->
        yahooAuctionOrder.updated_at = d.updated_at
        yahooAuctionOrder.is_saved = true
        $timeout(->
          yahooAuctionOrder.is_saved = false
        , 2000)
        _.each(Object.keys(yahooAuctionOrder), (key)=>if d[key]? then yahooAuctionOrder[key] = d[key])
      ).catch((error) ->
        if(error.data && error.data.error == 'ModelNotFoundException')
          alert("該当レコードは既に他のユーザによって更新されています。")
          $scope.noGroupShipTable.reload() # エラー時はデータリロード
          $scope.shippingSlipDetailTable.reload()
        else
          defaultErrorHandler = $scope.catchError(['saveYOrder'])
          defaultErrorHandler(error)
        yahooAuctionOrder.is_failed = true
        $timeout((->
          yahooAuctionOrder.is_failed = false
        ), 2000)
      )

    # ステータス変更
    $scope.moveOrderStatusId = (yahooAuctionOrder, status_id)->
      unless confirm "変更します。よろしいですか？" then return

      nowStatus = yahooAuctionOrder.yahoo_auction_order_status_id
      yahooAuctionOrder.yahoo_auction_order_status_id = status_id
      YahooAuctionOrderService.save({id: yahooAuctionOrder.yahoo_auction_order_id}, yahooAuctionOrder).$promise.then((updatedYahooAuctionOrder) ->
        yahooAuctionOrder.updated_at = updatedYahooAuctionOrder.updated_at
        $scope.noGroupShipTable.reload()
      ).catch((error) ->
        if(error.data && error.data.error == 'ModelNotFoundException')
          alert("該当レコードは既に他のユーザによって更新されています。")
        else
          defaultErrorHandler = $scope.catchError(['moveOrderStatusId'])
          defaultErrorHandler(error)
        $scope.noGroupShipTable.reload()
      )

    $scope.moveWaitCancel = (yahooAuctionOrder)=>
      unless confirm "キャンセル待ちに移動します。よろしいですか？" then return
      YahooAuctionOrderService.waitCancel(yahooAuctionOrder).$promise.then((d)=>
        $scope.tableReload()
      ).catch($scope.catchError(['moveWaitCancel']))

    $scope.returnWaitShipping = (yahooAuctionOrder)=>
      unless confirm "出荷処理待ちに移動します。よろしいですか？" then return
      YahooAuctionOrderService.returnWaitShipping(yahooAuctionOrder).$promise.then((d)=>
        yahooAuctionOrder.updated_at = d.updated_at
        $scope.tableReload()
        $scope.noGroupShipTable.reload()
      ).catch((error) ->
        if(error.data && error.data.error == 'ModelNotFoundException')
          alert("該当レコードは既に他のユーザによって更新されています。")
          $scope.noGroupShipTable.reload() # エラー時はデータリロード
          $scope.shippingSlipDetailTable.reload()
        else
          defaultErrorHandler = $scope.catchError(['returnWaitShipping'])
          defaultErrorHandler(error)
      )

    # 最終連絡日を取得
    # contactDate or MailLogから
    $scope.lastContactDate = (yahooAuctionOrder)->
      lastLog = _.last(yahooAuctionOrder.yahoo_order_mail_logs)
      date = null
      if lastLog?
        date = lastLog.created_at
      else
        items = _.filter(yahooAuctionOrder.yahoo_auction_order_items, (item)-> item.contact_date isnt null)
        item = _.first(items)
        date = item.contact_date if item?
      date

    # 最後に送った連絡日を指定のフォーマットで返す
    $scope.lastSendMailDate = (yahooAuctionOrder)->
      date = $scope.lastContactDate(yahooAuctionOrder)
      if date isnt null
        moment(date).format("YYYY-MM-DD H:mm:ss")
      else
        null

    # 落札日を返す
    # APIの実装の都合上, end_timeがnullの場合はyahoo_auction_order_itemsから取り出す必要がある
    $scope.showEndTime = (yahooOrder) ->
      if yahooOrder.end_time is not null
        endTime = yahooOrder.end_time
      else
        endTime = yahooOrder.yahoo_auction_order_items.filter((v) ->
          return v.order_form_id is yahooOrder.order_form_id)[0].end_time
      return moment(endTime).format('YYYY-MM-DD H:mm:ss')

    $scope.viewPrintDate = (date)->
      moment(date).format('MM月DD日 HH時mm分')

    $scope.getCheckedYahooAuctionOrder = (yahooAuctionOrders)=>
      datas = _.filter(yahooAuctionOrders, (y)->
        y.is_checked
      )
      unless datas?.length > 0 then return
      datas

    $scope.syncYahooOrder = (yahooAuctionOrder) ->
      YahooAuctionOrderService.syncYahooAuctionOrder({id: yahooAuctionOrder.yahoo_auction_order_id}, yahooAuctionOrder).$promise.then(->
        $scope.tableReload()
      ).catch($scope.catchError(['syncYahooOrder']))

    $scope.shipmentOption = (yahooAuctionOrder)->
      modal = $uibModal.open(
        templateUrl: '/html/angular/shipment_option_ng.html',
        controller: 'YahooAuctionShipmentOptionController',
        scope: $scope
        resolve: {
          yahooAuctionOrder: -> yahooAuctionOrder
        }
      )
      modal.result.then((d)=>
        if d?
          yahooAuctionOrder.yahoo_auction_shipment_option = d.yahooAuctionShipmentOption
        else
          yahooAuctionOrder.yahoo_auction_shipment_option = null
      )

    # メール送信用モーダル
    $scope.sendMail = (yahooAuctionOrders) ->
      if !confirm('出荷通知を送ります') then return
      modal = $uibModal.open(
        templateUrl: '/html/angular/mail_ng.html',
        controller: 'YahooSendMailController',
        scope: $scope
        size: 'lg'
        resolve: {
          OrderIds: -> _.map(yahooAuctionOrders, (y)-> y.order.order_id)
          templateId: -> 5
          statusId: -> null,
          type: -> 'order_id'
          mapFunc: -> (d)-> _.map(d, (r) -> Object.assign({category_id: 51}, r))
        }
      )
      modal.result.then(->
        Promise.all(
          _.map(_.chunk(yahooAuctionOrders, 10), (chunk, index)->
            new Promise((resolve, reject)->
              setTimeout(()->
                Promise.all(
                  _.map(chunk, (order) -> $scope.doneShipment(order.yahoo_auction_order_id, false))
                ).then(()-> resolve()).catch(()-> reject())
              , index * 1000)
            ))
        ).then(()-> $scope.tableReload())
      )

    $scope.openCustomerMessage = (YahooAuctionOrder)->
      text = """
領収書：\t#{if YahooAuctionOrder.yahoo_is_need_receipt then "領収書希望" else ""}
お客様要望：\t#{YahooAuctionOrder.yahoo_buyer_comments}

お届け希望日：\t#{YahooAuctionOrder.yahoo_ship_request_date}
お届け希望時間帯：\t#{YahooAuctionOrder.yahoo_ship_request_time}
"""
      modal = $uibModal.open(
        templateUrl: '/html/angular/yahoo_auction_customer_message_ng.html',
        controller: 'YahooAuctionCustomerMessageController',
        controllerAs: "c",
        resolve: {
          text: -> text
        }
      )
    $scope.catchError = (traces = [])->
      traces.unshift('YahooAuctionShipListController')
      (error) ->
        traces.push(error.config.url)
        alert("エラーが発生しました。以下の内容をコピーして管理者へ送ってください。\nMessage: \n" + traces.map(JSON.stringify).join("-> \n"))

    return
]
