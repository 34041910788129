app = angular.module('warehouse', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ngEnter'
  'GeeksCommon'
  'ngAlertify'
])

app.controller 'WarehousingController', [
  'UnWarehouseService', 'ConditionService', '$timeout', 'EstimateCommentService', 'alertify'
  class WarehousingController
    constructor: (@UnWarehouseService, @ConditionService, @$timeout, @EstimateCommentService, @alertify)->
      window.addEventListener('beforeunload', (e)=>
        unless @container == null
          txt = "入庫が完了している場合は入庫完了を押してください。"
          e.returnValue = txt
          return txt
        return e
      )
      @ConditionService.query().$promise.then((d)=> @conditions = d)
      @EstimateCommentService.query({category:"warehousing", type: "amazon"}).$promise.then((d)=>
        @commentDataList = d
      )
      @item_count = 0
      @container = null
      @warehouseItem = null
    init: ()->
      @item_count = 0
      @container = null
      @warehouseItem = null
      @container_number = null
      @focus("#containerNumber")

    searchContainer: (container_number)->
#      unless @container == null
#        unless @container.container_number == container_number
#          alert("入庫が完了している場合は入庫完了を押してください。")
#          return
      @warehouseItem = null
      p = @UnWarehouseService.query({container_number: container_number}).$promise
      p.then((d)=>
        @item_count = _.size(d.estimate_items)
        @container = d
        #入庫する商品があるならfocusさせる
        if @item_count > 0
          @focus("#w_i")
      )
      p
    warehousing: (item)->
      if @warehouseItem?.stock?
        delete @warehouseItem.stock.is_warehousing
        @warehouseItem.stock.position_id = @warehouseItem.stock.old_position_id
        delete @warehouseItem.stock.old_position_id
      @warehouseItem = null
      @msg = ""
      stock = item.stock
      stock.is_warehousing = true
      stock.old_position_id = stock.position_id
      stock.position_id = null
      stock.position?.label = ""

      #コメントデータマッピング
      item.estimate_item_comment_id = @commentDataList[item.stock.category_id].condition_table[item.condition_id]
      @warehouseItem = item
      @focus("#w_p")
    searchForCode: (code)->
      item = _.find(@container.estimate_items, (item)=>
        item.code == code || item.code == code.toIsbn10()
      )
      if item?
        @warehousing(item)
      else
        @alertify.logPosition("bottom right")
        @alertify.error("商品が見つかりませんでした。")
        @warehouseItem = null

    saveStock: ()->
      item = @warehouseItem
      stock = item.stock
      unless stock.is_warehousing  then return
      @warehouseItem = null
      item.stock.stock_status_id = 2
      @container.estimate_items = _.sortBy(@container.estimate_items, (item)=>item.stock.stock_status_id)
      @focus("#w_i")
      @UnWarehouseService.save(stock).$promise.then((d)=>
        item.stock = d.stock
      ).catch((d)=>
        item.stock.stock_status_id = 1
        item.stock.is_warehousing = false
        item.stock.position_label = null

        @msg = "ポジションが正しくありません。もし何度もエラーが出る場合は管理者までご連絡ください。"
      )
    btnHarita: ()->
      if @item_count > 0
        @container.estimate_items = _.map(@container.estimate_items, (item)=>
          if item.stock.stock_status_id == 1
            stock = item.stock
            stock.stock_status_id = 2
            stock.position_label = "テスト122"
            @UnWarehouseService.save(stock).$promise.then((d)=>
              d = d.toJSON()
              item.stock = d.stock
            )
          return item
        )


      @focus("#w_i")
    changeComment: (estimate_item_comment_id)->
      commentData = @commentDataList[@warehouseItem.stock.category_id]
      @warehouseItem.stock.export_comment = commentData.condition[estimate_item_comment_id].export_comment
      @warehouseItem.stock.condition_id = commentData.condition[estimate_item_comment_id].condition_id
    disableItem: ()->
      if confirm("出品不可処理を行います")
        stock = @warehouseItem.stock
        @UnWarehouseService.disable(stock).$promise.then((d)=>
          @searchContainer(@container_number)
        )
    endWarehouse: ()->
      f = true
      @searchContainer(@container_number).then((d)=>
        if _.filter(@container.estimate_items, (item)-> item.stock?.stock_status_id == 1).length > 0
          f = confirm("未入庫商品が入っていますが、入庫してもよろしいですか？")
        if f
          @UnWarehouseService.endWarehouse({estimate_container_id: @container.estimate_container_id}).$promise.then((d)=>
            @init()
          )
      )
    focus:(id)->
      $ = angular.element
      @$timeout(->
        $(id).focus()
      )

]

app.directive 'nextFocusElement', ->
  {
    restrict: 'A'
    link: (scope, element, attrs, modelCtrl)->
      element.bind('keydown', (e)->
        if(e.which is 13)
          scope.$apply(->
            $("\##{attrs.nextFocusElement}").focus()
          )
      )
  }
