app = angular.module('yahooAuctionTanaoroshiSuccess', [
  'angular-loading-bar'
  'ngTable'
  'ngEnter'
  'GeeksCommon',
  'tableSort'
])

app.controller 'YahooAuctionTanaoroshiSuccessController', [
  '$scope'
  '$q'
  'NgTableParams'
  '$uibModal'
  'YahooAuctionTanaoroshiService'
  class YahooAuctionTanaoroshiSuccessController
    constructor: (@$scope, @$q, @NgTableParams, @$uibModal, @YahooAuctionTanaoroshiService) ->
      @is_all = 0
      @tanaoroshi_stock_id = 0;
      @tanaoroshiTable = new @NgTableParams({}, {
        getData: (params)=>
          paramsUrl = params.url()
          paramsUrl['is_all'] = @is_all
          @YahooAuctionTanaoroshiService.getTanaoroshiList(paramsUrl).$promise.then((d)=>
            d
          )
      })
      @stockTable = new @NgTableParams({}, {
        getData: (params)=>
          if @tanaoroshi_stock_id
            return @YahooAuctionTanaoroshiService.getTanaoroshiStockList({id: @tanaoroshi_stock_id}).$promise.then((d)=>
              d
            )

          return []
      })

    showAll: () ->
      @is_all = 1
      @tanaoroshiTable.reload()

    showNotComplete: () ->
      @is_all = 0
      @tanaoroshiTable.reload()

    start: (tanaoroshi) ->
      @tanaoroshi_stock_id = tanaoroshi.id;
      @stockTable.reload()

    complete: () ->
      if @tanaoroshi_stock_id && confirm("ヤフオク処理完了にしてもよろしいですか?")
        @YahooAuctionTanaoroshiService.complete({id: @tanaoroshi_stock_id}).$promise.then((d)=>
          alert("処理完了にしました")
          @tanaoroshi_stock_id = 0
          @tanaoroshiTable.reload()
          @stockTable.reload()
        )
]
