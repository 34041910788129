app = angular.module('YahooAuctionExportSmart', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ngFileUpload'
  'ui.bootstrap'
  'nl2br'
  'ngSanitize'
  'ngResource'
  'GeeksCommon'
  'yahooAuctionExport'
])

app.controller 'yahooAuctionExportSmartIndexController', [
  'YahooAuctionStockService'
  'StockService'
  'ConditionService'
  'PositionService'
  'AuctionService'
  '$uibModal'
  '$scope'
  '$q'
  'Upload'
  'YahooAuctionShippingCostTable'
  class yahooAuctionExportSmartIndexController
    yahoo_auction_stock_id: null
    estimate_item_id: null
    yahoo_auction_stock: null
    constructor: (@YahooAuctionStockService, @StockService, @ConditionService, @PositionService, @AuctionService, @$uibModal, @$scope, @$q, @Upload, @YahooAuctionShippingCostTable)->
      @ConditionService.query().$promise.then((d)=>
        delete d[1005]
        @conditions = d
      )
      @yahooAuctionShippingCostTable = @YahooAuctionShippingCostTable


    reset: ()->
      @yahoo_auction_stock_id = null
      @estimate_item_id = null
      @yahoo_auction_stock = null
      window.scroll({top: 0, behavior: 'smooth'})
      setTimeout(-> document.getElementById('estimate_item_id').focus())


    searchAuctionStock: ()=>
      unless @yahoo_auction_stock_id > 0 or @estimate_item_id > 0 then return
      if @yahoo_auction_stock_id > 0
        @YahooAuctionStockService.get({yahoo_auction_stock_id: @yahoo_auction_stock_id}).$promise.then((d)=>
          if d.yahoo_auction_stock_id > 0
            if d.shipping_price == 0
              d.is_doukon = null
              d.shipping_price = null
            @yahoo_auction_stock = d
        )
      else
        @YahooAuctionStockService.query({
          "filter[estimate_item_id]": @estimate_item_id
        }).$promise.then((d)=>
          if d.total == 1
            @yahoo_auction_stock_id = d.data[0].yahoo_auction_stock_id
            @YahooAuctionStockService.get({yahoo_auction_stock_id: @yahoo_auction_stock_id}).$promise.then((d)=>
              if d.yahoo_auction_stock_id > 0
                if d.shipping_price == 0
                  d.is_doukon = null
                  d.shipping_price = null
                @yahoo_auction_stock = d
            )
        )

    # ヤフオクカテゴリ選択
    selectCategory: ()->
      modalInstance = @$uibModal.open(
        templateUrl: '/html/angular/auction_ng.html'
        controller: 'ModalInstanceController'
        size: 'lg'
        resolve: {
          SuggestKeyword:  => @yahoo_auction_stock.title
        }
      )
      modalInstance.result.then (yahoo) =>
        @yahoo_auction_stock.yahoo_auction_category ?= {}
        @yahoo_auction_stock.yahoo_category_id = yahoo.yahoo_category_id
        @yahoo_auction_stock.yahoo_auction_category.category = yahoo.category_name
        @yahoo_auction_stock.all_yahoo_category_name = yahoo.all_category_name
        return
    # 商品説明HTMLの表示
    showTemplate: (yahooAuctionStockId) =>
      @YahooAuctionStockService.showTemplate({yahoo_auction_stock_id: yahooAuctionStockId}).$promise.then((d)=>
        templateView = d.template
        templateView = '<div class="modal-content" style="width: 790px;margin-left: -95px;">' + templateView
        templateView = templateView + '</div>'
        @$uibModal.open template: templateView
      )

    # 送料の設定
    setShippingPrice: (shippingCost) ->
      @yahoo_auction_stock.shipping_price = shippingCost.price;

    # 同梱の自動設定
    setIsDoukon: (shippingCost) ->
      if shippingCost.name is '同梱可'
        @yahoo_auction_stock.is_doukon = 1
      else
        @yahoo_auction_stock.is_doukon = 0

    # 即決価格の指定
    setBuyoutPrice: (shippingCost)->
      if @yahoo_auction_stock.buyout_price > 0 || @yahoo_auction_stock.is_buyout_price
        @yahoo_auction_stock.buyout_price = shippingCost.buyoutPriceFn(@yahoo_auction_stock.price);

    # 送料と同梱が一致しているか
    isValidIsDoukon: () ->
      if (!@yahoo_auction_stock)
        return false

      if (@yahoo_auction_stock.shipping_price is 864 and @yahoo_auction_stock.is_doukon is 1) or
         (@yahoo_auction_stock.shipping_price isnt 864 and @yahoo_auction_stock.is_doukon is 0)
        return true
      return false

    deleteImage: (auctionStock, filename)->
      @AuctionService.deleteImage(auctionStock, filename, (()=>
#start_func
        @updating = true
      ),
        (d)=>
          @yahoo_auction_stock['yahoo_auction_stock_images'] = d
          @updating = false
      )
      return

    moveUpImage: (auctionStock, index)->
      if auctionStock.yahoo_auction_stock_images? && index > 0
        tmp = auctionStock.yahoo_auction_stock_images[index-1]
        target = auctionStock.yahoo_auction_stock_images[index]
        tmp_id = tmp.yahoo_auction_stock_image_id
        tmp.yahoo_auction_stock_image_id = target.yahoo_auction_stock_image_id
        target.yahoo_auction_stock_image_id = tmp_id
        auctionStock.yahoo_auction_stock_images[index-1] = target
        auctionStock.yahoo_auction_stock_images[index] = tmp

    moveDownImage: (auctionStock, index)->
      max = auctionStock.yahoo_auction_stock_images?.length
      if auctionStock.yahoo_auction_stock_images? && index < max-1
        tmp = auctionStock.yahoo_auction_stock_images[index+1]
        target = auctionStock.yahoo_auction_stock_images[index]
        tmp_id = tmp.yahoo_auction_stock_image_id
        tmp.yahoo_auction_stock_image_id = target.yahoo_auction_stock_image_id
        target.yahoo_auction_stock_image_id = tmp_id
        auctionStock.yahoo_auction_stock_images[index+1] = target
        auctionStock.yahoo_auction_stock_images[index] = tmp

    unUploadFiles: ->
      @imagePreviewList = []
      images = @yahoo_auction_stock['image_files']
      if images?.length > 0 && !(@updating)
        imageExtensionValidation = true
        angular.forEach images, (imageFile) ->
          if imageFile.type != 'image/jpeg' and imageFile.type != 'image/gif'
            imageExtensionValidation = false
          return
        if !imageExtensionValidation
          @updating = false
          alert '画像の拡張子はjpegかgifのみです'
          @yahoo_auction_stock['image_files'] = null
          return
        if @yahoo_auction_stock['yahoo_auction_stock_images'].length + images.length > 10
          @updating = false
          alert "画像は11枚以上登録できません"
          @yahoo_auction_stock['image_files'] = null
          return

        @updating = true
        @Upload.upload(
          url: '/api/v1/yahoo-auction-stocks/upload-images'
          fields:
            stock_id: @yahoo_auction_stock['stock_id']
          fileFormDataName: @yahoo_auction_stock['image_files'].map((image, key) ->
            'images[' + key + ']'
          )
          file: @yahoo_auction_stock['image_files']).progress((evt) ->
        ).success ((data, status, headers, config) =>
#fileフォームをリセット
          @yahoo_auction_stock['image_files'] = null
          angular.forEach angular.element('input[type=\'file\']'), (inputElem) ->
            angular.element(inputElem).val null
            return
          @yahoo_auction_stock['yahoo_auction_stock_images'] = data
          @updating = false
          return
        )

# 商品データ保存 + 画像の保存
    updateExportData: ->
      if @$scope.yahooAuctionStockForm.$valid == false
        return
      @updating = true

      #保存する前にPosition検証
      tmp = @yahoo_auction_stock
      stock = @yahoo_auction_stock['stock']
      label = stock.position_label
      if label?.length > 2 then stock.editPos = true else stock.editPos = false
      p = @PositionService.get({"filter[label]":label}).$promise
      p.then((d)=>
        @updating = false
        position = d.toJSON()
        if stock.editPos
          if d.total == 1
            position = d.data[0]
            stock.position_id = position.position_id
          else
            position = null
          if position == null
            alert("ポジションが見つかりません。")
            return

        saveDefer = []
        # YStockとStockの更新
        saveDefer.push @StockService.update(stock).$promise
        saveDefer.push @YahooAuctionStockService.update(@yahoo_auction_stock).$promise

        # table Load
        @$q.all(saveDefer).then((d)=>
          @reset()
        );
      )
      return
]
