app = angular.module('OrderReturned', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'GeeksCommon'
  'zaikoModal'
])

app.controller 'orderReturnedController', [
  '$rootScope'
  '$scope'
  '$http'
  '$location'
  '$uibModal'
  '$window'
  'NgTableParams'
  '$timeout'
  'StockService'
  'AmazonOrderService'
  'AmazonOrderItemStockService'
  'YahooAuctionOrderItemService'
  'YahooAuctionOrderItemStockService'
  (
    $rootScope,
    $scope,
    $http,
    $location,
    $uibModal,
    $window,
    NgTableParams,
    $timeout,
    StockService,
    AmazonOrderService
    AmazonOrderItemStockService,
    YahooAuctionOrderItemService,
    YahooAuctionOrderItemStockService
  ) =>

    # data
    $scope.orderInfo = null
    $scope.amazonOrderId = ''
    $scope.yahooOrderId = ''
    $scope.orderItemStocks = []

    $scope.reestimate = null

    # method

    ## フォームのリセット
    $scope.reset = () ->
      $scope.amazonOrderId = ''
      $scope.yahooOrderId = ''
      $scope.orderId = ''

    $scope.getOrderStocks = () ->
      if $scope.amazonOrderId != ''
        $scope.orderId = $scope.amazonOrderId
        $scope.getAmazonOrderStocks($scope.orderId)
      else
        $scope.orderId = $scope.yahooOrderId
        $scope.getYahooOrderStocks($scope.orderId)

    $scope.getYahooOrderStocks = (yahooOrderId) ->
      YahooAuctionOrderItemService.showWithOrderId({yahoo_order_id: yahooOrderId}).$promise.then((d) ->
        $scope.orderInfo =
            orderId: d.order_form_id
            name: d.shipping_name
            orderDate: d.created_at
            zip: d.shipping_zip

        if d.yahoo_auction_order_item_status_id == 70
          $scope.orderItemStocks = []
        else
          $scope.orderItemStocks = [
            id: d.yahoo_auction_order_item_stock.yahoo_auction_order_item_stock_id
            soldPlace: 'yahoo'
            stockId: d.yahoo_auction_stock.stock_id
            soldPlaceStockId: d.yahoo_auction_stock.yahoo_auction_stock_id
            code: null
            title: d.yahoo_auction_stock.title
            condition: d.yahoo_auction_stock.stock.condition.condition_name
            categoryId: d.yahoo_auction_stock.stock.category_id
          ]
      )


    $scope.getAmazonOrderStocks = (amazonOrderId) ->
      AmazonOrderService.get({amazon_order_id: amazonOrderId}).$promise.then((d) ->
        $scope.orderInfo =
          orderId: d.amazon_order_id
          name: d.shipping_name
          orderDate: d.created_at
          zip: d.shipping_zip

        $scope.orderItemStocks = d.amazon_order_items.flatMap((orderItem) ->
          orderItem.amazon_order_item_stocks.map((itemStock) ->
            return {
              id: itemStock.amazon_order_item_stock_id
              soldPlace: 'amazon'
              stockId: itemStock.stock_id
              soldPlaceStockId: orderItem.amazon_sku
              code: itemStock.stock.code
              title: itemStock.stock.title
              condition: itemStock.stock.condition.condition_name
              categoryId: itemStock.stock.category_id
            }
          )
        )
      )

    $scope.backToStock = (itemStock) ->
      return unless confirm(itemStock.title + "を返品処理しますか？")
      if itemStock.soldPlace == 'amazon'
        $scope.backToStockAmazon(itemStock)
      if itemStock.soldPlace == 'yahoo'
        $scope.backToStockYahoo(itemStock)

    ## Amazon、YahooそれぞれのbackToStock
    $scope.backToStockAmazon = (itemStock) ->
      AmazonOrderItemStockService.returned({amazon_order_item_stock_id: itemStock.id}).$promise.then((d) ->
        $scope.getAmazonOrderStocks($scope.orderId)
      ).catch((e) ->
        if e.data.error
          alert e.data.msg
        else
          alert 'エラー'
      )
    $scope.backToStockYahoo = (itemStock) ->
      YahooAuctionOrderItemStockService.returned({yahoo_auction_order_item_stock_id: itemStock.id}).$promise.then((d) ->
        $scope.getYahooOrderStocks($scope.orderId)
      ).catch((e) ->
        if e.data.error
          alert e.data.msg
        else
          alert 'エラー'
      )

    $scope.reEstimate = (itemStock) ->
      $scope.reestimate =
        itemStock: itemStock

      modal = $uibModal.open(
        templateUrl: '/html/reEstimate/re_estimate.html'
        controller: 'ReestimateModalController'
        controllerAs: 'c'
        backdrop: true
        bindToController: true
        windowTopClass: 'modal-xl'
        resolve:
          stock: () ->
            return {
              stockId: itemStock.stockId
              categoryId: itemStock.categoryId
              code: itemStock.code
            }
      )

      modal.closed.then () ->
        $scope.reestimateModal = null
        $scope.reestimate = null

      $scope.reestimateModal = modal

    $window.addEventListener('message', (message) ->
      reestimateData = message.data

      if $scope.reestimate && reestimateData.stock_id == $scope.reestimate.itemStock.stockId

        # 元がAmazon
        if $scope.reestimate.itemStock.soldPlace == 'amazon'

          # Amazon -> Amazon なのでそのまま返品処理
          if reestimateData.export_to == 'amazon'
            if confirm "再査定情報を反映して返品処理しますか"
              AmazonOrderItemStockService.returnedWithReestimate({amazon_order_item_stock_id: $scope.reestimate.itemStock.id}, reestimateData).$promise.then((d) ->
                $scope.getAmazonOrderStocks($scope.orderId)
              ).catch((e) ->
                if e.data.error
                  alert e.data.msg
                else
                  alert 'エラー'
              )
          # Amazon -> Yahoo の再査定が可能
          else
            if confirm "ヤフオク在庫として再査定して返品処理しますか"
              AmazonOrderItemStockService.returnedWithReestimateAsAuctionStock(
                { amazon_order_item_stock_id: $scope.reestimate.itemStock.id }, reestimateData
              ).$promise.then((d) ->
                $scope.getAmazonOrderStocks($scope.orderId)
              ).catch((e) ->
                if e.data.error
                  alert e.data.msg
                else
                  alert 'エラー'
              )

        # 元がYahoo
        else
          # Yahoo -> Yahoo なのでそのまま返品処理
          if reestimateData.export_to == 'yahoo'
            if confirm "再査定情報を反映して返品処理しますか"
              YahooAuctionOrderItemStockService.returnedWithReestimate({yahoo_auction_order_item_stock_id: $scope.reestimate.itemStock.id}, reestimateData).$promise.then((d) ->
                $scope.getYahooOrderStocks($scope.orderId)
              ).catch((e) ->
                if e.data.error
                  alert e.data.msg
                else
                  alert 'エラー'
              )
          # Yahoo -> Amazon の再査定はできないようにする
          else
            alert "ヤフオクの在庫をAmazon在庫として再査定できません"

      if $scope.reestimateModal
        $scope.reestimateModal.close()
    )
]
