app = angular.module('multipleWarehouse', [
  'angular-loading-bar'
  'ngTable'
  'ngEnter'
  'GeeksCommon'
  'ngAlertify'
])

app.controller 'MultipleWarehousingController', [
  'UnWarehouseService', 'ConditionService', '$timeout', 'EstimateCommentService', 'alertify', 'PositionService', '$q'
  class MultipleWarehousingController
    constructor: (@UnWarehouseService, @ConditionService,
      @$timeout, @EstimateCommentService, @alertify, @PositionService, @$q)->
      window.addEventListener('beforeunload', (e)=>
        unless @container == null
          txt = "入庫が完了している場合は入庫完了を押してください。"
          e.returnValue = txt
          return txt
        return e
      )
      @ConditionService.query().$promise.then((d)=> @conditions = d)
      @EstimateCommentService.query({category:"warehousing", type: "amazon"}).$promise.then((d)=>
        @commentDataList = d
      )
      @alertify.logPosition("bottom right")
      @init()
    init: ()->
      @item_count = 0
      @container = null
      @warehouseItems = []
      @container_number = null
      @position_label = null
      @user_pos = null
      @confirm_pos = null
      @focus("#containerNumber")

    searchContainer: (container_number)->
#      unless @container == null
#        unless @container.container_number == container_number
#          alert("入庫が完了している場合は入庫完了を押してください。")
#          return
      @warehouseItem = null
      p = @UnWarehouseService.query({container_number: container_number}).$promise
      p.then((d)=>
        @item_count = _.size(d.estimate_items)
        @container = d
        #入庫する商品があるならfocusさせる
        if @item_count > 0
          @focus("#w_p")
      )
      p
    searchPosition: (label)->
      p = @PositionService.get({"filter[label]":label}).$promise
      p.then((d)=>
        position = d.toJSON()
        if d.total == 1
          position = d.data[0]
        else
          position = null
        @position_label = position?.label
        if @position_label? then @focus("#w_i")
      )
    deletePoolItem: (item)->
      item.stock.is_warehousing = false
      @warehouseItems = _.reject(@warehouseItems, (e)=> e.estimate_item_id == item.estimate_item_id)
      @focus("#w_i")

    warehousing: (item, disable_focus = false)->
      obj = _.find(@warehouseItems, (e)=> e.estimate_item_id == item.estimate_item_id)
      if obj?
        return
      if @warehouseItems.length >= 25
        @alertify.error("一括処理の上限です。一度入庫処理してください。")
        @code = null
      else
        item.stock.is_warehousing = true
        @warehouseItems.push(item)
      unless disable_focus
        @focus("#w_i")
    searchForCode: (code)->
      item = _.find(@container.estimate_items, (item)=>
        item.code == code || item.code == code.toIsbn10()
      )
      if item?
        @warehousing(item)
        @code = null
      else
        @alertify.error("商品が見つかりませんでした。")
        @code = null

    saveStocks: ()->
      defers = _.map(@warehouseItems, (item)=>
        item.stock.stock_status_id = 2
        item.stock.position_label = @position_label
        @UnWarehouseService.save(item.stock).$promise
      )
      @$q.all(defers).then((d)=>
        c = @container_number
        @init()
        @container_number = c
        @searchContainer(c)
      ).catch((e)=>
        @alertify.error("入庫処理失敗")
      )

#    saveStock: ()->
#      item = @warehouseItem
#      stock = item.stock
#      unless stock.is_warehousing  then return
#      @warehouseItem = null
#      item.stock.stock_status_id = 2
#      @container.estimate_items = _.sortBy(@container.estimate_items, (item)=>item.stock.stock_status_id)
#      @focus("#w_i")
#      @UnWarehouseService.save(stock).$promise.then((d)=>
#        item.stock = d.stock
#      ).catch((d)=>
#        item.stock.stock_status_id = 1
#        item.stock.is_warehousing = false
#        item.stock.position_label = null
#
#        @msg = "ポジションが正しくありません。もし何度もエラーが出る場合は管理者までご連絡ください。"
#      )
#    btnHarita: ()->
#      if @item_count > 0
#        @container.estimate_items = _.map(@container.estimate_items, (item)=>
#          if item.stock.stock_status_id == 1
#            stock = item.stock
#            stock.stock_status_id = 2
#            stock.position_label = "テスト122"
#            @UnWarehouseService.save(stock).$promise.then((d)=>
#              d = d.toJSON()
#              item.stock = d.stock
#            )
#          return item
#        )
#      @focus("#w_i")
#    changeComment: (estimate_item_comment_id)->
#      commentData = @commentDataList[@warehouseItem.stock.category_id]
#      @warehouseItem.stock.export_comment = commentData.condition[estimate_item_comment_id].export_comment
#      @warehouseItem.stock.condition_id = commentData.condition[estimate_item_comment_id].condition_id
#    disableItem: ()->
#      if confirm("出品不可処理を行います")
#        stock = @warehouseItem.stock
#        @UnWarehouseService.disable(stock).$promise.then((d)=>
#          @searchContainer(@container_number)
#        )
    endWarehouse: ()->
      f = true
      @searchContainer(@container_number).then((d)=>
        if _.filter(@container.estimate_items, (item)-> item.stock.stock_status_id == 1).length > 0
          f = confirm("未入庫商品が入っていますが、入庫してもよろしいですか？")
        if f
          @UnWarehouseService.endWarehouse({estimate_container_id: @container.estimate_container_id}).$promise.then((d)=>
            @init()
          )
      )
    focus:(id)->
      $ = angular.element
      @$timeout(->
        $(id).focus()
      )

]

app.directive 'nextFocusElement', ->
  {
    restrict: 'A'
    link: (scope, element, attrs, modelCtrl)->
      element.bind('keydown', (e)->
        if(e.which is 13)
          scope.$apply(->
            $("\##{attrs.nextFocusElement}").focus()
          )
      )
  }
