'use strict'

SATEI_API = "https://satei.ecmk.jp/api"
GET = 'GET'
POST = 'POST'
PUT = 'PUT'
PATCH = 'PATCH'
DELETE = 'DELETE'

zaiko = angular.module('GeeksCommon',
  [
    'angular-loading-bar',
    'ngResource',
    'ui.bootstrap',
    'zaikoModal'
  ]
)

zaiko.config(($httpProvider)->
#  $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  $httpProvider.interceptors.push(['$q', '$location', ($q, $window)->
    return {
      request: (request)->
        unless  request.url.startsWith('https') then request.headers['X-Requested-With'] = 'XMLHttpRequest'
        request
      responseError: (rejection)->
        if rejection.status == 401 then location.href = '/auth/login'
        $q.reject(rejection)
    }
  ])
)

zaiko.factory 'EstimateApiService', ['$resource', ($resource) ->
  $resource "#{API_SERVICE}/v1/geeks/estimate.json", {},
    query:
      method: GET
    estimate:
      method: GET
    find:
      method: GET
      url: 'https://api-v2.gks.io/v1/geeks/estimate/find.json'
]

zaiko.factory 'AmazonStockService', ['$resource', ($r)->
  $r('/api/v1/amazon-stocks/:amazon_stock_id', {amazon_stock_id: "@amazon_stock_id"},
    query:
      isArray: false
    waitCatalogQuery:
      isArray: false
      method: GET
      url: '/api/v1/amazon-stocks/waitCatalogStocks'

    jokyaku:
      method: POST
      url: '/api/v1/amazon-stocks/jokyaku/:amazon_stock_id'
    replace:
      method: POST
      url: '/api/v1/amazon-stocks/replace/:amazon_stock_id'
    save:
      method: PUT
    retryFeed:
      method: PUT
      url: '/api/v1/amazon-stocks/feed/:amazon_stock_id'

    wrongStateStocks:
      method: GET
      url: '/api/v1/amazon-stocks/list-non-recorded-wrong-state-stocks'
      isArray: true
    rewarehouseWithReestimate:
      method: POST
      url: '/api/v1/amazon-stocks/:stock_id/rewarehouse-with-reestimate'
      params: {'stock_id': ''}
    rewarehouseWithReestimateAsAuctionStock:
      method: POST
      url: '/api/v1/amazon-stocks/:stock_id/rewarehouse-with-reestimate-as-auction-stock'
      params: {'stock_id': ''}
  )
]

zaiko.factory 'AmazonFbaStockService', ['$resource', ($r)->
  $r('/api/v1/amazon-fba-stocks/:amazon_fba_stock_id', {amazon_fba_stock_id: "@amazon_fba_stock_id"},
    query:
      isArray: false
    wrongState:
      method: POST
      url: '/api/v1/amazon-fba-stocks/:amazon_fba_stock_id/wrong_state'
    wrongStateJokyaku:
      method: POST
      url: '/api/v1/amazon-fba-stocks/:amazon_fba_stock_id/wrong_state_jokyaku'
    notFound:
      method: POST
      url: '/api/v1/amazon-fba-stocks/:amazon_fba_stock_id/not_found'
  )
]
zaiko.factory 'AmazonFbaStockShipmentService', ['$resource', ($r)->
  $r('/api/v1/amazon-fba-stock-shipments/:id', {id: "@id"},
    query:
      isArray: false
    update:
      method: POST
      isArray: false
    updateTrackingIds:
      url: '/api/v1/amazon-fba-stock-shipments/tracking/:id'
      method: POST
      isArray: false
  )
]
zaiko.factory 'AmazonSetStockService', ['$resource', ($r)->
  $r('/api/v1/amazon-set-stocks', {},
    query:
      isArray: false
    set:
      isArray: false
      method: POST
    single:
      url: '/api/v1/amazon-set-stocks/single'
      method: POST
  )
]

zaiko.factory 'AmazonFbaStockGroupService', ['$resource', ($r)->
  $r('/api/v1/amazon-fba-stock-groups/:amazon_fba_stock_group_id', {amazon_fba_stock_group_id: "@amazon_fba_stock_group_id"},
    query:
      isArray: false
    get:
      isArray: false
    done_pick:
      url: '/api/v1/amazon-fba-stock-groups/:amazon_fba_stock_group_id/done-pick'
      method: POST
    shipment:
      url: '/api/v1/amazon-fba-stock-groups/:amazon_fba_stock_group_id/shipment'
      method: POST
  )
]

zaiko.factory 'AmazonOrderService', ['$resource', ($r)->
  $r '/api/v1/amazon-orders/:amazon_order_id', {amazon_order_id: "@amazon_order_id"},
    query:
      isArray: false
    get:
      isArray: false
]

zaiko.factory 'AmazonOrderItemStockService', ['$resource', ($r)->
  $r('/api/v1/amazon-order-item-stocks/:amazon_order_item_stock_id', {amazon_order_item_stock_id: "@amazon_order_item_stock_id"},
    query:
      isArray: false
    save:
      method: PUT
    invalid:
      url: '/api/v1/amazon-order-item-stocks/:amazon_order_item_stock_id/invalid'
      method: POST
    cancelItem:
      method: POST
      url: '/api/v1/amazon-order-item-stocks/:amazon_order_item_stock_id/cancel'
    reWarehouse:
      method: POST
      url: '/api/v1/amazon-order-item-stocks/:amazon_order_item_stock_id/re-warehouse-of-cancel-stock'
    waitCancel:
      method: GET
      url: '/api/v1/amazon-order-item-stocks/wait-cancel'
    returned:
      method: POST
      url: '/api/v1/amazon-order-item-stocks/:amazon_order_item_stock_id/returned'
    returnedWithReestimate:
      method: POST
      url: '/api/v1/amazon-order-item-stocks/:amazon_order_item_stock_id/returned-with-reestimate'
    returnedWithReestimateAsAuctionStock:
      method: POST
      url: '/api/v1/amazon-order-item-stocks/:amazon_order_item_stock_id/returned-with-reestimate-as-auction-stock'
  )
]

zaiko.factory 'YahooCategoryService', ['$resource', ($resource)->
  yahooCategory = $resource("#{SATEI_API}/v1/common/yahooCategory/:id", {id: "id"},
    get:
      method: GET,
      isArray: true
      withCredentials: true,
      headers: {'X-Requested-With': 'XMLHttpRequest'}
    suggest:
      url: "#{SATEI_API}/v1/common/yahooCategory/suggest"
      method: GET,
      isArray: true
      withCredentials: true,
      headers: {'X-Requested-With': 'XMLHttpRequest'}
  )
  yahooCategory
]

zaiko.factory 'EstimateCommentService', ['$resource', (r)->
  r("#{SATEI_API}/v1/common/estimate", {}, {
    query:
      method: GET
      withCredentials: true,
      headers: {'X-Requested-With': 'XMLHttpRequest'}
  })
]

zaiko.factory 'YahooAuctionOrderService', ['$resource', ($resource)->
  $resource('/api/v1/yahoo-auction-orders/:id', {id: "@id"}
    paginate:
      method: GET,
      isArray: false
    contact:
      url: '/api/v1/yahoo-auction-orders/update-contact'
      method: 'POST'
      isArray: true
    save:
      method: 'PUT'
    deposit:
      url: '/api/v1/yahoo-auction-orders/deposit'
      method: 'POST'
    cancel:
      url: '/api/v1/yahoo-auction-orders/cancel'
      method: 'POST'
    cancelAndReExportRequest:
      url: '/api/v1/yahoo-auction-orders/cancelAndReExportRequest'
      method: POST
#waitCancel:
#  url: '/api/v1/yahoo-auction-orders/waitCancel'
#  method: POST
    returnWaitShipping:
      url: '/api/v1/yahoo-auction-orders/returnWaitShipping'
      method: POST
#    cancelBeforeDeposit:
#      url: '/api/v1/yahoo-auction-orders/cancelBeforeDeposit'
#      method: 'POST'
    donePaymentOrders:
      url: '/api/v1/yahoo-auction-orders/done-payment'
      method: GET
      isArray: true
    unknownPayments:
      url: '/api/v1/yahoo-auction-orders/unknown-payment'
      method: GET
    done:
      url: '/api/v1/yahoo-auction-orders/done/:id'
      params: {id: "@id"}
      method: 'PUT'
    notSentReceipts:
      url: '/api/v1/yahoo-auction-orders/not-sent-receipts'
      method: GET
    sentReceipts:
      url: '/api/v1/yahoo-auction-orders/sent-receipts'
      method: GET
    completeSend:
      url: '/api/v1/yahoo-auction-orders/complete-send'
      method: PATCH
    cancelSend:
      url: '/api/v1/yahoo-auction-orders/cancel-send'
      method: PATCH
    islandDb:
      method: 'GET',
      url: 'https://api.gks.io/v1/geeks/island'
      isArray: true
    syncYahooAuctionOrder:
      method: GET
      url: '/api/v1/yahoo-auction-orders/sync/:id'
      params: {id: "@id"}
    verifyUpdatedAt:
      url: '/api/v1/yahoo-auction-orders/verify-updated-at'
      method: POST
  )
]

zaiko.factory 'YahooAuctionOrderItemService', ['$resource', ($resource)->
  $resource('/api/v1/yahoo-auction-order-items/:yahoo_auction_order_item_id', {yahoo_auction_order_item_id: "@yahoo_auction_order_item_id"}
    query:
      method: GET
    save:
      method: 'PUT'
    cancel:
      method: POST
      url: '/api/v1/yahoo-auction-order-items/cancel/:yahoo_auction_order_item_id'
    check_shipping_cost:
      method: GET
      url: '/api/v1/yahoo-auction-order-items/check-shipping-cost'
    create_order:
      method: POST
      url: '/api/v1/yahoo-auction-order-items/create-order'
    showWithOrderId:
      method: GET
      url: '/api/v1/yahoo-auction-order-items/order-id/:yahoo_order_id'
  )
]

zaiko.factory 'YahooAuctionOrderItemStockService', ['$resource', ($r)->
  $r '/api/v1/yahoo-auction-order-item-stocks/:yahoo_auction_order_item_stocks', {yahoo_auction_order_item_stocks: "@yahoo_auction_order_item_stocks"},
    {
      query:
        method: GET
      returned:
        method: POST
        params: {yahoo_auction_order_item_stock_id: "@yahoo_auction_order_item_stock_id"}
        url: '/api/v1/yahoo-auction-order-item-stocks/returned/:yahoo_auction_order_item_stock_id'
      returnedWithReestimate:
        method: POST
        params: {yahoo_auction_order_item_stock_id: "@yahoo_auction_order_item_stock_id"}
        url: '/api/v1/yahoo-auction-order-item-stocks/returned-with-reestimate/:yahoo_auction_order_item_stock_id'
    }
]

zaiko.factory 'YahooOrderMailService', ['$resource', ($resource)->
  $resource('/api/v1/yahoo-auction-orders/mail', {},
    query:
      method: GET
      params:
        template_id: "@template_id"
        status_id: "@status_id"
      isArray: true
    post:
      method: 'POST'
  )
]

zaiko.factory 'YahooAuctionShippingSlipGroupService', ['$resource', ($resource)->
  $resource('', {},
    createYahooAuctionSlip:
      method: 'POST'
      url: '/api/v1/order/yahoo-auction/shipping-slip'
      params:
        'ids[]': "@ids"
  )
]

zaiko.factory 'YahooAuctionShippingSlipService', ['$resource', ($resource)->
  $resource('/api/v1/order/yahoo-auction/shipping-slip/:id', {id: "@id"},
    query:
      method: GET
    yahooAuctionOrders:
      method: GET
      url: '/api/v1/order/yahoo-auction/shipping-slip/:id/yahoo-auction-order'
      params: {id: "id"}
      isArray: true
  )
]

zaiko.factory 'YahooAuctionShipmentOptionService', ['$resource', ($resource)->
  $resource('/api/v1/yahoo-auction-orders/shipment-option/:yahoo_auction_order_id', {
    'yahoo_auction_order_id': "id"
  },
    create:
      method: GET
      url: '/api/v1/yahoo-auction-orders/shipment-option/create'
      cache: false
    query:
      method: GET
      cache: false
    save:
      method: 'PUT'
      cache: false
  )
]

zaiko.factory 'ShipmentService', ['$resource', ($resource)->
  $resource('/api/v1/shipments/:id', {id: "@id"}, {
    allSave:
      method: 'POST'
    saveYahoo:
      method: POST
      url: '/api/v1/order/yahoo-auction/shipments/:id'
    saveAmazon:
      method: POST
      url: '/api/v1/order/amazon/shipments/:id'
  })
]

zaiko.factory 'DeliveryCompanyService', ['$resource', ($resource)->
  $resource('/api/v1/delivery-companies/:id', {id: "@id"},
    query:
      method: GET,
  )
]

zaiko.factory 'WarehouseService', ['$resource', ($resource)->
  $resource('/api/v1/warehouses/:id', {id: "@id"},
    query:
      method: GET
  )
]

zaiko.factory 'CategoryService', ['$resource', ($resource)->
  $resource('/api/v1/categories/:id', {id: "@id"},
    query:
      method: GET
  )
]

zaiko.factory 'ConditionService', ['$resource', ($resource)->
  $resource('/api/v1/conditions/:id', {id: "@id"},
    query:
      method: GET
    amazonConditions:
      method: GET
      url: '/api/v1/conditions/amazon-conditions'
      isArray: true
  )
]

zaiko.factory 'StockService', ['$resource', ($resource)->
  $resource('/api/v1/stocks/:stock_id', {stock_id: "@stock_id"},
    query:
      method: GET
    update:
      method: PUT
    save:
      method: PUT
    reWarehouse:
      method: POST
      url: '/api/v1/stocks/re-warehouse/:stock_id/:stock_status_id',
      params: {stock_id: "@stock_id", stock_status_id: "@stock_status_id"}
    searchRewarehouseStock:
      url: '/api/v1/stocks/re-warehouse',
    newWarehouse:
      method: POST
      url: '/api/v1/stocks/new-warehouse'
    notFound:
      method: POST
      url: '/api/v1/stocks/:stock_id/not_found',
    searchOfPositionLabel:
      method: GET,
      url: '/api/v1/stocks/position'
    movePosition:
      method: POST,
      url: '/api/v1/stocks/position/:stock_id'
    bulkJokyaku:
      method: POST
      url: '/api/v1/stocks/bulk-jokyaku',
    mergeContainer:
      method: POST
      url: '/api/v1/stocks/merge-container'
    statuses:
      method: GET
      url: '/api/v1/stocks/statuses'
      isArray: true
  )
]

zaiko.factory 'SetStockService', ['$resource', ($r)=>
  $r('/api/v1/set-stocks/:set_stock_id', {set_stock_id: "@set_stock_id"},
    query:
      method: GET
    statuses:
      method: GET
      url: '/api/v1/set-stocks/statuses'
      isArray: true
  )
]

zaiko.factory 'OroshiService', ['$resource', ($resource)->
  $resource('/api/v1/oroshi/:id', {id: "@id"},
    query:
      method: GET
  )
]

zaiko.factory 'GarbageService', ['$resource', ($resource)->
  $resource('/api/v1/garbage/:id', {id: "@id"},
    query:
      method: GET
  )
]

zaiko.factory 'YahooAuctionStockService', ['$resource', ($resource)->
  $resource('/api/v1/yahoo-auction-stocks/:yahoo_auction_stock_id', {yahoo_auction_stock_id: "@yahoo_auction_stock_id"}
    query:
      method: GET
    update:
      method: PUT
    deleteImage:
      method: POST
      isArray: true
      url: '/api/v1/yahoo-auction-stocks/delete-images'
    showTemplate:
      method: GET
      url: '/api/v1/yahoo-auction-stocks/get-template/:yahoo_auction_stock_id'
      isArray: false
    packExport:
      method: POST
      url: '/api/v1/yahoo-auction-stocks/pack-export'
    approval:
      method: POST
      url: '/api/v1/yahoo-auction-stocks/approval'
    textCheck:
      method: 'GET',
      url: 'https://api-v2.gks.io/textalert/check'
    reestimate:
      method: 'POST'
      url: '/api/v1/yahoo-auction-stocks/:yahoo_auction_stock_id/reestimate'
  )
]

zaiko.factory 'PositionService', ['$resource', ($resource)->
  $resource('/api/v1/positions/:position_id', {position_id: "@position_id"},
    query:
      method: GET
    save:
      method: PUT
  )
]

zaiko.factory 'PositionGroupService', ['$resource', ($resource)->
  $resource('/api/v1/position-groups/:position_group_id', {position_group_id: "@position_group_id"},
    query:
      isArray: true
      method: GET
  )
]

zaiko.factory 'UnWarehouseService', ['$resource', ($resource)->
  $resource('/api/v1/unwarehouse/:estimate_item_id', {estimate_item_id: "@estimate_item_id"},
    pickup:
      method: GET
      url: '/api/v1/warehouse/pickup'
    pickupCol:
      method: GET
      url: '/api/v1/warehouse/pickupCol/:col'
    largePickup:
      method: GET
      url: '/api/v1/warehouse/large-pickup'
      isArray: true
    doPickup:
      method: POST
      url: '/api/v1/warehouse/pickup'
    save:
      method: PUT
    disable:
      method: PUT
      url: '/api/v1/unwarehouse/disable/:estimate_item_id'
    query:
      isArray: false
    endWarehouse:
      method: POST
      url: '/api/v1/unwarehouse/endwarehouse'
    actionDust:
      method: POST
      url: '/api/v1/unwarehouse/dust'
  )
]

zaiko.factory 'ContainerService', ['$resource', (r)->
  r('/api/v1/container/', {},
    finish:
      url: '/api/v1/container/finish',
      method: POST
    classification:
      method: GET
      url: '/api/v1/container/classification/:container_number'
      params: {container_number: "@container_number"}
    doClassification:
      method: POST
      url: '/api/v1/container/classification/:estimate_container_id/:target_container_number'
      params: {
        estimate_container_id: "@estimate_container_id"
        target_container_number: "@target_container_number"
      }
    searchContainer:
      method: GET
      url: '/api/v1/container/number/:container_number'
      params: {container_number: "@container_number"}
  )
]


zaiko.factory 'WaitStockService', ['$resource', (r)->
  r '/api/v1/wait-stocks/:id', {id: "@id"},
    query:
      method: GET
      isArray: false
    single_other_price:
      url: "https://9iau3avpma.execute-api.ap-northeast-1.amazonaws.com/Prod/other-price"
      method: GET
    submitOrder:
      method: PUT
    searchWaitStock:
      method: GET
      url: '/api/v1/wait-stocks/search'
    receive:
      method: PUT
      url: '/api/v1/wait-stocks/receive/:id'
]

#table servce
zaiko.factory 'AmazonShippingSlipService', ['$resource', ($resource)->
  $resource('/api/v1/order/amazon/shipping-slip/:id', {id: "@id"}
    query:
      method: GET
    get:
      method: GET
    createShippingSlip:
      method: POST
      url: '/api/v1/order/amazon/shipping-slip/createShippingSlip'
    nowShippingSlipCount:
      method: GET
      url: '/api/v1/order/amazon/shipping-slip/nonShippingSlipCount'
  )
]

zaiko.factory 'ProductCatalogService', ['$resource', ($r)->
  $r API_SERVICE + '/v1/geeks/catalog/:product_catalog_id', {product_catalog_id: "@product_catalog_id"},
    query:
      method: GET
]

zaiko.factory 'SetProductCatalogService', ['$resource', ($r)->
  $r API_SERVICE + '/v1/geeks/catalog', {},
    query:
      method: GET
    get:
      url: API_SERVICE + '/v1/geeks/catalog/get'
      method: GET
    update:
      method: POST
    save:
      method: PUT
    create:
      method: POST
    delete:
      method: DELETE
]

zaiko.factory 'YahooAuctionTanaoroshiService', ['$resource', ($r)->
  $r '/api/v1/yahoo-auction-tanaoroshi', {},
    query:
      method: GET
      isArray: false
    doTanaoroshi:
      url: '/api/v1/yahoo-auction-tanaoroshi/do-tanaoroshi'
      method: POST
    getTanaoroshiList:
      url: '/api/v1/yahoo-auction-tanaoroshi/tanaoroshi-list'
      method: GET
      isArray: true
    getTanaoroshiStockList:
      url: '/api/v1/yahoo-auction-tanaoroshi/tanaoroshi-stock-list'
      method: GET
      isArray: true
    complete:
      url: '/api/v1/yahoo-auction-tanaoroshi/complete'
      method: POST

]

zaiko.factory 'CommonService', ['$uibModal', 'StockService',
  ($uibModal, StockService)->
    @viewPosition = (position)->
      if position is null then return ""
      return position.position_text_format
    #ポジション編集画面を表示
    @positionModal = ($event, stock)->
      $event.preventDefault()
      positionModalInstance = $uibModal.open(
        templateUrl: '/html/angular/position.html'
        controller: 'PositionModalController'
        size: 'lg')
      positionModalInstance.result.then (results) ->
        stock.position = results.position
        StockService.get(stock).$promise.then((stock)->
          stock.position = results.position
          stock.position_id = results.position.position_id
          stock.$save()
        )
        return
    @
]

zaiko.factory 'AuctionService', ['YahooAuctionStockService', (YahooAuctionStockService)->
  @deleteImage = (yahooAuctionStock, filename, start_func = null, end_func = null)->
    if !confirm('本当に削除してよろしいでしょうか？')
      return
    param = {'file_name': filename, 'stock_id': yahooAuctionStock.stock_id}
    start_func?()
    YahooAuctionStockService.deleteImage(param).$promise.then((d)->
      end_func?(d)
    )
    return
  @
]

zaiko.controller 'ModalInstanceController', ['$scope', 'YahooCategoryService', '$uibModalInstance', 'SuggestKeyword'
  ($s, yahooCategoryS, $m, suggestKeyword) ->
    $s.init = ->
      $s.childCategory = []
      $s.suggestCategory = []
      $s.prevChildCategories = [];
      $s.fetchCategories()
      suggestKeyword && $s.fetchSuggest(suggestKeyword)
      return
    $s.fetchCategories = (id) ->
      id = 1 if id?
      yahooCategoryS.get({id: id}, (res)->
        $s.categories = res
      )
      return
    $s.fetchSuggest = (keyword)->
      yahooCategoryS.suggest({keyword: keyword}, (res)->
        $s.suggestCategory = res
      )
      return

    $s.selectCategory = (categories, c, childLayer) ->
      if c.auction_category_id == "26395" then return
      for category in categories
        category.selected = false
      c.selected = true
      if childLayer == 0 then $s.childCategory[0] = $s.childCategory[1] = null

      #ID選択できてないならchild表示
      if c.auction_category_id is null
        $s.prevChildCategories.push(angular.extend({}, $s.childCategory))
        #数が多くなったら前のやつを消す
        if childLayer > 1
          $s.childCategory[0] = $s.childCategory[1]
          $s.childCategory[1] = null
        yahooCategoryS.get id: c.yahoo_category_id, (data)->
          childLayer = 1 if childLayer == 2
          $s.childCategory[childLayer] = data
      else
        $m.close
          yahoo_category_id: c.auction_category_id
          category_name: c.category
          all_category_name: c.all_yahoo_category_name
    $s.prev = ()=>
      $s.childCategory = $s.prevChildCategories.pop()
    return
]

zaiko.directive 'gConfirmClick', [->
  {
    priority: -1
    restrict: 'A'
    scope:
      confirmFunction: "&gConfirmClick"
    link: (scope, element, attrs)->
      element.bind('click', (e)->
        message = attrs.gConfirmClickMessage ? "OK?"
        if confirm(message) then scope.confirmFunction()
      )
  }
]

zaiko.filter 'objLength', ()->
  (obj)->
    if obj? then Object.keys(obj).length else 0

zaiko.filter 'matchWord', ()->
  (str, matchWord = "")->
    str?.indexOf(matchWord) != -1

zaiko.directive 'auctionWordHighlight', (YahooAuctionStockService)->
  restrict: 'A',
  link: ($scope, element, attrs)->
    text = attrs.auctionWordHighlight
    YahooAuctionStockService.textCheck({title: text}).$promise.then((res)=>
      if res.hit
        tmp_render_text = ''
        tmp_render_text += text.substr(0, res.hit_start_pos)
        tmp_render_text += '<span style="color: white;padding: .3em;background: #ff4040;">' + res.hit + '</span>'
        tmp_render_text += text.substr(res.hit_end_pos)
        element.html(tmp_render_text)
    )
    element.html(text)

zaiko.directive 'numericOnly', ->
  require: 'ngModel',
  link: (scope, element, attr, modelCtrl)->
    modelCtrl.$parsers.push((inputValue)->
      transformedInput = if inputValue then inputValue.replace(/[^\d.-]/g, '') else null
      if transformedInput != inputValue
        modelCtrl.$setViewValue transformedInput
        modelCtrl.$render()
      transformedInput
    )

zaiko.directive 'searchPositionLabel', (PositionService, CommonService)->
  {
    restrict: 'A'
    scope: {
    }
    require: 'ngModel'
    link: ($scope, element, attrs, ngModel)->
      unless ngModel? then return
      ngModel.$formatters.push((stock)=> stock?.position?.label)
      ngModel.$parsers.push((label)=>
        stock = ngModel.$modelValue
        unless stock? then return stock #undefinedだったら同じやつ返しとけ
        format_back = stock.position?.position_text_format
        stock.position?.position_text_format = "..."
        stock.position_id = null
        p = PositionService.get({"filter[label]": label}).$promise
        p.then((d)->
          position = d.toJSON()
          if d.total == 1
            position = d.data[0]
            stock.position = position
            stock.position_id = position.position_id
          else
            stock.position?.position_text_format = "ポジションが見つかりません。"
        )
        stock.promise = p
        stock
      )
  }

zaiko.directive 'searchPosition', (PositionService, CommonService)->
  {
    restrict: 'A'
    scope: {}
    link: ($scope, element, attrs, modelCtrl)->
      element.bind('keyup blur', (e)->
        position_id = parseInt(attrs.searchPosition)
        targetId = attrs.searchPositionTargetId
        targetElem = $("#" + targetId)
        #        if(true || e.which is 13 || e.type == 'blur')
        unless position_id? && position_id > 0
          targetElem.text("-")
          return;
        if $scope.now_pos_id is position_id
          return;
        targetElem.text("...")
        #position search
        PositionService.get({"filter[position_id]": position_id}).$promise.then((d)->
          if d.total > 0
            position = d.data[0]
            targetElem.text(CommonService.viewPosition(position))
            $scope.now_pos_id = position.position_id
          else
            $scope.now_pos_id = position_id
            targetElem.text("-")
        )
      )
  }

zaiko.directive 'integer', ()->
  {
    restrict: 'A'
    scope: {}
    link: (scope, element, attrs, modelCtrl)->
      console.log(element)
      element.bind('keydown keypress', (e)->
        if e.key == "-" || event.key == "-"
          e.preventDefault()
      )
  }

zaiko.directive 'maxByte', ()->
  require: 'ngModel' #1
  link: (scope, elem, attrs, modelCtrl)-> #2
    modelCtrl.$parsers.push (viewValue)-> #3
      return viewValue if !attrs.maxByte?
      val = scope.$eval(attrs.maxByte) #4
      return viewValue if !val?

      return viewValue if viewValue.length == 0
      byte = (encodeURIComponent(s) for s in viewValue).map((s)->if s.length < 4 then 1 else 2).reduce((a, b)->a + b)

      if byte > val
        modelCtrl.$setValidity "maxByte", false #5
        return undefined #6
      else
        modelCtrl.$setValidity "maxByte", true #5
        return viewValue #6

##文字を短縮表示するやつ　作りたかった
zaiko.filter 'abbreviate', ->
  (text, len = 10, end = "...")->
    if text?
      if text.length > len
        text.substring(0, len -1) + end
      else
        text

String::toIsbn13 = ()->
  isbn10 = this
  if isbn10?.length == 10
    isbnTemp = "978" + isbn10
    sum = 0
    for i in [0..11]
      if i % 2 == 0 then weight = 1 else weight = 3
      sum += parseInt(isbnTemp.substr(i, 1)) * weight
    if 10 - sum % 10 == 10 then checkDgt = 0 else checkDgt = 10 - sum % 10
    "978" + isbn10.substr(0, 9) + checkDgt;
  else
    isbn10?.toUpperCase()

String::toIsbn10 = ()->
  isbn13 = this
  if isbn13?.length == 13
    digits = isbn13.substr(3, 9).split("")
    sum = 0
    for i in [0..8]
      sum = (sum + digits[i] * (10 - i))
    checkTmp = 11 - (sum % 11)
    console.log(checkTmp)
    digits.push(if(checkTmp == 10) then "X" else if checkTmp == 11 then 0 else checkTmp)
    digits.join("")
  else
    isbn13?.toUpperCase()

zaiko.directive('multiCheckboxContainer', ()->
  restrict: 'E'
  controller: class MultiCheckboxContainerClass
    constructor: ()->
      @checkBoxes = []
      @checkBoxModels = []
      @previousClickedCheckbox = null
    addCheckbox: (checkbox, checkboxModelCtrl)->
      @checkBoxes.push(checkbox)
      @checkBoxModels.push(checkboxModelCtrl)
    onCheckboxClick: (checkbox, shiftKey, checked)->
      if shiftKey && @previousClickedCheckbox
        checking = checked
        start = _.findIndex(@checkBoxes, (e)=>e.is(@previousClickedCheckbox))
        end = _.findIndex(@checkBoxes, (e)=>e.is(checkbox))
        if start > end
          start = start + end
          end = start - end
          start = start - end
        for i in [start..end]
          @checkBoxes[i].prop('checked', checking)
          @checkBoxModels[i].$setViewValue(checking)
      @previousClickedCheckbox = checkbox
).directive('multiCheckbox', ()->
  {
    restrict: 'A',
    require: ['^^multiCheckboxContainer', 'ngModel']
    link: (scope, element, attrs, controllers)->
      containerCtrl = controllers[0]
      ngModelCtrl = controllers[1]
      containerCtrl.addCheckbox(element, ngModelCtrl)
      element.on('click', (e)->
        containerCtrl.onCheckboxClick(element, e.shiftKey, ngModelCtrl.$viewValue)
      )
  }
)

zaiko.value 'YahooAuctionShippingCostTable',
  [
    {price: 864, name: "同梱可", buyoutPriceFn: (price)-> price * 3},
    {price: 910, name: "60", buyoutPriceFn: (price)-> price * 3},
    {price: 1134, name: "80(小)", buyoutPriceFn: (price)-> price * 3 + 6000},
    {price: 1469, name: "100(中)", buyoutPriceFn: (price)-> price * 3 + 6000},
    {price: 1923, name: "140(大)", buyoutPriceFn: (price)-> price * 3 + 45000},
    {price: 2139, name: "160", buyoutPriceFn: (price)-> price * 3 + 69000},
    {price: 3078, name: "170", buyoutPriceFn: (price)-> price * 3 + 84000},
    {price: 3348, name: "180", buyoutPriceFn: (price)-> price * 3 + 84000},
    {price: 4104, name: "200", buyoutPriceFn: (price)-> price * 3 + 84000},
    {price: 2938, name: "2個口", buyoutPriceFn: (price)-> price * 3 + 84000},
    {price: 4407, name: "3個口", buyoutPriceFn: (price)-> price * 3 + 84000},
    {price: 5876, name: "4個口", buyoutPriceFn: (price)-> price * 3 + 84000},
    {price: 7345, name: "5個口", buyoutPriceFn: (price)-> price * 3 + 84000},
    {price: 8814, name: "6個口", buyoutPriceFn: (price)-> price * 3 + 84000},
#    {price: 865, name: "HDD用"},
  ];
