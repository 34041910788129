app = angular.module('AmazonShippingSlip', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ngFileUpload'
  'ngEnter',
  'ngPapaParse'
  'GeeksCommon'
])
app.controller 'slipController', [
  '$scope'
  '$http'
  'NgTableParams'
  '$filter'
  '$timeout'
  '$q'
  'Upload'
  'DeliveryCompanyService'
  'AmazonShippingSlipService'
  '$rootScope'
  'AmazonOrderItemStockService'
  ($scope, $http, NgTableParams, $filter, $timeout, $q, Upload,DeliveryCompanyService, AmazonShippingSlipService, $rootScope, AmazonOrderItemStockService) ->
    version = 'v1'
    $scope.orders = []
    $scope.url = "/api/#{version}"
    $scope.viewShippingSlipGroupId = null;

    $scope.deleteShipmentForm = false

    $scope.init = ->
      $rootScope.$on('reload', =>
        $scope.tableReload()
      )
      $scope.createShippingSlipType = "0"
      $scope.nowShippingSlipCount = AmazonShippingSlipService.nowShippingSlipCount()
      return


    $scope.createAmazonShippingSlip = ()->
      type = parseInt($scope.createShippingSlipType)
      count = if type == 0 then $scope.nowShippingSlipCount['single'] else $scope.nowShippingSlipCount['multi']
      unless count > 0
        alert("生成するデータがありません。")
        return
      unless confirm (if type == 0 then "１点のみ納品書を印刷します" else "2点以上の納品書を印刷します。") then return

      AmazonShippingSlipService.createShippingSlip({type: type}).$promise.then((d)=>
        $scope.tableReload()
        $scope.nowShippingSlipCount = AmazonShippingSlipService.nowShippingSlipCount()
      )


    $scope.tableReload = ->
      $scope.shippingSlipTable.reload()
      return

    ###
    # 注文テーブル
    ###

    $scope.shippingSlipTable = new NgTableParams({
      page: 1
      count: 10
      sorting:
        'shipping_slip_group_id': 'desc'
      filter:
        type: "AMAZON"
    },
      getData: (table) ->
        AmazonShippingSlipService.query(table.url()).$promise.then((r) ->
          table.total(r.total)
          r.data
        )
    )

    $scope.viewPrintDate = (date)->
      moment(date).format('MM月DD日 H時m分')

    $scope.shippingSlipDetailTable = new NgTableParams({
      page: 1,
      count: 1000
    }, {
      counts: [1000, 2000, 5000, 10000]
      getData: (table) ->
        #通常時
        if($scope.viewShippingSlipGroupId > 0)
          AmazonShippingSlipService.get(_.assign({id: $scope.viewShippingSlipGroupId}, table.url())).$promise.then((r) ->
            table.total(r.total)
            r.data
          )
    });

    $scope.viewShippingSlipDetail = (id)->
      $scope.viewShippingSlipGroupId = id
      $scope.shippingSlipDetailTable.reload()

    $scope.showDeleteShipmentForm = ->
      $scope.deleteShipmentForm = !$scope.deleteShipmentForm

#    $scope.deleteShipment = (id = null)->
#      ids = if id? then "#{id}" else $scope.deleteShipmentIds
#      ids = ids?.split('\n').filter((e)-> isFinite(e) and e isnt null) ? []
#
#      if ids.length > 0 and confirm("#{ids.length} 件未発見処理します。")
#        defers = []
#        ids.forEach((e)->
#          defer = $http.put("#{$scope.url}/order/amazon/shipments/#{e}", {is_not_found: 1})
#          defers.push defer
#        )
#        $q.all(defers).then((r)->
#          alert('処理完了')
#          $scope.deleteShipmentIds = [];
#          $scope.tableReload()
#          $scope.shippingSlipDetailTable.reload()
#        )
    $scope.stockNotFound = (amazon_order_item_stock_id, reason)->
      switch reason
        when 'notfound'
          return unless confirm("未発見処理をします")
        when 'unsellable'
          return unless confirm("状態違い販売不可処理をします")
        else
          return
      AmazonOrderItemStockService.invalid({amazon_order_item_stock_id: amazon_order_item_stock_id, reason: reason}).$promise.then((d)=>
        if d.status == 'hit'
          stock = d.data.amazon_order_item_stocks[0].stock
          asin = stock.asin
          pos = stock.position_text_format
          alert("代替商品が見つかりました！！\n ASIN: #{asin} POS: #{pos}");
        $scope.shippingSlipDetailTable.reload()
      )
    return
]

app.controller 'ShippingNotifyController', [
  'DeliveryCompanyService',
  '$http',
  '$rootScope',
  '$timeout'
  class ShippingNotifyController
    constructor: (@DeliveryCompanyService, @$http, @$rootScope, @$timeout)->
      @loadCsvFlg = false
      @notifyDatas = []
      @deliveryCompanies = []
      @allDeliveryCompanyId = null

      @jpPostPlaceId = "1"
      @jpPostPlaceTable = {
        1: "生野",
        2: "大阪東"
      }
      @jpPostDepthId = "2"
      @jpPostDepthTable = {
        2: "~2cm",
        3: "~3cm"
      }
      @jpPostWeightId = "250"
      @jpPostWeightTable = {
        250: "~250g",
        400: "~400g",
        600: "~600g",
        1000: "~1000g"
      }

      # 配送会社を取得
      @DeliveryCompanyService.query().$promise.then((d)=>
        @deliveryCompanies = d
        @addNotifyTemplate()
        @addNotifyTemplate()
      )

    getNotifyTemplate: ()->
      #ゆうメールだけ特別な対応
      if @allDeliveryCompanyId == 3
        {
          shipment_id: null,
          delivery_company_id: 3
          tracking_number: null
        }
      else
        {
          shipment_id: null,
          delivery_company_id: 1
          tracking_number: null
        }
    addNotifyTemplate: ->
      @notifyDatas.push @getNotifyTemplate()

    parseFile: (file)->
      if file is null then return
      @loadCsvFlg = true
      @notifyDatas = _.filter(@notifyDatas, (r)-> isFinite(r.shipment_id) && r.shipment_id != null && r.shipment_id != "")
      Papa.parse(file, {
        encoding: 'Shift-JIS',
        step: (r)=>
          d = @getDeliveryCompany(1)
          row = r.data
          if row.length == 1
            row = row[0]
          if isFinite row[29]
            @notifyDatas.push(
              {
                shipment_id: row[29],
                tracking_number: row[0]
                delivery_company_id: d.delivery_company_id
                delivery_method: d.delivery_method
              }
            )
        complete: (result)=>
          @$timeout(->
            @notifyDatas = _.filter(@notifyDatas, (r)-> isFinite(r.shipment_id) && r.shipment_id != null && r.shipment_id != "")
          )
          @loadCsvFlg = false
      })
    getDeliveryCompany: (id)->
      @deliveryCompanies[id]

    changeAllDeliveryCompany: (id)->
      d = @getDeliveryCompany(id)
      _.each(@notifyDatas, (e)=>
        e.delivery_company_id = id
        e.delivery_method = d.delivery_method
        return
      )
      return
    changeJpPost: (place, depth, weight)->
      if @jpPostPlaceTable[place]? then @jpPostPlaceId  = place
      if @jpPostDepthTable[place]? then @jpPostDepthId  = depth
      if @jpPostWeightTable[place]? then @jpPostWeightId  = weight

    sendShipmentReport: ->
      @$http.post("/api/v1/order/amazon/shipments", {
        shipments: @notifyDatas
      }).then((r)=>
        @notifyDatas = []
        @addNotifyTemplate()
        @addNotifyTemplate()
        @$rootScope.$broadcast('reload')
      )
]

app.directive('ngMoveNext', ->
  (scope, element, attrs)->
    element.bind "keydown keypress", (e)->
      if(e.which == 13)
        scope.$apply(->
          $e = $(element[0])
          $tr = $e.parents('tr')
          $table = $tr.parent()
          $trs = $table.children()
          index = $trs.index($tr)
          if attrs.ngModel is "row.shipment_id"
            if /^[0-9]*$/.test(element.val())
              if $tr.find('[ng-model="row.tracking_number"]').length == 0
                scope.$eval(attrs.ngMoveNext)
                $tr = $($trs[index+1])
                $input = $tr.find('[ng-model="row.shipment_id"]')
                $input.focus()
              else
                $tr.find('[ng-model="row.tracking_number"]').focus()
          else if attrs.ngModel is "row.tracking_number"
            if element.val() isnt ""
              console.log $trs.length ,index
              if $trs.length - index < 4
                scope.$eval(attrs.ngMoveNext)
              $tr = $($trs[index+1])
              $input = $tr.find('[ng-model="row.shipment_id"]')
              $input.focus()
        )
        e.preventDefault()
      return
)
