app = angular.module('yahooAuctionOrder', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ngFileUpload'
  'ui.bootstrap',
  'GeeksCommon'
])
app.controller 'yahooAuctionOrderController', [
  '$scope'
  '$http'
  'NgTableParams'
  '$filter'
  '$timeout'
  '$q'
  'YahooAuctionOrderItemService'
  ($scope, $http, NgTableParams, $filter, $timeout, $q,YahooAuctionOrderItemService) ->
    $scope.version = 'v1'
    $scope.orders = [];

    $scope.init = ->
      $scope.yahooAuctionOrderStatuses = []
      url = '/api/' + $scope.version + '/yahoo-auction-order-statuses'
      $http.get(url).then (result) ->
        $scope.yahooAuctionOrderStatuses = result.data
        return

    $scope.reload = ->
      #注文データを取得
      $scope.yahooAuctionOrderTable.reload()
      return

    ###
    # 注文テーブル
    ###

    $scope.yahooAuctionOrderTable = new NgTableParams({
      page: 1
      count: 10
      sorting: 'yahoo_auction_order_item_id': 'desc'
      with: ['yahoo_auction_order_item_status','yahoo_auction_order.order','yahoo_auction_order.yahoo_auction_order_status']
    },
      getData: (table) ->
        YahooAuctionOrderItemService.query(table.url()).$promise.then((d)=>
          table.total(d.total)
          d.data
        )
    )

    window.t = $scope.yahooAuctionOrderTable
    window.s = $scope
    ###*
    # 編集画面表示
    ###

    $scope.showEdit = (editYahooAuctionOrderItem) ->
      $scope.editYahooAuctionOrderItem = editYahooAuctionOrderItem
      return

    $scope.hideEdit = ->
      $scope.editYahooAuctionOrderItem = null
      return

    $scope.edit = ->
      $scope.editFlg = if $scope.editFlg then false else true
      return

    ###*
    # 注文更新
    # @param yahooAuctionOrder
    # @param type
    ###

    $scope.update = ->
      `var url`
      if $scope.yahooAuctionOrderForm.$valid == false
        return
      data =
        'order_form_id': $scope.editYahooAuctionOrderItem['order_form_id']
        'status': $scope.editYahooAuctionOrderItem['status']
        'contact_email': $scope.editYahooAuctionOrderItem['contact_email']
        'buyer_name': $scope.editYahooAuctionOrderItem['buyer_name']
        'buyer_kana': $scope.editYahooAuctionOrderItem['buyer_kana']
        'buyer_tel': $scope.editYahooAuctionOrderItem['buyer_tel']
        'buyer_zip': $scope.editYahooAuctionOrderItem['buyer_zip']
        'buyer_pref': $scope.editYahooAuctionOrderItem['buyer_pref']
        'buyer_address1': $scope.editYahooAuctionOrderItem['buyer_address1']
        'buyer_address2': $scope.editYahooAuctionOrderItem['buyer_address2']
        'buyer_address3': $scope.editYahooAuctionOrderItem['buyer_address3']
        'buyer_email': $scope.editYahooAuctionOrderItem['buyer_email']
        'shipping_name': $scope.editYahooAuctionOrderItem['shipping_name']
        'shipping_kana': $scope.editYahooAuctionOrderItem['shipping_kana']
        'shipping_tel': $scope.editYahooAuctionOrderItem['shipping_tel']
        'shipping_zip': $scope.editYahooAuctionOrderItem['shipping_zip']
        'shipping_pref': $scope.editYahooAuctionOrderItem['shipping_pref']
        'shipping_address1': $scope.editYahooAuctionOrderItem['shipping_address1']
        'shipping_address2': $scope.editYahooAuctionOrderItem['shipping_address2']
        'shipping_address3': $scope.editYahooAuctionOrderItem['shipping_address3']
      url = $scope.url + '/' + $scope.editYahooAuctionOrderItem['yahoo_auction_order_id']
      order_data =
        'price': $scope.editYahooAuctionOrderItem['order']['price']
        'shipping_price': $scope.editYahooAuctionOrderItem['order']['shipping_price']
      order_url = '/api/' + $scope.version + '/orders/' + $scope.editYahooAuctionOrderItem['order_id']
      defers = []
      #yahoo-auction-orderの更新
      defers.push $http.put(url, data)
      defers.push $http.put(order_url, order_data)
      $scope.updating = true
      $q.all(defers).then ((result) ->
        $scope.updating = false
        remake $scope.editYahooAuctionOrderItem
        #編集モードをやめる
        $scope.edit()
        return
      ), (errorMessage) ->
        alert 'error'
        console.log errorMessage
        return
      return

    ###*
    # 注文削除
    # @param order
    ###

    $scope.delete = (yahooAuctionOrder) ->
      `var url`
      if !confirm('本当に削除してよろしいですか？')
        return
      url = '/api/' + $scope.version + '/orders/' + yahooAuctionOrder['order_id']
      $http.delete(url).then ((result) ->
        #編集モードをやめる
        $scope.edit()
        #編集画面を非表示にする
        $scope.hideEdit()
        $scope.reload()
        return
      ), (errorMessage) ->
        alert 'error'
        console.log errorMessage
        return
      return

    remake = (yahooAuctionOrder) ->
      angular.forEach $scope.yahooAuctionOrderStatuses, (yahooAuctionOrderStatus) ->
        if yahooAuctionOrder['status'] == yahooAuctionOrderStatus['status_id']
          yahooAuctionOrder['status_name'] = yahooAuctionOrderStatus['status_name']
        return
      return

    return
]
