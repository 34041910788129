app = angular.module('movePosition', [
  'angular-loading-bar'
  'ngAnimate'
  'ui.bootstrap',
  'GeeksCommon',
  'ngAlertify',
  'ngEnter'
])


app.controller 'MovePositionController', [
  'StockService'
  '$timeout'
  class MovePositionController
    @old_pos = null
    constructor: (@StockService, @$timeout)->
      @res = null
      @old_pos = null
      @isSearch = false
      @isIsbnSearch = false
      @isMove = false
      @error_msg = null
      @searchMsg = null
      @movePosMsg = null
      @isbn = null
      @pos_label = null
      @stock
      @is_bulk = false
      @bulk_pos = null


    search: ()=>
      if @isSearch or @old_pos?.length == 0 then return
      @isbn = null
      @pos_label = null
      @StockService.searchOfPositionLabel({position_label: @old_pos}).$promise.then((d)=>
        @isSearch = false
        @error_msg = null
        if d.is_error
          @error_msg = d.msg
          @res = null
          @old_pos = null
        else
          @res = d.data
          @$timeout(=>
            @focus('#isbn')
          )
      )
    searchIsbnOfRow: (stock) =>
      @isbn = stock.code
      if @isbn == null and stock.asin?.length == 10
        @isbn = stock.asin
      @searchIsbn(stock.stock_id)
    searchIsbn: (stock_id = null)=>
      if @isIsbnSearch or @isbn?.length == 0 then return
      @isIsbnSearch = true
      if stock_id > 0
        @stock = _.find(@res, (e)=> e.stock_id == stock_id)
      else
        @stock = _.find(@res, (e)=> e.code == @isbn.toIsbn13() || e.asin == @isbn.toIsbn10())
      @isIsbnSearch = false
      @searchMsg = null
      @pos_label = null
      console.log(@stock)
      if @stock?
        if @is_bulk
          @pos_label = @bulk_pos
          @movePos()
        else
          @focus('#pos_label')
      else
        @isbn = ""
        @focus('#isbn')
        @searchMsg = "見つかりませんでした。"
    movePos: ()=>
      console.log(@stock, @pos_label)
      unless @stock? and @pos_label?.length > 0 then return
      @isMove = true
      @movePosMsg = ""
      @StockService.movePosition({
        stock_id: @stock.stock_id,
        position_label: @pos_label
      }).$promise.then((d)=>
        @isMove = false
        if d.is_error
          @movePosMsg = d.msg
          @pos_label = ""
        else
          @focus('#isbn')
          @stock = null
          @search()
      )
    bulkJokyaku: ()=>
      unless confirm("表示されているStockを除却します") then return
      stock_ids = _.map(@res, (row)=> row.stock_id)
      if stock_ids.length > 0
        @StockService.bulkJokyaku({"stock_id[]": stock_ids}).$promise.then((d)=>
          @error_msg = d.msg;
          @res = null
        )
      else
        @error_msg = "除却対象がありません"
    focus: (id)=>
      document.querySelector(id)?.focus();
]
