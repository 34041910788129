app = angular.module('amazonWaitCancel', [
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'GeeksCommon'
])

app.controller 'AmazonWaitCancelController', [
  'NgTableParams',
  'AmazonOrderItemStockService'
  class AmazonWaitCancelController
    constructor: (@NgTableParams, @AmazonOrderItemStockService)->
      @amazonOrderTable = new @NgTableParams({
        page: 1
        count: 100
      },{
        counts: [100, 500, 1000, 2000],
        getData: (params) =>
          @AmazonOrderItemStockService.waitCancel(params.url()).$promise.then((d)=>
            params.total(d.total)
            d.data
          )
      })
    backStatusItem: (amazon_order_item_stock_id)->
      unless confirm("商品が在った、もしくは出荷可能なので出荷待ちに戻します。") then return
      @AmazonOrderItemStockService.save({amazon_order_item_stock_id: amazon_order_item_stock_id, amazon_order_item_stock_status_id: 1}).$promise.then((d)=>
        @amazonOrderTable.reload()
      )
    cancelItem: (amazon_order_item_stock_id)->
      unless confirm("キャンセル処理完了?") then return
      @AmazonOrderItemStockService.cancelItem({amazon_order_item_stock_id: amazon_order_item_stock_id}).$promise.then((d)=>
        @amazonOrderTable.reload()
      )
]
