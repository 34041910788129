app = angular.module('YahooAuctionReceipts', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'GeeksCommon'
])

app.controller 'YahooAuctionReceiptsController', [
  '$rootScope'
  '$scope'
  '$http'
  '$location'
  '$uibModal'
  'NgTableParams'
  '$timeout'
  'YahooAuctionOrderService'
  ($rootScope, $scope, $http, $location, $uibModal, NgTableParams, $timeout, YahooAuctionOrderService) =>

    $scope.isTableLoding = []

    $scope.reloadTable = ->
      $scope.notSentReceiptsTable.reload()
      $scope.sentReceiptsTable.reload()

    # 領収書対応 > 領収書希望一覧テーブル
    $scope.notSentReceiptsTable = new NgTableParams({
      page: 1
      count: 5
    },
      counts: [10, 20, 50, 100]
      total: 0
      getData: (params) ->
        $scope.isTableLoding[99] = true
        YahooAuctionOrderService.notSentReceipts(params.url()).$promise.then((data) ->
          $scope.isTableLoding[99] = false
          params.total(data.total)
          data.data
        )
    )

    # 領収書対応 > 領収書発行済みテーブル
    $scope.sentReceiptsTable = new NgTableParams({
      page: 1
      count: 5
    },
      counts: [50, 100, 200, 500]
      total: 0
      getData: (params) ->
        $scope.isTableLoding[99] = true
        YahooAuctionOrderService.sentReceipts(params.url()).$promise.then((data) ->
          $scope.isTableLoding[99] = false
          params.total(data.total)
          data.data
        )
    )

    # 配送オプション > 但書きの表示制御
    $scope.displayYAuctionShipmentOptionDescription = (description) ->
      if description && description.length > 0
        return description
      else
        return '-'

    # 配送オプション > 宛名の表示制御
    $scope.displayYAuctionShipmentOptionAddressee = (yahooAuctionOrder) ->
      yahooAuctionShipmentOption = yahooAuctionOrder?.yahoo_auction_shipment_option
      if yahooAuctionShipmentOption?.is_blank
        return '[空欄希望]'
      else if yahooAuctionShipmentOption?.addressee
        return yahooAuctionShipmentOption.addressee
      else
        return yahooAuctionOrder.order.buyer_name

    # 配送オプション > 送り先住所の表示制御
    $scope.displayYAuctionShipmentOptionAddress = (yahooAuctionOrder) ->
      address = yahooAuctionOrder?.yahoo_auction_shipment_option?.address
      if address
        return address
      else
        order = yahooAuctionOrder.order
        return order.shipping_zip + "\n" + order.shipping_pref + order.shipping_address1 + order.shipping_address2 + order.shipping_address3 + "\n" + order.shipping_name

    # 送付完了
    $scope.completeSend = (receipt) ->
      unless confirm('送付完了します') then return
      YahooAuctionOrderService.completeSend({
        yahoo_auction_receipt_id: receipt.id
        tracking_number: receipt.tracking_number
        order_id: receipt.yahoo_auction_order.order_id
      }).$promise.then(->
        $scope.reloadTable()
      )

    return
]
