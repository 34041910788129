app = angular.module('yahooAuctionExport', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ngFileUpload'
  'ui.bootstrap'
  'nl2br'
  'ngSanitize'
  'ngResource'
  'GeeksCommon'
])

app.controller 'yahooAuctionExportController', [
  '$scope',
  '$q'
  '$uibModal'
  '$http'
  '$window'
  'NgTableParams'
  'Upload'
  'WarehouseService'
  'CategoryService'
  'ConditionService'
  'StockService'
  'YahooAuctionStockService'
  'AuctionService'
  'PositionService'
  'YahooAuctionShippingCostTable'
  class YahooAuctionExportController
    constructor: (@$scope, @$q, @$uibModal, @$http, @$window, @NgTableParams, @Upload,
      @WarehouseService, @CategoryService, @ConditionService,
    @StockService, @YahooAuctionStockService, @AuctionService, @PositionService, @YahooAuctionShippingCostTable)->
      @WarehouseService.query().$promise.then((d)=>
        @warehouses = _.assign(d, {0: {warehouse_id: null, warehouse_name: "全て"}})
      )
      @CategoryService.query().$promise.then((d)=>
        @categories = _.assign(d, 0: {category_id: null, category_name: "全て"})
      )
      @ConditionService.query().$promise.then((d)=>
        @conditions = d
      )

      @tables = {}
      @isTableLoding = []
      @warehouseId = null
      @itemCategoryId = null
      @initTables()
      @isEditStock = false
      @editYahooAuctionStock = null
      @yahooAuctionShippingCostTable = @YahooAuctionShippingCostTable

      @$scope.$watch 'c.exportCsvFiles', =>
        @csvFileUpload(@exportCsvFiles)

      @setupReestimateEventHandler()

    setParamTables: ()->
      _.each(@tables, (table, status_id)=>
        table.filter({
          'yahoo_auction_stock_status_id': status_id
          'stock.position.position_group.warehouse_id' : if @warehouseId is null then undefined else @warehouseId
          'stock.category_id': if @itemCategoryId is null then undefined else @itemCategoryId
        })
      )
    tablesReload: (targetTableIds = [])->
      reloadTables = if targetTableIds.length == 0 then @tables else targetTableIds.map((e) => @tables[e])
      defers = _.map(reloadTables, (table)=>
        table.reload()
      )
      @$q.all(defers)

    reloadEditStock: (editYahooAuctionStock)->
      status_id = editYahooAuctionStock.yahoo_auction_stock_status_id
      yahoo_auction_stock_id = editYahooAuctionStock.yahoo_auction_stock_id
      table = @tables[status_id]
      if table?
        yStock = _.find(table.data, (c)=> c.yahoo_auction_stock_id is yahoo_auction_stock_id)
        @editYahooAuctionStock = yStock


    tableGetData: (status_id)->
      (params)=>
        if @isTableLoding[status_id] is true then return
        @isTableLoding[status_id] = true
        @YahooAuctionStockService.query(params.url()).$promise.then((d)=>
          @isTableLoding[status_id] = false
          params.total(d.total)
          d.data
        )
    initTables: ()->
      ###*
      #出品準備テーブル
      ###
      @preparingExportTable =  new @NgTableParams({
        page: 1
        count: 10
        sorting: 'yahoo_auction_stock_id': 'desc'
        filter:
          yahoo_auction_stock_status_id: 1
      },
        getData: @tableGetData(1)
      )

      ###*
      # 出品待ちテーブル
      # @type {NgTableParams}
      ###
      @preparedExportTable =  new @NgTableParams({
        page: 1
        count: 10
        sorting: 'yahoo_auction_stock_id': 'desc'
        filter:
          yahoo_auction_stock_status_id: 2
      },
        counts: [50, 100, 200, 500],
        getData: @tableGetData(2)
      )

      ###*
      # 出品中反映待ちテーブル
      # @type {NgTableParams}
      ###
      @exportingTable = new @NgTableParams({
        page: 1
        count: 10
        sorting: 'yahoo_auction_stock_id': 'desc'
        filter:
          yahoo_auction_stock_status_id: 3
      },
        counts: [50, 100, 200, 500],
        getData: @tableGetData(3)
      )

      ###*
      # 出品中テーブル
      # @type {NgTableParams}
      ###
      @exportedTable =  new @NgTableParams({
        page: 1
        count: 10
        sorting: 'yahoo_auction_stock_id': 'desc'
        filter:
          yahoo_auction_stock_status_id: 4
      },
        getData: @tableGetData(4)
      )

      ###*
      # 再出品テーブル
      # @type {NgTableParams}
      ###
      @reexportingTable = new @NgTableParams({
        page: 1
        count: 10
        sorting: 'yahoo_auction_stock_id': 'desc'
        filter:
          yahoo_auction_stock_status_id: 6
      },
        counts: [50, 100, 200, 500],
        getData: @tableGetData(6)
      )

      ###*
      # 出品承認待ちテーブル
      # @type {NgTableParams}
      ###
      @approvalTable = new @NgTableParams({
        page: 1
        count: 50
        sorting: 'yahoo_auction_stock_id': 'desc'
        filter:
          yahoo_auction_stock_status_id: 7
      },
        counts: [50, 100, 200, 500],
        getData: @tableGetData(7)
      )

      ###*
      # 再撮影待ちテーブル
      # @type {NgTableParams}
      ###
      @retakeTable = new @NgTableParams({
        page: 1
        count: 50
        sorting: 'yahoo_auction_stock_id': 'desc'
        filter:
          yahoo_auction_stock_status_id: 8
      },
        counts: [50, 100, 200, 500],
        getData: @tableGetData(8)
      )

      @tables = {
        1: @preparingExportTable,
        2: @preparedExportTable,
        3: @exportingTable,
        4: @exportedTable,
        6: @reexportingTable,
        7: @approvalTable
        8: @retakeTable
      }

    showEdit: (yahooAuctionStock)->
      @updating = true
      @YahooAuctionStockService.get({yahoo_auction_stock_id: yahooAuctionStock.yahoo_auction_stock_id}).$promise.then((d)=>
        @editYahooAuctionStock = d
        @updating = false
      )
    hideEdit: ()->
      @editYahooAuctionStock = null
    edit: ()->
      @editFlg = if @editFlg then false else true

    deleteAuctionStock: (yahooAuctionStock, reason) ->
      switch reason
        when 'notfound'
          return unless confirm("未発見として削除しますか？")
          yahooAuctionStock.yahoo_auction_stock_status_id = 98
        when 'unsellable'
          return unless confirm("販売不可として削除しますか？")
          yahooAuctionStock.yahoo_auction_stock_status_id = 99
        else
          return
      @updating = true
      @YahooAuctionStockService.update(yahooAuctionStock).$promise.then((d)=>
        @updating = false
        @editFlg = false
        @hideEdit()
        @tablesReload()
      )

    revertApprovalAuctionStock: (yahooAuctionStock)->
      if !confirm("出品承認待ちに戻します。") then return
      yahooAuctionStock.yahoo_auction_stock_status_id = 7
      @updating = true
      @YahooAuctionStockService.update(yahooAuctionStock).$promise.then((d)=>
        @updating = false
        @editFlg = false
        @hideEdit()
        @tablesReload()
      )
    moveRetakeAuctionStock: (yahooAuctionStock)->
      if !confirm("再撮影タブに移動します。") then return
      yahooAuctionStock.yahoo_auction_stock_status_id = 8
      yahooAuctionStock.is_force_move = true;
      @updating = true
      @YahooAuctionStockService.update(yahooAuctionStock).$promise.then((d)=>
        @updating = false
        @editFlg = false
        @hideEdit()
        @tablesReload()
      )
    # 送料の設定
    setShippingPrice: (shippingCost) ->
      @editYahooAuctionStock.shipping_price = shippingCost.price;

    # 同梱の自動設定
    setIsDoukon: (shippingCost) ->
      if shippingCost.name is '同梱可'
        @editYahooAuctionStock.is_doukon = 1
      else
        @editYahooAuctionStock.is_doukon = 0

    # 送料と同梱が一致しているか
    isValidIsDoukon: () ->
      if (!@editYahooAuctionStock)
        return false

      if (@editYahooAuctionStock.shipping_price is 864 and @editYahooAuctionStock.is_doukon is 1) or
         (@editYahooAuctionStock.shipping_price isnt 864 and @editYahooAuctionStock.is_doukon is 0)
        return true
      return false

    deleteImage: (auctionStock, filename)->
      @AuctionService.deleteImage(auctionStock, filename, (()=>
      #start_func
        @updating = true
      ),
        (d)=>
          @editYahooAuctionStock['yahoo_auction_stock_images'] = d
          @updating = false
      )
      return

    moveUpImage: (auctionStock, index)->
      if auctionStock.yahoo_auction_stock_images? && index > 0
        tmp = auctionStock.yahoo_auction_stock_images[index-1]
        target = auctionStock.yahoo_auction_stock_images[index]
        tmp_id = tmp.yahoo_auction_stock_image_id
        tmp.yahoo_auction_stock_image_id = target.yahoo_auction_stock_image_id
        target.yahoo_auction_stock_image_id = tmp_id
        auctionStock.yahoo_auction_stock_images[index-1] = target
        auctionStock.yahoo_auction_stock_images[index] = tmp

    moveDownImage: (auctionStock, index)->
      max = auctionStock.yahoo_auction_stock_images?.length
      if auctionStock.yahoo_auction_stock_images? && index < max-1
        tmp = auctionStock.yahoo_auction_stock_images[index+1]
        target = auctionStock.yahoo_auction_stock_images[index]
        tmp_id = tmp.yahoo_auction_stock_image_id
        tmp.yahoo_auction_stock_image_id = target.yahoo_auction_stock_image_id
        target.yahoo_auction_stock_image_id = tmp_id
        auctionStock.yahoo_auction_stock_images[index+1] = target
        auctionStock.yahoo_auction_stock_images[index] = tmp

    unUploadFiles: ->
      @imagePreviewList = []
      images = @editYahooAuctionStock['image_files']
      if images?.length > 0 && !(@updating)
        imageExtensionValidation = true
        angular.forEach images, (imageFile) ->
          if imageFile.type != 'image/jpeg' and imageFile.type != 'image/gif'
            imageExtensionValidation = false
          return
        if !imageExtensionValidation
          @updating = false
          alert '画像の拡張子はjpegかgifのみです'
          @editYahooAuctionStock['image_files'] = null
          return
        if @editYahooAuctionStock['yahoo_auction_stock_images'].length + images.length > 10
          @updating = false
          alert "画像は11枚以上登録できません"
          @editYahooAuctionStock['image_files'] = null
          return

        @updating = true
        @Upload.upload(
          url: '/api/v1/yahoo-auction-stocks/upload-images'
          fields:
            stock_id: @editYahooAuctionStock['stock_id']
          fileFormDataName: @editYahooAuctionStock['image_files'].map((image, key) ->
            'images[' + key + ']'
          )
          file: @editYahooAuctionStock['image_files']).progress((evt) ->
        ).success ((data, status, headers, config) =>
#fileフォームをリセット
          @editYahooAuctionStock['image_files'] = null
          angular.forEach angular.element('input[type=\'file\']'), (inputElem) ->
            angular.element(inputElem).val null
            return
          @editYahooAuctionStock['yahoo_auction_stock_images'] = data
          @updating = false
          return
        )

    # 商品データ保存 + 画像の保存
    updateExportData: ->
      if @$scope.yahooAuctionStockForm.$valid == false
        return
      @updating = true

      #保存する前にPosition検証
      tmp = @editYahooAuctionStock
      stock = @editYahooAuctionStock['stock']
      label = stock.position_label
      if label?.length > 2 then stock.editPos = true else stock.editPos = false
      p = @PositionService.get({"filter[label]":label}).$promise
      p.then((d)=>
        position = d.toJSON()
        if stock.editPos
          if d.total == 1
            position = d.data[0]
            stock.position_id = position.position_id
          else
            position = null
          if position == null
            alert("ポジションが見つかりません。")
            @updating = false
            return

        saveDefer = []
        # YStockとStockの更新
        saveDefer.push @StockService.update(stock).$promise
        saveDefer.push @YahooAuctionStockService.update(@editYahooAuctionStock).$promise

        # table Load
        @$q.all(saveDefer).then((d)=>
          @showEdit(d[1])
          @editFlg = false
          @tablesReload().then((r)=>
            @updating = false
            if d[1]?.yahoo_auction_stock_status_id == 2 and tmp.yahoo_auction_stock_status_id == 1 then @hideEdit()
          )
        );
      )
      return

    checkAll: (tableIndex = 2, flagKey = 'exportFlg', value = true, excludeAdult = false)->
      if value then @checkAll(tableIndex, flagKey, false)
      preparedExportData = @tables[tableIndex].data
      angular.forEach(preparedExportData, (yahooAuctionStock) =>
        if excludeAdult
          if @isAdult(yahooAuctionStock.all_yahoo_category_name) then return
        yahooAuctionStock[flagKey] = value
      )


    # ヤフオクカテゴリ選択
    selectCategory: ()->
      modalInstance = @$uibModal.open(
        templateUrl: '/html/angular/auction_ng.html'
        controller: 'ModalInstanceController'
        size: 'lg'
        resolve: {
          SuggestKeyword:  -> ""
        }
      )
      modalInstance.result.then (yahoo) =>
        @editYahooAuctionStock.yahoo_auction_category ?= {}
        @editYahooAuctionStock.yahoo_category_id = yahoo.yahoo_category_id
        @editYahooAuctionStock.yahoo_auction_category.category = yahoo.category_name
        window.a = @editYahooAuctionStock
        return

    # 商品説明HTMLの表示
    showTemplate: (yahooAuctionStockId) =>
      @YahooAuctionStockService.showTemplate({yahoo_auction_stock_id: yahooAuctionStockId}).$promise.then((d)=>
        templateView = d.template
        templateView = '<div class="modal-content" style="width: 790px;margin-left: -95px;">' + templateView
        templateView = templateView + '</div>'
        @$uibModal.open template: templateView
      )

    getExportCsv: (mode) ->
      exportYahooAuctionStocks = []
      preparedExportData = @tables[2].data
      exportingTableData = @tables[3].data
      reexportingData = @tables[6].data

      ###*
      # 出品mode
      # export 出品
      # reexport 再出品
      ###

      if mode == 'export'
        exportYahooAuctionStocks = preparedExportData.filter((yahooAuctionStock) ->
          yahooAuctionStock['exportFlg'] == true
        )
        tmp = exportingTableData.filter((row)=>row['exportFlg']==true)
        exportYahooAuctionStocks = exportYahooAuctionStocks.concat(tmp)
      else if mode == 'reexport'
        exportYahooAuctionStocks = reexportingData.filter((yahooAuctionStock) ->
          yahooAuctionStock['exportFlg'] == true
        )
      if !exportYahooAuctionStocks.length > 0
        return
      exportYahooAuctionStockIds = exportYahooAuctionStocks.map((yahooAuctionStock) ->
        yahooAuctionStock['yahoo_auction_stock_id']
      )
      param = {'export_yahoo_auction_stock_ids': exportYahooAuctionStockIds}
      url = '/api/v1/yahoo-auction-stocks/get-export-csv'
      # 出品CSVダウンロード取得
      @$http.post(url, param, responseType: 'arraybuffer').then ((result) =>
        saveAs new Blob([ result.data ], type: 'application/octet-stream\''), 'auction'+moment().format('_YYYYMMDD_HHmm')+'.zip'
        @tablesReload()
      ), (errorMessage) ->
      return

    ###*
    # 出品CSVをインポートしてyahooAuctionStockと同期する
    ###

    @exportCsvFilesLog = ''
    #出品CSV結びつけ

    csvFileUpload: (files) ->
      if files and files.length
        i = 0
        while i < files.length
          file = files[i]
          @Upload.upload(
            url: '/api/v1/yahoo-auction-stocks/combine-auction-ids'
            file: file).progress((evt) ->
          ).success((data, status, headers, config) =>
            @tablesReload()
            return
          ).error
          (errorMessage, statusCode) ->
            alert 'error\nstatusCode:' + statusCode
            console.log errorMessage
            return
          i++
      return
    changeWidth: (target)->
      y = scrollY
      @rightBoxPos = y
    viewPackModal: ()=>
      instance = @$uibModal.open({
        animation: true
        controller: 'yahooAuctionExportModal'
        controllerAs: 'ctrl'
        template: "<div>
<div class='modal-header'>ヤフオク卸まとめ売り作成画面</div>
<div class='modal-body'>
  <button type='button' class='btn btn-info' ng-click='ctrl.createPack(1)' ng-disabled='c.isSubmit'>抱き枕・シーツ等</button>
  <button type='button' class='btn btn-info' ng-click='ctrl.createPack(2)' ng-disabled='c.isSubmit'>成人・成年コミック詰め合わせ</button>
  <button type='button' class='btn btn-info' ng-click='ctrl.createPack(3)' ng-disabled='c.isSubmit'>BLコミック詰め合わせ</button>
  <button type='button' class='btn btn-info' ng-click='ctrl.createPack(4)' ng-disabled='c.isSubmit'>アダルトDVDセット</button>
  <button type='button' class='btn btn-info' ng-click='ctrl.createPack(5)' ng-disabled='c.isSubmit'>イメージDVDセット</button>
  <button type='button' class='btn btn-info' ng-click='ctrl.createPack(6)' ng-disabled='c.isSubmit'>古書プロジェクト</button>
</div>
</div>"
      })

      instance.result.then((d)=>
        @tablesReload()
      )

    # ヤフオクカテゴリがアダルトかどうかの判定
    isAdult: (allYahooCategoryName) ->
      if allYahooCategoryName.match(/アダルト/) then true else false

    #ヤフオク商品承認処理
    itemApproval :()->
      ids = @tables[7].data.filter((e) => e.approvalFlag).map((e) => e.yahoo_auction_stock_id)
      @updating = true
      @YahooAuctionStockService.approval({ids: ids}).$promise.then((d)=>
        @updating = false
        @editFlg = false
        @hideEdit()
        @tablesReload([2,7])
      )


    # 再査定
    ## 再査定モーダルを開く
    reestimate: (yahooAuctionStock) ->
      @reestimateData =
        yahooAuctionStock: yahooAuctionStock
      modal = @$uibModal.open(
        templateUrl: '/html/reEstimate/re_estimate.html'
        controller: 'ReestimateModalController'
        backdrop: true
        bindToController: true
        windowTopClass: 'modal-xl'
        resolve:
          stock: () ->
            return {
              stockId: yahooAuctionStock.stock.stock_id
              categoryId: yahooAuctionStock.stock.category_id
              code: yahooAuctionStock.stock.code
            }
      )

      modal.closed.then () =>
        @reestimateModal = null
        @reestimateData = null

      @reestimateModal = modal

    ## 再査定用のイベントリスナー
    setupReestimateEventHandler: () ->
      @$window.addEventListener('message', (message) =>
        reestimateParam = message.data

        if @reestimateData and @reestimateData.yahooAuctionStock.stock.stock_id == reestimateParam.stock_id
          if confirm "再査定情報を反映しますか"
            @YahooAuctionStockService.reestimate(
              {yahoo_auction_stock_id: @reestimateData.yahooAuctionStock.yahoo_auction_stock_id}
              reestimateParam
            ).$promise.then (d) =>
              alert(d.msg) if d.error
              @reestimateModal.close() if @reestimateModal

      )
]

app.controller 'yahooAuctionExportModal', [
  '$uibModalInstance',
  'YahooAuctionStockService',
  class YahooAuctionExportModal
    constructor: (@$uibModalInstance, @YahooAuctionStockService)->
      @submit = false
    createPack: (id)->
      if @submit then return
      @submit = true
      @YahooAuctionStockService.packExport({
        yahoo_acution_template_id: id
      }).$promise.then((d)=>
        @$uibModalInstance.close()
      )
      console.log(id)
]

app.directive 'auctionTemplate', ->
  { templateUrl: '/angularTemplate/yahooAuction/other.html' }
app.directive 'auctionFigureTemplate', ->
  { templateUrl: '/angularTemplate/yahooAuction/figure.html' }
