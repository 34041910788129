app = angular.module 'ProductCatalog', [
  'ngMessages',
  'angular-loading-bar',
  'ngAnimate',
  'ngTable',
  'GeeksCommon'
]

class Paginate
  constructor: (total, count, page)->
    @nowPage = 0
    @nowCount = 0
    @nowTotal = 0
    @prevPage = 0
    @nextPage = 0
    @maxPage = 0
    @countList = [30, 60, 90, 120]
    @pageList = []
    @isOkPrev = false
    @isOkNext = false
    @isUpdated = false
    @fetchFunc = null
    @update(total, count, page)
    return
  update: (total, count, page)->
    @nowTotal= total
    @nowPage = page
    @nowCount = count

    if @nowTotal == 0 then return
    @maxPage = Math.ceil(total / count)

    #page List 計算
    if @maxPage - 5 <= 5
      @pageList = _.range(1, @maxPage+1)
    else
      if @nowPage - 2 < 1
        @pageList = _.range(1, 6)
      else
        if @nowPage + 2 > @maxPage
          @pageList = _.range(@maxPage -5, @maxPage + 1)
        else
          @pageList = _.range(@nowPage-2, @nowPage+3)

    @prevPage = if @nowPage - 1 < 1 then 1 else @nowPage - 1
    @nextPage = if @nowPage + 1 > @maxPage then @maxPage else @nowPage + 1
    @isOkPrev = if @prevPage == @nowPage then false else true
    @isOkNext = if @nextPage == @maxPage then false else true
    return
  next: ->
    if @isUpdated then return
    if @nowPage == @nextPage then return
    @update(@nowTotal, @nowCount, @nextPage)
    @fetch(@nowCount, @nowPage)
    return
  prev: ->
    if @isUpdated then return
    if @nowPage == @prevPage then return
    @update(@nowTotal, @nowCount, @prevPage)
    @fetch(@nowCount, @nowPage)
    return
  page: (page)->
    if @isUpdated then return
    if @nowPage == page then return
    @update(@nowTotal, @nowCount, page)
    @fetch(@nowCount, @nowPage)
    return
  count: (count)->
    if @isUpdated then return
    if @nowCount == count then return
    @update(@nowTotal, count, @nowPage)
    @fetch(@nowCount, @nowPage)
    return
  total: (total)->
    @update(total, @nowCount, @nowPage)
  fetch: (count, page)->
    @isUpdated = true
    @fetchFunc?(count, page).then(()=>
      @isUpdated = false
    )

app.controller 'ProductCatalogController', [
  '$scope', 'ProductCatalogService', 'SetProductCatalogService'
  class ProductCatalogController
    constructor: (@$scope, @ProductCatalogService, @SetProductCatalogService)->
      @catalogs = []
      @total = 0
      @searchText = ""
      @paginate = new Paginate(0, 30, 1)
      @init()
    search: ->
      if @searchText.length > 1
        @searchWord(@searchText)
    searchWord: (word)->
      @paginate.update(0, 30,1)
      @paginate.fetchFunc = (count, page)=>
        promise = @ProductCatalogService.query({
          title: word
          count: count
          page: page
        }).$promise
        promise.then((d)=>
          @catalogs = d.data
          @total = d.total
          @paginate.total(@total)
        )
        return promise
      @paginate.fetch(30, 1)
    toSet: (productCatalogId)->
      @SetProductCatalogService.create({productCatalogId: productCatalogId}).$promise.then((d)=>
        @paginate.fetch(@paginate.nowCount, @paginate.nowPage)
      )
    removeSet: (productCatalogId)->
      @SetProductCatalogService.delete({baseProductCatalogId: productCatalogId}).$promise.then((d)=>
        @paginate.fetch(@paginate.nowCount, @paginate.nowPage)
      )
    init: ->
]
