app = angular.module('yahooAuctionStock', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ngFileUpload'
  'nl2br'
  'ngSanitize'
  'GeeksCommon'
])

app.config ['$locationProvider', ($locationProvider) ->
  $locationProvider.hashPrefix('!')
]

app.controller 'yahooAuctionStockController', [
  '$scope'
  '$http'
  '$location'
  'NgTableParams'
  '$filter'
  '$timeout'
  '$q'
  'Upload'
  'AuctionService'
  'YahooAuctionStockService'
  ($scope, $http, $location, NgTableParams, $filter, $timeout, $q, Upload,
   AuctionService,
  YahooAuctionStockService) ->
    version = 'v1'
    $scope.yahooAuctionStocks = []
    $scope.cancelYahooAuctionStocks = []
    $scope.url = '/api/' + version + '/yahoo-auction-stocks'
    # 編集画面の表示、非表示によって、左右のテーブルのクラスを変える
    $scope.$watch 'editYahooAuctionStock', (newValue) ->
      if newValue
        $scope.leftBoxClass = 'col-md-6'
        $scope.rightBoxClass = 'col-md-6'
      else
        $scope.leftBoxClass = 'col-md-12'
        $scope.rightBoxClass = ''
      return

    $scope.init = ->
      url = '/api/v1/yahoo-auction-stocks/statuses'
      $q.all([
        $http.get(url)
      ])
        .then ((results) ->
          $scope.statuses = results[0].data
          return
        ), (err) ->
          console.log err
          return

      initial_id = $location.hash()
      if initial_id
        $scope.showEdit({yahoo_auction_stock_id: initial_id})

      return

    $scope.checkUrlHash = ->
      hash = $location.hash()
      if isFinite(hash) and hash.length >= 1
        $scope.searchConditions =  [{
          'column': 'yahoo_auction_stock_id'
          'label': 'ヤフオク在庫ID'
          'value': hash
        }]
        $scope.reload()


    #検索カラム
    $scope.searchColumns = [
      {
        'column': 'yahoo_auction_stock_id'
        'label': 'ヤフオク在庫ID'
      }
      {
        'column': 'stock_id'
        'label': '在庫ID'
      }
      {
        'column': 'auction_id'
        'label': 'オークションID'
      }
      {
        'column': 'title'
        'label': 'タイトル'
      }
    ]

    $scope.clearSearchForm = ->
      $scope.searchConditions = [ {
        'column': ''
        'value': ''
      } ]
      $location.hash ""
      $scope.reload()
      return

    ###*
    # 検索
    ###

    $scope.search = ->
      $scope.yahooAuctionStockTable.page 0
      $scope.reload()
      return

    $scope.reload = ->
      $scope.yahooAuctionStockTable.reload()
      $scope.editYahooAuctionStock = null

    ###
    # 検索クエリーをObjectとして出力する
    ###
    $scope.getSearchQueryParams = ->
      params = []
      angular.forEach $scope.searchConditions, (searchCondition) ->
        if searchCondition['column'] != ''
          params.push searchCondition
      if params.length == 0
        return []
      return _.map(params, (param) ->
        val = param['value']
        val = if val is '' then "NULL" else val
        return {"#{param['column']}": val}
      )

    ###*
    # キャンセル商品の取得
    ###

    $scope.getCancels = ->
      url = $scope.url + '/cancel'
      $http.get(url).then (result) ->
        $scope.cancelYahooAuctionStocks = result.data
        return
      return

    ###*
    # ヤフオク在庫テーブル
    ###

    $scope.yahooAuctionStockTable = new NgTableParams({
      page: 1
      count: 10
      sorting: 'yahoo_auction_stock_id': 'desc'
    },
      getData: (params) ->
        YahooAuctionStockService.query(params.url()).$promise.then((d)->
          params.total(d.total)
          d.data
        )
    )

    ###
      入庫処理
    ###
    $scope.warehousing = ->
      if !confirm('入庫します. ポジションは変更されません。')
        return
      stock = $scope.editYahooAuctionStock.stock
      param =
        'position_id': stock.position_id
        'warehouse_date': moment().format('YYYY-MM-DD HH:mm:ss')
      url = "/api/#{version}/stocks/#{stock.stock_id}"
      $scope.updating = true
      $http.put(url, param).then ((result) ->
        $scope.editYahooAuctionStock.price = stock.simulate_price
        $scope.editYahooAuctionStock.stock = result.data
        $scope.update().then(->
          $scope.reload()
        )
        return
      ), (errorMessage) ->
        alert 'error'
        console.log errorMessage
        return
      return

    ###*
    # 編集画面表示
    ###

    $scope.showEdit = (yahooAuctionStock) ->
      id = yahooAuctionStock.yahoo_auction_stock_id
      $location.hash id
      # $scope.editYahooAuctionStock = yahooAuctionStock
      YahooAuctionStockService.get({yahoo_auction_stock_id: id}).$promise.then((d) =>
        $scope.editYahooAuctionStock = d
      )
      return

    $scope.hideEdit = ->
      $location.hash ""
      $scope.editYahooAuctionStock = null
      return

    $scope.edit = ->
      $scope.editFlg = if $scope.editFlg then false else true
      return

    ###*
    # 更新
    ###

    $scope.update = (yahooAuctionStock) ->
      url = $scope.url + '/' + yahooAuctionStock['yahoo_auction_stock_id']
      $scope.updating = true
      $http.patch(url, {
        'auction_id': yahooAuctionStock['auction_id'],
        'title': yahooAuctionStock['title'],
        'yahoo_auction_stock_status_id': yahooAuctionStock['yahoo_auction_stock_status_id'],
        'price': yahooAuctionStock['price'],
        'shipping_price': yahooAuctionStock['shipping_price'],
        'description': yahooAuctionStock['description']
      })
        .then ((result) ->
          $scope.updating = false
          $scope.editFlg = false
          $scope.yahooAuctionStockTable.reload()
          return
        ), (errorMessage) ->
          alert 'error'
          console.log errorMessage
          return

#        TODO: 画像の更新処理は将来的に実装される予定なので以下のコードをコメントアウトして残しています
#      #画像がアップロードされていれば、登録処理を行う
#      if $scope.editYahooAuctionStock['image_files']
#        imageExtensionValidation = true
#        angular.forEach $scope.editYahooAuctionStock['image_files'], (imageFile) ->
#          if imageFile.type != 'image/jpeg' and imageFile.type != 'image/gif'
#            imageExtensionValidation = false
#          return
#        if !imageExtensionValidation
#          $scope.updating = false
#          alert '画像の拡張子はjpegかgifのみです'
#          return
#        if $scope.editYahooAuctionStock['dynamo']?['images'].length + $scope.editYahooAuctionStock['image_files'].length > 10
#          $scope.updating = false
#          alert "画像は11枚以上登録できません"
#          return
#
#        #画像登録処理
#        Upload.upload(
#          url: $scope.url + '/upload-images'
#          fields:
#            stock_id: $scope.editYahooAuctionStock['stock_id']
#          fileFormDataName: $scope.editYahooAuctionStock['image_files'].map((image, key) ->
#            'images[' + key + ']'
#          )
#          file: $scope.editYahooAuctionStock['image_files']).progress((evt) ->
#        ).success (data, status, headers, config) ->
#          #fileフォームをリセット
#          $scope.editYahooAuctionStock['image_files'] = null
#          angular.forEach angular.element('input[type=\'file\']'), (inputElem) ->
#            angular.element(inputElem).val null
#            return
#          images = data
#          $scope.editYahooAuctionStock['dynamo']['images'] = images
#          # YahooAuctionStockとdynamoDB.Stockの更新
#          YahooAuctionStockInDynamoService.update(param).$promise.then((d)->
#            $scope.editFlg = false
#            $scope.updating = false
#            def.resolve()
#          )
#          return
#      else
#        YahooAuctionStockInDynamoService.update(param).$promise.then((d)->
#          $scope.editFlg = false
#          $scope.updating = false
#          def.resolve()
#        )
#      return def.promise

    ###*
    # 削除
    # @param yahooAuctionStock
    ###

    $scope.delete = (yahooAuctionStock) ->
      if !confirm('本当に削除してよろしいですか？')
        return
      url = $scope.url + '/' + yahooAuctionStock['yahoo_auction_stock_id']
      $scope.updating = true
      $http.delete(url).then ((result) ->
        angular.forEach $scope.yahooAuctionStocks, (value, key) ->
          if value['yahoo_auction_stock_id'] == yahooAuctionStock['yahoo_auction_stock_id']
            delete $scope.yahooAuctionStocks[key]
          return
        $scope.updating = false
        $scope.editYahooAuctionStock = null
        $scope.yahooAuctionStockTable.reload()
        return
      ), (errorMessage) ->
        alert 'error'
        console.log errorMessage
        return
      return

    $scope.deleteImage = (auctionStock, filename)->
      AuctionService.deleteImage(auctionStock, filename, (()->
          #start_func
          $scope.updating = true
        ),
        (d)->
          $scope.editYahooAuctionStock['dynamo']['images'] = d
          $scope.updating = false
      )
    return
]
