app = angular.module('WaitStock', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ui.bootstrap',
  'ngRoute',
  'zaikoModal'
  'GeeksCommon'
])

app.config ['$routeProvider', ($r)->
  $r
    .when('/', {
      templateUrl: "/html/waitStock/order.html"
      controller: "WaitStockOrderController"
      controllerAs: "c"
    })
    .when('/receive', {
      templateUrl: '/html/waitStock/receive.html'
      controller: "WaitStockReceiveController",
      controllerAs: "c"
    })
    .otherwise(redirectTo: "/")
]

app.controller 'WaitStockOrderController', [
  'WaitStockService',
  'NgTableParams'
  '$timeout'
  class WaitStockOrderController
    constructor: (@WaitStockService, @NgTableParams, @$timeout)->
      @otherPriceCache = {}
      @waitTable = new @NgTableParams({
        count: 30
        filter: {
          wait_stock_status_id: 1
        }
        sorting:{
          created_at: "asc"
        }
      }, {
        counts: [100,500,1000,2000]
        getData: (params)=>
          @WaitStockService.query(params.url()).$promise.then((d)=>
            params.total(d.total)
            _.each(d.data, (row, i)=>
              #他社金額取得APIはコストがかかるのでフロントでキャッシュさせる
              cache = @getOtherPriceCache(row.code)
              if cache?
                row.other_price = cache.other_price
                row.cost_price = cache.cost_price
                return

              @$timeout(=>
                cache = @getOtherPriceCache(row.code)
                if cache?
                  row.other_price = cache.other_price
                  row.cost_price = cache.cost_price
                  return
                else
                  @WaitStockService.single_other_price({isbn: row.code}).$promise.then((other)=>
                    @otherPriceCache[row.code] = other
                    row.other_price = other
                    if other.min?
                      row.cost_price = other.min.price
                  )
              , (i*1000))
            )
            d.data
          )
      })

      @orderedTable = new @NgTableParams({
        count: 100,
        filter: {
          wait_stock_status_id: 2
        },
        sorting: {
          updated_at: "desc"
        }
      },{
        counts: [100, 500, 1000, 2000]
        getData: (params)=>
          @WaitStockService.query(params.url()).$promise.then((d)=>
            params.total(d.total)
            d.data
          )
      })
    getOtherPriceCache: (code)->
      if @otherPriceCache[code]?
        row = {}
        other = @otherPriceCache[code]
        row.other_price = other
        if other.min?
          row.cost_price = other.min.price
        return row
      return null
    submitOrder: (waitStock)->
      waitStock.wait_stock_status_id = 2
      @WaitStockService.submitOrder(waitStock).$promise.then((d)=>
        @waitTable.reload()
        @orderedTable.reload()
      )
    cancelOrder: (waitStock)->
      waitStock.wait_stock_status_id = 1
      @WaitStockService.submitOrder(waitStock).$promise.then((d)=>
        @waitTable.reload()
        @orderedTable.reload()
      )
    isbn10To13:(isbn10)->
      isbnTemp = "978"+isbn10
      sum = 0
      for i in [0..11]
        if i % 2 == 0 then weight = 1
        else weight = 3
        sum += parseInt(isbnTemp.substr(i, 1)) * weight
      if 10 - sum % 10 == 10 then checkDgt = 0
      else checkDgt = 10 - sum % 10
      "978" + isbn10.substr(0,9) + checkDgt;
]

app.controller 'WaitStockReceiveController', [
  'WaitStockService',
  'NgTableParams'
  '$timeout'
  class WaitStockOrderController
    constructor: (@WaitStockService, @NgTableParams, @$timeout)->
      @waitTable = @NgTableParams({
        count: 100
        filter:{
          wait_stock_status_id: 2
        }
      }, {
        counts: [100,500,1000,2000]
        getData: (params)=>
          @WaitStockService.query(params.url()).$promise.then((d)=>
            params.total(d.total)
            d.data
          )
      })
      @targetWaitStock = null
    init: ()->
      @targetWaitStock = null
      @positionId = null
      @isReceived = false
      @isNotFound = false
      @isbn = null
      @waitTable.reload()
      @error_msg

    searchWaitStock: (isbn)->
      if @isSearched then return
      @isSearched = true
      @WaitStockService.searchWaitStock({code: isbn}).$promise.then((d)=>
        d = d.toJSON()
        if d.id?
          @targetWaitStock = d
          @focus("#position_id")
          @isNotFound = false
        else
          @targetWaitStock = null
          @isNotFound = true
        @isSearched = false
        return
      ).catch(() =>
        @targetWaitStock = null
        @isNotFound = true
        @isSearched = false
      )
    receive: ()->
      if @isReceived then return
      @isReceived = true
      @targetWaitStock.position_id = @positionId
      @WaitStockService.receive(@targetWaitStock).$promise.then((d)=>
        @init()
      ).catch(()=>
        @error_msg = "ポジションが間違っています。"
        @isReceived = false
        @positionId = ""
        @focus('#position_id')
      )
      @focus("#isbn")
    focus: (id)->
      @$timeout(->angular.element(id).focus())
]

#
#app.controller 'ContainerController', [
#  'UnWarehouseService'
#  class ContainerController
#    constructor: (@UnWarehouseService)->
#      @list = @UnWarehouseService.pickup()
#
#]
#
#app.controller 'PickupController', [
#  'UnWarehouseService'
#  class PickupController
#    constructor: (@UnWarehouseService)->
#      @isSubmit = false
#      @res = false
#    pickup: (form)->
#      if @isSubmit then return;
#      if form.$valid
#        @isSubmit = true
#        @UnWarehouseService.doPickup({container_number: @containerNumber}).$promise.then((d)=>
#          @isSubmit = false
#          @res = d
#          @focus()
#        ).catch((e)=>
#          @isSubmit = false
#          @res = {
#            status: false
#          }
#          @focus()
#        )
#    focus: ()->
#      $ = angular.element
#      $('#containerNumber').val("")
#      $('#containerNumber')[0].focus()
#]
