app = angular.module('stock', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ui.bootstrap',
  'ngRoute',
  'zaikoModal'
  'GeeksCommon'
])

app.config ['$locationProvider', ($locationProvider) ->
  $locationProvider.hashPrefix('!')
]

app.controller 'stockController', [
  '$scope',
  'NgTableParams',
  'StockService',
  '$uibModal',
  '$location',
  class StockController
    constructor: (@$scope,
    @NgTableParams,
    @StockService,
    @$uibModal,
    @$location)->
      @stockTable =  new @NgTableParams({
        page: 1
        count: 10
        sorting:
          stock_id: 'desc'
      },{
        getData: (params)=>
          #通常時
          @StockService.query(params.url()).$promise.then((d)=>
            params.total(d.total)
            d.data
          )
      })
      initial_stock_id = @$location.hash()
      if initial_stock_id
        @showEdit({stock_id: initial_stock_id})
    ###*
    # 編集画面表示
    ###
    showEdit: (stock) ->
      @$location.hash stock.stock_id
      @StockService.get({stock_id: stock.stock_id}).$promise.then((d)=>
        @editStock = d
      )
      return

    hideEdit:->
      @editStock = null
      @editFlg = false
      return

    edit: ->
      @editFlg = if @editFlg then false else true
      return

    openNewStockModal:  =>
      return
      newStockModalInstance = @$uibModal.open(
        templateUrl: 'newStock.html'
        controller: 'newStockModalController'
        scope: @$scope)
      newStockModalInstance.result.then ->
        return

    getStockStatusNames: () ->
      @StockService.statuses().$promise.then((d) =>
        return _.map(d, (val, key) ->
          { id: val.status_name, title: val.status_name }
        )
      )
]

app.controller 'stock1Controller', [
  '$scope'
  '$http'
  'NgTableParams'
  '$filter'
  '$timeout'
  '$q'
  '$uibModal',
  '$location'
  'WarehouseService'
  'CategoryService'
  'ConditionService'
  ($scope, $http, NgTableParams, $filter, $timeout, $q, $uibModal, $location
   WarehouseService,CategoryService,ConditionService
  ) ->
    $scope.version = 'v1'
    $scope.stocks = []
    $scope.positions = []
    $scope.url = '/api/' + $scope.version + '/stocks'

    $scope.init = ->
      # 倉庫・カテゴリー・状態一覧を取得
      $q.all([
        WarehouseService.query().$promise
        CategoryService.query().$promise
        ConditionService.query().$promise
      ]).then ((results) ->
        $scope.warehouses = results[0]
        $scope.categories = results[1]
        $scope.conditions = results[2]
        return
      ), (errorMessage) ->
        alert 'error'
        console.log errorMessage
        return
      $scope.checkUrlHash()
      #urlが変わった時にすぐ反映させる
      $scope.$on('$locationChangeStart', (e)->
        $scope.checkUrlHash()
      )
      return
    $scope.checkUrlHash = ->
      hash = $location.hash()
      if isFinite(hash) and hash.length >= 1
        $scope.searchConditions =  [{
          'column': 'stock_id'
          'label': '在庫ID'
          'value': hash
        }]
        $scope.reload()

    #検索カラム
    $scope.searchColumns = [
      {
        'column': 'stock_id'
        'label': '在庫ID'
      }
      {
        'column': 'estimate_item_id'
        'label': '見積詳細番号'
      }
      {
        'column': 'asin'
        'label': 'ASIN'
      }
      {
        'column': 'title'
        'label': 'タイトル'
      }
      {
        'column': 'sku'
        'label': 'SKU'
      }
      {
        'column': 'position'
        'label': '場所'
      }
      {
        'column': 'container_number'
        'label': 'コンテナ番号'
      }
      {
        'column': 'warehouse_date'
        'label': '入庫日時'
      }
      {
        'column': 'category_id'
        'label': 'カテゴリ'
      }
    ]
    #検索条件モデル
    $scope.searchConditions = [ {
      'column': ''
      'value': ''
    } ]

    ###*
    # 検索条件を追加
    ###

    $scope.addSearchCondition = ->
      $scope.searchConditions.push
        'column': ''
        'value': ''
      return

    ###
    # 検索条件を削除
    ###
    $scope.deleteSearchCondition = ->
      $scope.searchConditions.pop()

    ###*
    # 検索フォームをクリア
    ###

    $scope.clearSearchForm = ->
      $scope.searchConditions = [ {
        'column': ''
        'value': ''
      } ]
      $location.hash ""
      $scope.reload()
      return

    ###*
    # 検索
    ###

    $scope.search = ->
      $scope.stockTable.page(1)
      $scope.reload()
      return

    $scope.getSearchQueryParams = ->
      params = []
      angular.forEach $scope.searchConditions, (searchCondition) ->
        if searchCondition['column'] != ''
          params.push searchCondition
      if params.length == 0
        return []
      return _.map(params, (param) ->
        val = param['value']
        val = if val is '' then "NULL" else val
        return {"#{param['column']}": val}
      ).reduce((a,b) -> _.extend(a,b))
    ###*
    # 在庫テーブルのリロード
    ###

    $scope.reload = ->
      $scope.stockTable.reload()
      return

    ###
    # 在庫テーブル
    ###
    $scope.stockTable = new NgTableParams({
      page: 1
      count: 10
    },{
      getData: (table)->
        table.filter($scope.getSearchQueryParams())
        #通常時
        url = $scope.url + "?#{$.param(table.url())}"
        $http.get(url).then ((result) ->
          d = result.data
          table.total d.total
          #コンテナ番号を16進数に変換
          $scope.stocks = _.map(d.data, (stock) ->
            stock.container_number = containerNumberToHex(stock.container_number)
            stock
          )
          if $scope.stocks?.length == 1 then $scope.showEdit($scope.stocks[0])
          $scope.stocks
        ), (errorMessage) ->
          console.log errorMessage
          return
    })


    ###*
    # 新規登録モーダルを表示
    ###

    $scope.openNewStockModal = ->
      $scope.newStockModalInstance = $uibModal.open(
        templateUrl: 'newStock.html'
        controller: 'newStockModalController'
        scope: $scope)
      $scope.newStockModalInstance.result.then ->
        #closeされた場合の処理
        $scope.reload()
        return
      return

    ###
    # 何か編集した後リロードする関数
    # 検索など行っていたら再検索させる
    ###
    $scope.editAfterReload = ->
      if $scope.search()? then $scope.reload()

    ###*
    # 編集画面表示
    ###
    $scope.showEdit = (stock) ->
      $location.hash stock.stock_id
      $scope.editStock = stock
      return

    $scope.hideEdit = ->
      $location.hash ""
      $scope.editStock = null
      $scope.editFlg = false
      return

    $scope.edit = ->
      $scope.editFlg = if $scope.editFlg then false else true
      return

    ###*
    # ポジション編集画面を表示
    ###

    $scope.openPositionModal = (event) ->
      #form送信を防ぐ
      event.preventDefault()
      positionModalInstance = $uibModal.open(
        templateUrl: '/html/angular/position.html'
        controller: 'PositionModalController'
        size: 'lg')
      positionModalInstance.result.then (results) ->
        $scope.editStock.position_id = results.position.position_id
        $scope.editStock.position = results.position
        return

    ###*
    # 在庫更新
    # @param stock
    # @param type
    ###

    $scope.update = ->
      if $scope.editStockForm.$invalid == true
        return
      data =
        'asin': $scope.editStock.asin
        'title': $scope.editStock.title
        'category_id': $scope.editStock.category_id
        'condition_id': $scope.editStock.condition_id
        'simulate_price': $scope.editStock.simulate_price
        'quantity': $scope.editStock.quantity
        'cost_price': $scope.editStock.cost_price
        'shipping_price': $scope.editStock.shipping_price
        'position_id': $scope.editStock.position_id
        'container_number': containerNumberToDec($scope.editStock.container_number)
      url = $scope.url + '/' + $scope.editStock['stock_id']
      $scope.updating = true
      $http.put(url, data).then ((result) ->
        $scope.editStock = result.data
        $scope.editStock.container_number = containerNumberToHex($scope.editStock.container_number)
        $scope.updating = false
        $scope.editFlg = false
        return
      ), (errorMessage) ->
        alert 'error'
        console.log errorMessage
        return
      return

    ###*
    # 在庫削除
    # @param stock
    ###

    $scope.delete = (stock) ->
      if !confirm('本当に削除してよろしいですか？')
        return
      url = $scope.url + '/' + stock['stock_id']
      $http.delete(url).then ((result) ->
        $scope.editStock = null
        $scope.stockTable.reload()
        return
      ), (errorMessage) ->
        alert 'error'
        console.log errorMessage
        return
      return

    ###*
    # コンテナ番号を10進数から16進数に変換
    # @param stocks
    ###

    containerNumberToHex = (containerNumber) ->
      if containerNumber then containerNumber.toString(16) else null

    ###*
    # コンテナ番号16進数から10進数に変換
    # ＠param containerNumber
    ###

    containerNumberToDec = (containerNumber) ->
      if containerNumber then parseInt(containerNumber, 16) else null

    ###*
    # amazon出品
    ###

    $scope.exportAmazon = ->
      if !$scope.editStock.asin
        alert 'ASINを持っていないので出品できません'
        return
      if $scope.editStock.condition_id == 1100
        alert 'ジャンク品は出品できません'
        return
      if !confirm('amazon在庫を作成してもいいですか？')
        return
      angular.forEach $scope.conditions, (condition) ->
        if $scope.editStock['condition_id'] == condition.condition_id
          $scope.editStock['amazon_condition_id'] = condition.amazon_condition_id
        return
      $scope.updating = true
      #Amazon行き
      param =
        'stock_id': $scope.editStock['stock_id']
        'asin': $scope.editStock['asin']
        'title': $scope.editStock['title']
        'price': $scope.editStock['simulate_price']
        'amazon_sku': $scope.editStock['sku']
        'quantity': $scope.editStock['quantity']
        'amazon_condition_id': $scope.editStock['amazon_condition_id']
        'description': $scope.editStock['comment']
      $http.post('/api/v1/amazon-stocks', param).then ((result) ->
        `var param`
        `var param`
        amazonStock = result.data
        defers = []
        #価格ログ
        param =
          'stock_id': amazonStock.stock_id
          'price': amazonStock.price
          'type': 'amazon'
        defers.push $http.post('/api/v1/price-logs', param)
        #カタログ
        param = 'asin': amazonStock.asin
        defers.push $http.post('/api/v1/product-catalogs', param)
        $q.all(defers).then ((results) ->
          alert 'amazon在庫を作成しました'
          $scope.updating = false
          $scope.editStock['amazon_stock'] = result.data
          $scope.editStock = null
          return
        ), (errorMessage) ->
          alert '価格ログ作成に失敗'
          console.log errorMessage
          return
        return
      ), (errorMessage) ->
        console.log errorMessage
        return
      return

    ###
      入庫処理
    ###
    $scope.warehousing = ->
      if !confirm('入庫します. ポジションは変更されません。')
        return
      stock = $scope.editStock
      param =
        'position_id': stock.position_id
        'warehouse_date': moment().format('YYYY-MM-DD HH:mm:ss')
      url = $scope.url + '/' + $scope.editStock['stock_id']
      $scope.updating = true
      $http.put(url, param).then ((result) ->
        $scope.editStock = result.data
        $scope.editAfterReload()
        $scope.updating = false
        return
      ), (errorMessage) ->
        alert 'error'
        console.log errorMessage
        return
      return

    ###*
    # ヤフオク出品
    ###

    $scope.exportYahooAuction = ->
      if !confirm('ヤフオク在庫を作成してもいいですか？')
        return
      $scope.updating = true
      param =
        'stock_id': $scope.editStock['stock_id']
        'title': $scope.editStock['title']
        'price': $scope.editStock['simulate_price']
        'quantity': $scope.editStock['quantity']
      dynamoParam = 'title': $scope.editStock['title']
      defers = [
        $http.post('/api/v1/yahoo-auction-stocks', param)
        $http.put('/api/v1/yahoo-auction-stocks/update-export-data/' + param.stock_id, dynamoParam)
      ]
      $q.all(defers).then ((result) ->
        $scope.updating = false
        $scope.editStock['yahoo_auction_stock'] = result[0].data
        $scope.editStock = null
        return
      ), (errorMessage) ->
        console.log errorMessage
        return
      return

    ###*
    # 除却処理
    ###

    $scope.jokyaku = ->
      if !confirm('本当に除却処理を行ってもよろしいでしょうか？')
        return
      $scope.updating = true
      param =
        'jokyaku_date': moment().format('YYYY-MM-DD HH:mm:ss')
        'quantity': 0
      url = '/api/v1/stocks/' + $scope.editStock.stock_id
      defers = [ $http.put(url, param) ]
      if $scope.editStock.amazon_stock
        amazonUrl = '/api/v1/amazon-stocks/' + $scope.editStock.amazon_stock.amazon_stock_id
        amazonParam = 'quantity': 0
        defers.push $http.put(amazonUrl, amazonParam)
      if $scope.editStock.yahoo_auction_stock
        yahooAuctionUrl = '/api/v1/yahoo-auction-stocks/' + $scope.editStock.yahoo_auction_stock.yahoo_auction_stock_id
        yahooAuctionParam = 'quantity': 0
        deferes.push $http.put(yahooAuctionUrl, yahooAuctionParam)
      $q.all(defers).then ((results) ->
        $scope.editStock = results[0].data
        $scope.updating = false
        return
      ), (errorMessage) ->
        console.log errorMessage
        return
      return

    return
]

###*
# 在庫登録モーダルコントローラ
###

app.controller 'newStockModalController', [
  '$scope'
  '$http'
  '$q'
  '$uibModal'
  '$uibModalInstance'
  ($scope, $http, $q, $uibModal, $uibModalInstance) ->
    $scope.warehouses = $scope.$parent.warehouses
    $scope.categories = $scope.$parent.categories

    ###*
    # ポジション編集画面を表示
    ###

    $scope.openPositionModal = (event) ->
      #form送信を防ぐ
      event.preventDefault()
      positionModalInstance = $uibModal.open(
        templateUrl: '/html/angular/position.html'
        controller: 'PositionModalController'
        size: 'lg')
      positionModalInstance.result.then (results) ->
        if !$scope.newStock
          $scope.newStock = []
        $scope.newStock.position_id = results.position.position_id
        $scope.newStock.position = results.position
        return

    ###*
    # 在庫登録
    ###

    $scope.create = ->
      if $scope.newStockForm.$valid == false
        return
      $scope.newStockForm.$submitting = true
      data =
        'estimate_item_id': $scope.newStock.estimate_item_id
        'product_catalog_id': $scope.newStock.asin
        'asin': $scope.newStock.asin
        'category_id': $scope.newStock.category_id
        'title': $scope.newStock.title
        'condition_id': $scope.newStock.condition_id
        'cost_price': $scope.newStock.cost_price
        'quantity': $scope.newStock.quantity
        'simulate_price': $scope.newStock.simulate_price
        'position_id': $scope.newStock.position_id
        'container_number': $scope.newStock.container_number
        'shipping_price': $scope.newStock.shipping_price
      url = $scope.url
      defers = [ $http.post(url, data) ]
      if $scope.newStock.asin
        productCatalogUrl = '/api/' + $scope.version + '/product-catalogs'
        productCatalogData = 'asin': $scope.newStock.asin
        defers.push $http.post(productCatalogUrl, productCatalogData)
      $q.all(defers).then ((results) ->
        $scope.newStockForm.$submitting = false
        $scope.newStockForm.$setPristine()
        #formをリセット
        $uibModalInstance.close()
        return
      ), (errorMessage) ->
        alert 'error'
        console.log errorMessage
        return
      return

    return
]
