app = angular.module('amazonReWarehouseWrongStateCancelStock', [
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'GeeksCommon'
])

app.controller 'AmazonReWarehouseWrongStateCancelStockController', [
  'AmazonOrderItemStockService',
  'AmazonOrderService'
  'AmazonStockService'
  '$uibModal'
  '$window'
  class AmazonReWarehouseCancelStockController
    constructor: (@AmazonOrderItemStockService, @AmazonOrderService, @AmazonStockService, @$uibModal, @$window)->
      @stocks = []
      @saisatei_stock = null

      @new_position = null
      @reestimateModal = null

    init: () ->
      @reset()
      @setupReestimateListener()

    reset: () ->
      @saisatei_stock = null
      @stocks = []
      @new_position = null
      @reestimateModal = null
      @AmazonStockService.wrongStateStocks().$promise.then((d) =>
        @stocks = d
      )

    reWarehouse: (stock) ->
      @saisatei_stock = stock

    reEstimate: () ->
      return if !@saisatei_stock
      if !@new_position
        alert('ポジションを指定してください')
        return

      modal = @$uibModal.open(
        templateUrl: '/html/reEstimate/re_estimate.html'
        controller: 'ReestimateModalController'
        controllerAs: 'c'
        backdrop: true
        bindToController: true
        windowTopClass: 'modal-xl'
        resolve:
          stock: () =>
            return {
              stockId: @saisatei_stock.stock_id
              categoryId: @saisatei_stock.category_id
              code: @saisatei_stock.code
            }
      )
      modal.closed.then () ->
        @reestimateModal = null
        @reestimate = null

      @reestimateModal = modal

    setupReestimateListener: () ->
      @$window.addEventListener('message', (message) =>
        reestimateData = message.data

        if @saisatei_stock && reestimateData.stock_id == @saisatei_stock.stock_id
          reestimateData['position_label'] = @new_position

          if reestimateData.export_to == 'amazon'
            if confirm "再査定情報を反映して再入庫しますか"
              @AmazonStockService.rewarehouseWithReestimate({stock_id: @saisatei_stock.stock_id}, reestimateData).$promise.then((d) =>
                if d.error
                  alert d.msg
                else
                  @reset()
              ).catch((e) ->
                if e.data.error
                  alert e.data.msg
                else
                  alert 'エラー'
              )
          else
            if confirm "ヤフオク在庫として再査定して返品処理しますか"
              @AmazonStockService.rewarehouseWithReestimateAsAuctionStock(
                {stock_id: @saisatei_stock.stock_id},
                reestimateData
              ).$promise.then((d) =>
                if d.error
                  alert d.msg
                else
                  @reset()
              ).catch((e) ->
                if e.data.error
                  alert e.data.msg
                else
                  alert 'エラー'
              )

        if @reestimateModal
          @reestimateModal.close()
      )
]
