app = angular.module('setStock', [
  'ngMessages'
  'angular-loading-bar'
  'ngAnimate'
  'ngTable'
  'ui.bootstrap',
  'ngRoute',
  'zaikoModal'
  'GeeksCommon'
])

app.config ['$locationProvider', ($locationProvider) ->
  $locationProvider.hashPrefix('!')
]

app.controller 'setStockController', [
  '$scope',
  'NgTableParams',
  'SetStockService',
  '$uibModal'
  class StockController
    constructor: (@$scope,
    @NgTableParams,
    @SetStockService,
    @$uibModal)->
      @stockTable =  new @NgTableParams({
        page: 1
        count: 10
        sorting:
          set_stock_id: 'desc'
      },{
        getData: (params)=>
          #通常時
          @SetStockService.query(params.url()).$promise.then((d)=>
            params.total(d.total)
            d.data
          )
      })
    ###*
    # 編集画面表示
    ###
    showEdit: (stock) ->
      @SetStockService.get({set_stock_id: stock.set_stock_id}).$promise.then((d)=>
        @editStock = d
      )
      return

    hideEdit:->
      @editStock = null
      @editFlg = false
      return

    edit: ->
      @editFlg = if @editFlg then false else true
      return
    changeWidth: (target)->
      y = scrollY
      @rightBoxPos = y
#
#    openNewStockModal:  =>
#      return
#      newStockModalInstance = @$uibModal.open(
#        templateUrl: 'newStock.html'
#        controller: 'newStockModalController'
#        scope: @$scope)
#      newStockModalInstance.result.then ->
#        return

    getSetStockStatusNames: () ->
      @SetStockService.statuses().$promise.then((d) =>
        return _.map(d, (val, key) ->
          { id: val.status_name, title: val.status_name }
        )
      )

]

###*
# 在庫登録モーダルコントローラ
###

app.controller 'newStockModalController', [
  '$scope'
  '$http'
  '$q'
  '$uibModal'
  '$uibModalInstance'
  ($scope, $http, $q, $uibModal, $uibModalInstance) ->
    $scope.warehouses = $scope.$parent.warehouses
    $scope.categories = $scope.$parent.categories

    ###*
    # ポジション編集画面を表示
    ###

    $scope.openPositionModal = (event) ->
      #form送信を防ぐ
      event.preventDefault()
      positionModalInstance = $uibModal.open(
        templateUrl: '/html/angular/position.html'
        controller: 'PositionModalController'
        size: 'lg')
      positionModalInstance.result.then (results) ->
        if !$scope.newStock
          $scope.newStock = []
        $scope.newStock.position_id = results.position.position_id
        $scope.newStock.position = results.position
        return

    ###*
    # 在庫登録
    ###

    $scope.create = ->
      if $scope.newStockForm.$valid == false
        return
      $scope.newStockForm.$submitting = true
      data =
        'estimate_item_id': $scope.newStock.estimate_item_id
        'product_catalog_id': $scope.newStock.asin
        'asin': $scope.newStock.asin
        'category_id': $scope.newStock.category_id
        'title': $scope.newStock.title
        'condition_id': $scope.newStock.condition_id
        'cost_price': $scope.newStock.cost_price
        'quantity': $scope.newStock.quantity
        'simulate_price': $scope.newStock.simulate_price
        'position_id': $scope.newStock.position_id
        'container_number': $scope.newStock.container_number
        'shipping_price': $scope.newStock.shipping_price
      url = $scope.url
      defers = [ $http.post(url, data) ]
      if $scope.newStock.asin
        productCatalogUrl = '/api/' + $scope.version + '/product-catalogs'
        productCatalogData = 'asin': $scope.newStock.asin
        defers.push $http.post(productCatalogUrl, productCatalogData)
      $q.all(defers).then ((results) ->
        $scope.newStockForm.$submitting = false
        $scope.newStockForm.$setPristine()
        #formをリセット
        $uibModalInstance.close()
        return
      ), (errorMessage) ->
        alert 'error'
        console.log errorMessage
        return
      return

    return
]
